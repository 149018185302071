// -----------------------------------------------------------------------------------------------------
// @ Size helpers
// -----------------------------------------------------------------------------------------------------
@each $prop, $abbrev in (height: h, width: w) {
    // pixels - Without breakpoint infix
    @for $index from 0 through 64 {
        $size: $index * 4;
        $length: #{$size}px;

        .#{$abbrev}-#{$size} {
            #{$prop}: $length;
        }

        .max-#{$abbrev}-#{$size} {
            max-#{$prop}: $length;
        }

        .min-#{$abbrev}-#{$size} {
            min-#{$prop}: $length;
        }

        .#{$abbrev}-#{$size}--force {
            #{$prop}: $length !important;
        }

        .max-#{$abbrev}-#{$size}--force {
            max-#{$prop}: $length !important;
        }

        .min-#{$abbrev}-#{$size}--force {
            min-#{$prop}: $length !important;
        }
    }

    // percentage - Without breakpoint infix
    @for $i from 0 through 20 {
        $i-p: 5 * $i;
        $size-p: 5% * $i;

        .#{$abbrev}-#{$i-p}-p {
            #{$prop}: $size-p !important;
        }

        .max-#{$abbrev}-#{$i-p}-p {
            max-#{$prop}: $size-p !important;
        }

        .min-#{$abbrev}-#{$i-p}-p {
            min-#{$prop}: $size-p !important;
        }
    }

    // big sizes - Without breakpoint infix
    @for $i from 3 through 8 {
        $size: $i * 100;
        $length: #{$size}px;

        .#{$abbrev}-#{$size} {
            #{$prop}: $length !important;
        }

        .max-#{$abbrev}-#{$size} {
            max-#{$prop}: $length !important;
        }

        .min-#{$abbrev}-#{$size} {
            min-#{$prop}: $length !important;
        }
    }

    // auto - Without breakpoint infix
    .#{$abbrev}-auto {
        #{$prop}: auto !important;
    }
}

@each $breakpoint-infix, $media-size in $helper-breakpoints {
    @include media-breakpoint($media-size) {
        $infix: if($media-size ==null, "", "-#{$breakpoint-infix}");

        @each $prop, $abbrev in (height: h, width: w) {
            // pixels - With breakpoint infix
            @for $index from 0 through 64 {
                $size: $index * 4;
                $length: #{$size}px;

                .#{$abbrev}#{$infix}-#{$size} {
                    #{$prop}: $length;
                }

                .max-#{$abbrev}#{$infix}-#{$size} {
                    max-#{$prop}: $length;
                }

                .min-#{$abbrev}#{$infix}-#{$size} {
                    min-#{$prop}: $length;
                }

                .#{$abbrev}#{$infix}-#{$size}--force {
                    #{$prop}: $length !important;
                }

                .max-#{$abbrev}#{$infix}-#{$size}--force {
                    max-#{$prop}: $length !important;
                }

                .min-#{$abbrev}#{$infix}-#{$size}--force {
                    min-#{$prop}: $length !important;
                }
            }

            // percentage - With breakpoint infix
            @for $i from 0 through 20 {
                $i-p: 5 * $i;
                $size-p: 5% * $i;

                .#{$abbrev}#{$infix}-#{$i-p}-p {
                    #{$prop}: $size-p !important;
                }

                .max-#{$abbrev}#{$infix}-#{$i-p}-p {
                    max-#{$prop}: $size-p !important;
                }

                .min-#{$abbrev}#{$infix}-#{$i-p}-p {
                    min-#{$prop}: $size-p !important;
                }
            }

            // big sizes - With breakpoint infix
            @for $i from 3 through 8 {
                $size: $i * 100;
                $length: #{$size}px;

                .#{$abbrev}#{$infix}-#{$size} {
                    #{$prop}: $length !important;
                }

                .max-#{$abbrev}#{$infix}-#{$size} {
                    max-#{$prop}: $length !important;
                }

                .min-#{$abbrev}#{$infix}-#{$size} {
                    min-#{$prop}: $length !important;
                }
            }

            // auto - With breakpoint infix
            .#{$abbrev}#{$infix}-auto {
                #{$prop}: auto !important;
            }
        }
    }
}
