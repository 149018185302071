// -------------------------------------------------------------------------------------------------------------------
// @ Elevations Mixins
//
// A collection of mixins and CSS classes that can be used to apply elevation to a material
// element.
// See: https://material.io/design/environment/elevation.html
// Examples:
//
//
// .matcha-foo {
//   @include $elevation(2);
//
//   &:active {
//     @include $elevation(8);
//   }
// }
//
// <div id="external-card" class="elevation-z2"><p>Some content</p></div>
//
// For an explanation of the design behind how elevation is implemented, see the design doc at
// https://goo.gl/Kq0k9Z.

// Colors for umbra, penumbra, and ambient shadows. As described in the design doc, each elevation
// level is created using a set of 3 shadow values, one for umbra (the shadow representing the
// space completely obscured by an object relative to its light source), one for penumbra (the
// space partially obscured by an object), and one for ambient (the space which contains the object
// itself). For a further explanation of these terms and their meanings, see
// https://en.wikipedia.org/wiki/Umbra,_penumbra_and_antumbra.

// Maps for the different shadow sets and their values within each z-space. These values were
// created by taking a few reference shadow sets created by Google's Designers and interpolating
// all of the values between them.
//
// -----------------------------------------------------------------------------------------------------

// Applies the correct css rules to an element to give it the elevation specified by $zValue.
// The $zValue must be between 0 and 24.
@mixin elevation($zValue, $color: $elevation-color, $opacity: $elevation-opacity) {
    @if type-of($zValue) !=number or not unitless($zValue) {
        @error '$zValue must be a unitless number';
    }

    @if $zValue < 0 or $zValue >24 {
        @error '$zValue must be between 0 and 24';
    }

    box-shadow: #{map-get(_get-umbra-map($color, $opacity), $zValue)},
    #{map-get(_get-penumbra-map($color, $opacity), $zValue)},
    #{map-get(_get-ambient-map($color, $opacity), $zValue)};
}

@mixin _theme-elevation($zValue, $theme, $opacity: $elevation-opacity) {
    $foreground: map-get($theme, foreground);
    $elevation-color: map-get($foreground, elevation);
    $elevation-color-or-default: if($elevation-color ==null, $elevation-color, $elevation-color);

    @include elevation($zValue, $elevation-color-or-default, $opacity);
}

// Applies the elevation to an element in a manner that allows
// consumers to override it via the Material elevation classes.
@mixin overridable-elevation($zValue, $color: $elevation-color, $opacity: $elevation-opacity) {
    &:not([class*="#{$_elevation-prefix}"]) {
        @include elevation($zValue, $color, $opacity);
    }
}

@mixin _theme-overridable-elevation($zValue, $theme, $opacity: $elevation-opacity) {
    $foreground: map-get($theme, foreground);
    $elevation-color: map-get($foreground, elevation);
    $elevation-color-or-default: if($elevation-color ==null, $elevation-color, $elevation-color);

    @include overridable-elevation($zValue, $elevation-color-or-default, $opacity);
}

// Applies the correct css rules needed to have an element transition between elevations.
// This mixin should be applied to elements whose elevation values will change depending on their
// context (e.g. when active or disabled).
//
// NOTE(traviskaufman): Both this mixin and the above function use default parameters so they can
// be used in the same way by clients.
@mixin elevation-transition($duration: $elevation-transition-duration,
    $easing: $elevation-transition-timing-function) {
    // Returns a string that can be used as the value for a transition property for elevation.
    // Calling this function directly is useful in situations where a component needs to transition
    // more than one property.
    //
    // .foo {
    //   transition: elevation-transition-property-value(), opacity 100ms ease;
    // }
    transition: elevation-transition-property-value($duration, $easing);
}

// Css class generator for shadows
// $_elevation-prefix: "matcha-elevation-z";
@mixin elevation-theme($theme) {
    @for $zValue from 0 through 24 {
        .#{$_elevation-prefix}#{$zValue} {
            @include _theme-elevation($zValue, $theme);
        }
    }
}
