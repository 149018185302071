// -------------------------------------------------------------------------------------------------------------------
// @ Display grids generator
// -------------------------------------------------------------------------------------------------------------------
@mixin display-grid($grid-prefix, $grid-length, $grid-sizes, $helper-breakpoints) {
    @each $sizeLabel, $value in $grid-sizes {
        .row-#{$sizeLabel} {
            display: flex;
            flex-direction: column;
        }

        @media only screen and (min-width: #{$value}) {
            @for $i from 1 through $grid-length {
                .row > .#{$grid-prefix}-#{$sizeLabel}-#{$i} {
                    flex-basis: $i / $grid-length * 100%;
                }

                .row > .#{$grid-prefix}-offset-#{$sizeLabel}-#{$i} {
                    margin-left: $i / $grid-length * 100%;
                }
            }

            .row-#{$sizeLabel} {
                flex-direction: row;
            }
        }
    }

    [class^="grid-"] {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
    }

    @for $i from 0 through 12 {
        $size: $i * 4;
        .gap-#{$size} {
            column-gap: #{$size}px;
            row-gap: #{$size}px;
        }
        @each $breakpoint, $materialBreakpoint in $helper-breakpoints {
            @include media-breakpoint($materialBreakpoint) {
                $infix: if($materialBreakpoint == null, "", "-#{$breakpoint}");
                .gap#{$infix}-#{$size} {
                    column-gap: #{$size}px;
                    row-gap: #{$size}px;
                }
            }
        }
    }

    @each $breakpoint, $materialBreakpoint in $helper-breakpoints {
        @include media-breakpoint($materialBreakpoint) {
            $infix: if($materialBreakpoint ==null, "", "-#{$breakpoint}");

            @for $i from 1 through $grid-length {
                .grid#{$infix}-#{$i} {
                    @include _grid-prop($i);
                }

                .colspan#{$infix}-#{$i} {
                    grid-column-end: span #{$i};
                }

                .rowspan#{$infix}-#{$i} {
                    grid-row-end: span #{$i};
                }
            }
        }
    }
}



@mixin _grid-prop($i) {
    display: grid;
    grid-template-columns: repeat($i, minmax(0, 1fr));
}

// -------------------------------------------------------------------------------------------------------------------
// @ Display flex generator
// -------------------------------------------------------------------------------------------------------------------
@mixin display-flex($grid-prefix, $grid-length, $grid-sizes) {
    .row {
        display: flex;
        flex-wrap: wrap;

        > div[class*="#{$grid-prefix}-"] {
            box-sizing: border-box;
        }

        > div:not([class*="#{$grid-prefix}-"]) {
            flex: 1;
        }

        @for $i from 1 through $grid-length {
            .#{$grid-prefix}-#{$i} {
                flex-basis: $i / $grid-length * 100%;
            }

            .#{$grid-prefix}-offset-#{$i} {
                margin-left: $i / $grid-length * 100%;
            }

            @each $sizeLabel, $value in $grid-sizes {
                [class*="#{$grid-prefix}-offset-#{$sizeLabel}-"] {
                    margin-left: 0;
                }

                .#{$grid-prefix}-#{$sizeLabel}-#{$i} {
                    flex-basis: 100%;
                }
            }
        }

        @each $classLabel, $cssValue in (align-start flex-start, align-center center, align-end flex-end) {
            > div[class*="#{$grid-prefix}-"].#{$classLabel} {
                align-self: $cssValue;
            }
        }

        @each $classLabel,
            $cssValue
                in (
                    align-start flex-start,
                    align-center center,
                    align-end flex-end,
                    space-around space-around,
                    space-between space-between
                )
        {
            &.#{$classLabel} {
                justify-content: $cssValue;
            }
        }
    }
}

@include display-grid($grid-prefix, $grid-length, $grid-sizes, $helper-breakpoints);
@include display-flex($grid-prefix, $grid-length, $grid-sizes);

// -------------------------------------------------------------------------------------------------------------------
// Dynamic gaps
// -------------------------------------------------------------------------------------------------------------------
.gap-inside {
    -moz-column-gap: 16px;
    column-gap: 16px;
    row-gap: 16px;
}
.gap-outside {
    -moz-column-gap: 16px;
    column-gap: 16px;
    row-gap: 16px;
}
@media screen and (min-width: 600px) {
    .gap-outside {
        -moz-column-gap: 24px;
        column-gap: 24px;
        row-gap: 24px;
    }
}