// -----------------------------------------------------------------------------------------------------
// @ Spacing - Margin and Padding
// Generate spacing values from -64 to 64 jumping from 4 to 4
// -----------------------------------------------------------------------------------------------------
@each $breakpoint-infix, $media-size in $helper-breakpoints {
    @include media-breakpoint($media-size) {
        $infix: if($media-size ==null, "", "-#{$breakpoint-infix}");

        @each $prop, $abbrev in (margin: m, padding: p) {
            @if ($prop ==margin) {
                @for $index from -16 through 16 {
                    $size: $index * 4;
                    $length: #{$size}px;

                    .#{$abbrev}#{$infix}-#{$size} {
                        #{$prop}: $length;
                    }

                    .#{$abbrev}#{$infix}-#{$size}--force {
                        #{$prop}: $length !important;
                    }

                    .#{$abbrev}x#{$infix}-#{$size} {
                        #{$prop}-right: $length;
                        #{$prop}-left: $length;
                    }

                    .#{$abbrev}y#{$infix}-#{$size} {
                        #{$prop}-top: $length;
                        #{$prop}-bottom: $length;
                    }

                    .#{$abbrev}x#{$infix}-#{$size}--force {
                        #{$prop}-right: $length !important;
                        #{$prop}-left: $length !important;
                    }

                    .#{$abbrev}y#{$infix}-#{$size}--force {
                        #{$prop}-top: $length !important;
                        #{$prop}-bottom: $length !important;
                    }

                    .#{$abbrev}t#{$infix}-#{$size} {
                        #{$prop}-top: $length;
                    }

                    .#{$abbrev}r#{$infix}-#{$size} {
                        #{$prop}-right: $length;
                    }

                    .#{$abbrev}b#{$infix}-#{$size} {
                        #{$prop}-bottom: $length;
                    }

                    .#{$abbrev}l#{$infix}-#{$size} {
                        #{$prop}-left: $length;
                    }

                    .#{$abbrev}t#{$infix}-#{$size}--force {
                        #{$prop}-top: $length !important;
                    }

                    .#{$abbrev}r#{$infix}-#{$size}--force {
                        #{$prop}-right: $length !important;
                    }

                    .#{$abbrev}b#{$infix}-#{$size}--force {
                        #{$prop}-bottom: $length !important;
                    }

                    .#{$abbrev}l#{$infix}-#{$size}--force {
                        #{$prop}-left: $length !important;
                    }
                }

                // Some special margin utils for flex alignments
                .m#{$infix}-auto {
                    margin: auto;
                }

                .mt#{$infix}-auto {
                    margin-top: auto;
                }

                .mr#{$infix}-auto {
                    margin-right: auto;
                }

                .mb#{$infix}-auto {
                    margin-bottom: auto;
                }

                .ml#{$infix}-auto {
                    margin-left: auto;
                }

                .mx#{$infix}-auto {
                    margin-right: auto;
                    margin-left: auto;
                }

                .my#{$infix}-auto {
                    margin-top: auto;
                    margin-bottom: auto;
                }

                .m#{$infix}-auto--force {
                    margin: auto !important;
                }

                .mt#{$infix}-auto--force {
                    margin-top: auto !important;
                }

                .mr#{$infix}-auto--force {
                    margin-right: auto !important;
                }

                .mb#{$infix}-auto--force {
                    margin-bottom: auto !important;
                }

                .ml#{$infix}-auto--force {
                    margin-left: auto !important;
                }

                .mx#{$infix}-auto--force {
                    margin-right: auto !important;
                    margin-left: auto !important;
                }

                .my#{$infix}-auto--force {
                    margin-top: auto !important;
                    margin-bottom: auto !important;
                }
            }

            @if ($prop ==padding) {
                @for $index from 0 through 16 {
                    $size: $index * 4;
                    $length: #{$size}px;

                    .#{$abbrev}#{$infix}-#{$size} {
                        #{$prop}: $length;
                    }

                    .#{$abbrev}#{$infix}-#{$size}--force {
                        #{$prop}: $length !important;
                    }
                }

                @for $index from 0 through 16 {
                    $size: $index * 4;
                    $length: #{$size}px;

                    .#{$abbrev}x#{$infix}-#{$size} {
                        #{$prop}-right: $length;
                        #{$prop}-left: $length;
                    }

                    .#{$abbrev}y#{$infix}-#{$size} {
                        #{$prop}-top: $length;
                        #{$prop}-bottom: $length;
                    }

                    .#{$abbrev}x#{$infix}-#{$size}--force {
                        #{$prop}-right: $length !important;
                        #{$prop}-left: $length !important;
                    }

                    .#{$abbrev}y#{$infix}-#{$size}--force {
                        #{$prop}-top: $length !important;
                        #{$prop}-bottom: $length !important;
                    }
                }

                @for $index from 0 through 16 {
                    $size: $index * 4;
                    $length: #{$size}px;

                    .#{$abbrev}t#{$infix}-#{$size} {
                        #{$prop}-top: $length;
                    }

                    .#{$abbrev}r#{$infix}-#{$size} {
                        #{$prop}-right: $length;
                    }

                    .#{$abbrev}b#{$infix}-#{$size} {
                        #{$prop}-bottom: $length;
                    }

                    .#{$abbrev}l#{$infix}-#{$size} {
                        #{$prop}-left: $length;
                    }

                    .#{$abbrev}t#{$infix}-#{$size}--force {
                        #{$prop}-top: $length !important;
                    }

                    .#{$abbrev}r#{$infix}-#{$size}--force {
                        #{$prop}-right: $length !important;
                    }

                    .#{$abbrev}b#{$infix}-#{$size}--force {
                        #{$prop}-bottom: $length !important;
                    }

                    .#{$abbrev}l#{$infix}-#{$size}--force {
                        #{$prop}-left: $length !important;
                    }
                }
            }
        }
    }
}
