$size-font-xxsmall: 10px;
$size-font-xsmall: 12px;
$size-font-small: 14px;
$size-font-medium: 16px;
$size-font-big: 18px;
$size-font-large: 20px;
$size-font-xlarge: 24px;
$size-font-xxlarge: 32px;
$size-font-2-xxlarge: 40px;
$size-font-weight-thin: 100;
$size-font-weight-extra-light: 200;
$size-font-weight-light: 300;
$size-font-weight-normal: 400;
$size-font-weight-medium: 500;
$size-font-weight-semi-bold: 600;
$size-font-weight-bold: 700;
$size-font-weight-extra-bold: 800;
$size-font-weight-black: 900;
$size-font-base: 16px;
$size-line-height-minimum: 32px;
$size-line-height-small: 40px;
$size-line-height-medium: 48px;
$size-line-height-large: 56px;
$size-line-height-xlarge: 64px;
$size-line-height-xxlarge: 72px;
