// Saturation Levels
$saturationLevels: 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, A100, A200, A400, A700;

// Hues Palette Colors
$index: 0;
// -------------------------------------------------------------------------------------------------------------------
// @ Text color levels generator
// -------------------------------------------------------------------------------------------------------------------
@mixin _generate-text-color-levels($classes, $contrast) {
    @if ($contrast == "dark") {
        #{$classes} {
            &.secondary-text,
            .secondary-text {
                color: rgba(0, 0, 0, 0.54) !important;
            }

            &.hint-text,
            .hint-text,
            &.disabled-text,
            .disabled-text {
                color: rgba(0, 0, 0, 0.38) !important;
            }

            &.divider,
            .divider {
                color: rgba(0, 0, 0, 0.12) !important;
            }
        }
    } @else {
        #{$classes} {
            &.secondary-text,
            .secondary-text {
                color: rgba(255, 255, 255, 0.7) !important;
            }

            &.hint-text,
            .hint-text,
            &.disabled-text,
            .disabled-text {
                color: rgba(255, 255, 255, 0.5) !important;
            }

            &.divider,
            .divider {
                color: rgba(255, 255, 255, 0.12) !important;
            }
        }
    }
}
// @mixin _generate-classes($prefix, $color-map, $saturation) {
//     // .primary-500
//     // .primary-600-bg
//     // .primary-700-fg
//     $background: map-get($color-map, $saturation);
//     $contrast: map-get($color-map, #{$saturation}-contrast);

//     .#{$prefix}-#{$saturation} {
//         background: $background;
//         color: $contrast;
//     }
//     .#{$prefix}-#{$saturation}-bg {
//         background: $background;
//     }
//     .#{$prefix}-#{$saturation}-fg {
//         color: $background;
//     }
// }

// -------------------------------------------------------------------------------------------------------------------
// @ Static color classes generator
// -------------------------------------------------------------------------------------------------------------------
@mixin _generate-static-color-classes($colorName, $color, $contrastColor, $saturation) {
    $colorSelector: unquote(".#{$colorName}#{$saturation}");

    #{$colorSelector} {
        background-color: $color !important;
        color: $contrastColor !important;

        &[disabled] {
            background-color: rgba($color, 0.12) !important;
            color: rgba($contrastColor, 0.26) !important;
        }
    }

    #{$colorSelector}-bg {
        background-color: $color !important;
    }

    #{$colorSelector}-fg {
        color: $color !important;
    }

    @if ($saturation == "-500") {
        $colorSelectorDefault: unquote(".#{$colorName}");

        #{$colorSelectorDefault}-bg {
            background-color: $color !important;
        }

        #{$colorSelectorDefault}-fg {
            color: $color !important;
        }
    }

    // #{$colorSelector}-border,
    // #{$colorSelector}-border-top,
    // #{$colorSelector}-border-right,
    // #{$colorSelector}-border-bottom,
    // #{$colorSelector}-border-left {
    //     border-color: $color !important;
    // }

    // #{$colorSelector}-fill {
    //     fill: $color !important;
    // }
}

@mixin colors-classes-static($theme) {
    $palettes: getAllPalettes($theme);
    $light-contrasting-classes: ();
    $dark-contrasting-classes: ();

    @each $paletteName, $palette in $palettes {
        // $contrasts: map-get($red, "contrast");
        // $contrasts =  all contrast colors of $red palette
        $contrasts: map-get($palette, "contrast");

        @each $saturation in $saturationLevels {
            // $color: map-get($red, 500)
            // $contrast: map-get($contrasts, 500);  500 contrast color of $red palette
            $color: map-get($palette, $saturation);
            $contrast: map-get($contrasts, $saturation);

            @if ($color != null and $contrast != null) {
                @include _generate-static-color-classes($paletteName, $color, $contrast, "-#{$saturation}");

                // If the contrast color is dark
                @if (rgba(black, 1) ==rgba($contrast, 1)) {
                    $dark-contrasting-classes: append(
                        $dark-contrasting-classes,
                        unquote(".#{$paletteName}-#{$saturation}"),
                        "comma"
                    );
                }

                // if the contrast color is light
                @else {
                    $light-contrasting-classes: append(
                        $light-contrasting-classes,
                        unquote(".#{$paletteName}-#{$saturation}"),
                        "comma"
                    );
                }
            }
            // Run the generator one more time for default values (500)
            // if we are not working with primary, accent or warn palettes
            // @if ($saturation == 500) {
            //     // Generate color classes
            //     @include _generate-static-color-classes($paletteName, $color, $contrast, "");

            //     // Add color to the correct list depending on the contrasting color

            //     // If the contrast color is dark
            //     @if (rgba(black, 1) ==rgba($contrast, 1)) {
            //         $dark-contrasting-classes: append($dark-contrasting-classes, unquote(".#{$paletteName}"), "comma");
            //     }

            //     // if the contrast color is light
            //     @else {
            //         $light-contrasting-classes: append(
            //             $light-contrasting-classes,
            //             unquote(".#{$paletteName}"),
            //             "comma"
            //         );
            //     }
            // }
        }
    }

    // Generate contrasting colors
    @include _generate-text-color-levels($dark-contrasting-classes, "dark");
    @include _generate-text-color-levels($light-contrasting-classes, "light");
}

// -------------------------------------------------------------------------------------------------------------------
// @ Dynamic color classes generator
// -------------------------------------------------------------------------------------------------------------------
@mixin _generate-dynamic-color-classes($attributes, $palette, $paletteName, $background, $foreground) {
    $color: unquote(#{$paletteName});
    $baseClassName: (".background-#{$paletteName}"); // .base-yellow
    // $baseClassNameDefault: (".#{$paletteName}"); // .amber

    // $paletteClassNameDefault: "#{$paletteName}"; // .amber
    @each $attribute, $attribute-palette in $attributes {
        $withPrefixSelector: ".#{$attribute}-#{$color}"; // .background-amber
        $nonPrefixSelector: ".#{$color}"; // .amber

        // generate classes for color-amber, background-amber, fill-amber, stroke-amber and border-color-amber
        #{$withPrefixSelector} {
            #{$attribute}: map-get($attribute-palette, $paletteName);
            @if ($attribute != "color") {
                &-alpha {
                    #{$attribute}: map-get($attribute-palette, ("#{$paletteName}-alpha")) !important;
                }
            }
        }
        // generate classes red blue green pink
        @if ($attribute == "background") {
            #{$nonPrefixSelector} {
                background: map-get($attribute-palette, $paletteName) !important;
            }
        }

        @if ($attribute == "color") {
            @if ($color == "yellow" or $color == "amber") {
                #{$nonPrefixSelector} {
                    color: map-get($foreground, static-dark-text);
                }
            }
            @if ($color != "yellow" and $color != "amber") {
                #{$nonPrefixSelector} {
                    color: map-get($foreground, text-inverse);
                }
            }
        }
        //     $selector: ".#{$attribute}-#{$color}"; // .background-yellow
        //     @if ($attribute != "color") {
        //         #{$selector} {
        //             #{$attribute}: map-get($background, $color) !important;
        //             &-alpha {
        //                 #{$attribute}: map-get($background, ("#{$paletteName}-alpha")) !important;
        //             }
        //         }
        //     }
        //     @if ($attribute == "color") {
        //     }
    }

    // #{$baseClassName} {
    //     // background: map-get($background, ("#{$paletteName}")) !important;
    //     &-alpha {
    //         background: map-get($background, ("#{$paletteName}-alpha")) !important;
    //     }
    // }

    // #{$baseClassNameDefault} {
    //     background: map-get($background, ("#{$paletteName}")) !important;
    // }

    // @if ($color != yellow or $color != amber) {
    //     #{$baseClassNameDefault} {
    //         color: map-get($foreground, text-inverse);
    //     }
    // }

    // @if ($color == yellow or $color == amber) {
    //     #{$baseClassNameDefault} {
    //         color: map-get($foreground, static-dark-text);
    //     }
    // }
}

@mixin colors-classes-dynamic($theme) {
    $palettes: getAllPalettes($theme);

    $is-dark: map-get($theme, isdark);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $colorNames: red, pink, purple, deep-purple, indigo, blue, light-blue, cyan, teal, green, light-green, lime, yellow,
        amber, orange, deep-orange, primary, accent, warn;

    $attributes: (
        color: $foreground,
        border-color: $foreground,
        background: $background,
        fill: $background,
        stroke: $background
    );

    // @each $saturation in $saturationLevels {
    //     // generate classes for all saturation levels of primary, accent and warn palettes
    //     @include _generate-classes("primary", $primary, $saturation);
    //     @include _generate-classes("accent", $accent, $saturation);
    //     @include _generate-classes("warn", $warn, $saturation);
    // }

    // // primary accent e warn
    // @each $paletteName, $palette in $palettes {
    //     // generate
    //     // .background-primary
    //     // .background-primary-alpha
    //     // .base-primary
    //     // .base-primary-alpha
    //     // .primary
    //     // .primary-alpha

    //     // .primary-bg
    //     // .primary-fg
    //     .background-#{$paletteName},
    //     .base-#{$paletteName},
    //     .#{$paletteName} {
    //         background: map-get($palette, default);
    //         color: map-get($palette, default-contrast);
    //         &-alpha {
    //             background: rgba(map-get($background, background), 0.5) !important;
    //         }
    //     }
    //     .#{$paletteName}-bg {
    //         background: map-get($palette, default) !important;
    //     }
    //     .#{$paletteName}-fg {
    //         color: map-get($palette, default) !important;
    //     }
    //     // .background-#{$paletteName}-alpha,
    //     // .base-#{$paletteName}-alpha {
    //     // }
    // }

    .base-surface {
        background: map-get($background, card);
        color: map-get($foreground, text);
        &-alpha {
            background: rgba(map-get($background, card), 0.5) !important;
        }
    }
    .base-fg {
        background: map-get($foreground, text);
        color: map-get($foreground, text-inverse);
        &-alpha {
            background: rgba(map-get($foreground, text), 0.2) !important;
        }
    }
    .base-bg {
        background: map-get($background, background);
        color: map-get($foreground, text);
        &-alpha {
            background: rgba(map-get($background, background), 0.5) !important;
        }
    }

    .background-transparent,
    .matcha-background-transparent {
        background: transparent;
    }

    @each $attribute, $colorLevel in $attributes {
        @if ($attribute != "color") {
            .#{$attribute}-bg {
                #{$attribute}: map-get($background, background) !important;
            }
            .#{$attribute}-bg-alpha {
                #{$attribute}: map-get($background, background-alpha) !important;
            }
            .#{$attribute}-bg-inverse {
                #{$attribute}: map-get($background, background-inverse) !important;
            }
            .#{$attribute}-bg-alpha-inverse {
                #{$attribute}: map-get($background, background-alpha-inverse) !important;
            }
            .#{$attribute}-surface-inverse {
                #{$attribute}: map-get($background, card-inverse) !important;
            }
            .#{$attribute}-surface-alpha-inverse {
                #{$attribute}: map-get($background, card-alpha-inverse) !important;
            }
            .primary {
                #{$attribute}: map-get($primary, default) !important;
            }
            .accent {
                #{$attribute}: map-get($accent, default) !important;
            }
            .warn {
                #{$attribute}: map-get($warn, default) !important;
            }
        }
        .#{$attribute}-primary {
            #{$attribute}: map-get($primary, default) !important;
            &-alpha {
                background: rgba(map-get($primary, default), 0.2) !important;
            }
        }
        .#{$attribute}-accent {
            #{$attribute}: map-get($accent, default) !important;
            &-alpha {
                background: rgba(map-get($accent, default), 0.2) !important;
            }
        }
        .#{$attribute}-warn {
            #{$attribute}: map-get($warn, default) !important;
            &-alpha {
                background: rgba(map-get($warn, default), 0.2) !important;
            }
        }
        .#{$attribute}-error {
            #{$attribute}: map-get($background, error) !important;
        }
        .#{$attribute}-info {
            #{$attribute}: map-get($background, info) !important;
        }
        .#{$attribute}-success {
            #{$attribute}: map-get($background, success) !important;
        }
        .#{$attribute}-warning {
            #{$attribute}: map-get($background, warning) !important;
        }
        .#{$attribute}-fg,
        .#{$attribute}-base,
        .#{$attribute}-text {
            #{$attribute}: map-get($foreground, text) !important;
        }

        .#{$attribute}-fg-inverse,
        .#{$attribute}-base-inverse,
        .#{$attribute}-text-inverse {
            #{$attribute}: map-get($foreground, text-inverse) !important;
        }

        .#{$attribute}-surface {
            #{$attribute}: map-get($background, card) !important;
        }

        .#{$attribute}-surface-alpha {
            #{$attribute}: map-get($background, card-alpha) !important;
        }

        .#{$attribute}-placeholder {
            #{$attribute}: map-get($foreground, placeholder) !important;
            &-alpha {
                background: rgba(map-get($foreground, placeholder), 0.2) !important;
            }
        }

        .#{$attribute}-label {
            #{$attribute}: map-get($foreground, label) !important;
        }

        .#{$attribute}-disabled {
            #{$attribute}: map-get($background, disabled) !important;
        }

        .#{$attribute}-disabled-icon {
            #{$attribute}: map-get($foreground, disabled-icon) !important;
        }

        .#{$attribute}-selected {
            #{$attribute}: map-get($background, selected-button) !important;
        }
    }
    @each $paletteName, $palette in $palettes {
        @include _generate-dynamic-color-classes($attributes, $palette, $paletteName, $background, $foreground);
    }
}
