@mixin vendor-ng-5-theme($theme) {
    $accent: map-get($theme, accent);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .ng5-slider {
        &.animate {
            .ng5-slider-pointer {
                transition: all 50ms linear !important;
            }
        }

        .ng5-slider-bubble {
            bottom: 20px;
            padding: 0;
            margin: 0px auto;
            background: map-get($background, card);
            color: map-get($foreground, label);
            transition: all 50ms linear !important;
        }

        .ng5-slider-bar {
            background-color: map-get($background, disabled);
        }

        .ng5-slider-pointer,
        .ng5-slider-selection {
            background-color: map-get($accent, default);
        }

        .ng5-slider-pointer:after {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 8px;
            left: 8px;
            border-radius: 8px;
            background-color: map-get($background, card);
        }

        .ng5-slider-pointer:active:after {
            background-color: map-get($background, card);
        }

        .ng5-slider-pointer.ng5-slider-active:after {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 8px;
            left: 8px;
            border-radius: 8px;
            background: map-get($background, background);
            background-color: map-get($background, background);
        }
    }
}
