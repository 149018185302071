@mixin vendor-chart($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    // Fixer wrappers
    .matcha-card,
    .matcha-card-flat,
    .matcha-section {

        //Google Chart
        google-chart {
            width: 100% !important;
            max-width: 100% !important;
            min-width: 100% !important;
            height: 100%;
        }

        google-chart * {
            max-width: 100% !important;
        }
    }

    #ngxcharts {
        ngx-charts-chart {
            svg {
                height: 400px;
                width: 400px;

                g {}
            }
        }
    }

    .chart-legend {
        background-color: transparent;
        padding: 0;
        width: 200px !important;
        min-width: 200px;
        max-width: 300px;
        display: -webkit-box;
        display: flex;
        right: 0;

        div {
            width: 100% !important;
        }

        .legend-labels {
            background: transparent !important;

            .active {
                .legend-label-text {
                    color: map-get($foreground, label);
                }
            }
        }

        .legend-label-color {
            border-radius: 12px !important;
        }

        .legend-label-text {
            font-weight: normal;
            font-size: 16px !important;
            line-height: 20px;
            color: map-get($foreground, text);
        }
    }

    // Large devices (tablets and small monitors, 1024px and up < 1440px)
    @media (min-width: 1024px) {
        .chart-legend {
            margin: 0 24px 0 0;
            border-radius: 8px;
            position: absolute;
            background-color: map-get($background, background-alpha);
            @include elevation(1);
        }
    }

    .pie-chart {
        .pie-label {
            fill: map-get($foreground, label);
            font-size: 16px !important;
            line-height: 20px;
            padding: 8px;
        }
    }
}
