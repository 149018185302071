@mixin matcha-cards-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .matcha-card,
    .matcha-card-flat {
        // background: map-get($background, card);
    }
}

// -----------------------------------------------------------------------------------------------------
// @ Cards
// -----------------------------------------------------------------------------------------------------
.matcha-card,
.matcha-card-flat {
    padding: 16px;
    border-radius: 8px;

    &-border,
    &-border-left {
        border-left: 8px solid;
    }

    &-border-right {
        border-right: 8px solid;
    }

    &.disabled {
        transition: opacity 100ms linear;
        opacity: 0.5;
        pointer-events: none;
    }

    &.enabled {
        transition: opacity 100ms linear;
        opacity: 1;
    }

    &.variable-width {
        min-width: 0;
    }

    &.auto-width {
        min-width: 0;
        max-width: none;
    }

    &.fixed-width {
        min-width: 264px;
        max-width: 264px;
        width: 264px;
    }

    // Buttons
    .mat-button {
        min-width: 0 !important;
        padding: 0 8px !important;
    }

    // Button Toggle Group
    .mat-button-toggle-group,
    .mat-button-toggle-standalone {
        box-shadow: none !important;
    }

    .mat-tab-label {
        min-width: 0 !important;
    }

    // Divider
    .card-divider {
        margin: 16px;

        &.full-width {
            margin: 0;
        }
    }

    // Expand Area
    .card-expand-area {
        overflow: hidden;

        .card-expanded-content {
            padding: 8px 16px 16px 16px;
            line-height: 1.75;
        }
    }

}

