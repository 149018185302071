/*
How to use
@import '~matcha-ds/assets/scss/components/matcha-header.scss';
@include matcha-headers-theme($theme);
*/
// -----------------------------------------------------------------------------------------------------
// Headers [Theme]
// -----------------------------------------------------------------------------------------------------
@mixin matcha-header-theme($theme) {
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .matcha-header {

        // matcha-header__content
        &__content {

            // matcha-header__content__title
            &__title {

                // matcha-header__content__title__icon
                &__icon {
                    color: map-get($foreground, label);
                }
            }

            &__options {
                background: map-get($background, card);
                @include elevation(1);
            }
        }
    }

    @media (min-width: 1024px) {
        .matcha-header {

            // matcha-header__content
            &__content {
                background: map-get($background, card);
                @include elevation(1);

                // matcha-header__content__title
                &__title {

                    // matcha-header__content__title__icon
                    &__icon {
                        color: map-get($foreground, label);
                    }
                }

                &__options {
                    background: transparent;
                    @include elevation(0);
                }
            }
        }

    }

    // X-Large devices (big desktops, 1440px and up < 1920)
    @media (min-width: 1440px) {}

    // XX-Large devices (larger desktops, 1920px and up)
    @media (min-width: 1920px) {}
}

// -----------------------------------------------------------------------------------------------------
// Headers [Style]
// -----------------------------------------------------------------------------------------------------
.matcha-header {

    // matcha-header__content
    &__content {
        // align-content: center;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: auto;

        >div:nth-child(n + 2) {
            margin-top: 8px;
        }


        // matcha-header__content--few-options
        &--few-options {
            .matcha-header__content__options__buttons {
                display: flex;
            }
        }

        // matcha-header__content--full-options
        &--full-options {
            .matcha-header__content__options__buttons {
                display: flex;
            }

            .main-btn-new {
                display: none;
            }

            .float-btn-new {
                display: inline-block;
            }
        }

        // matcha-header__content__title
        &__title {
            font-size: 20px;
            font-family: "CircularStd", "Helvetica Neue", "Arial", sans-serif;
            font-weight: 700;
            margin: 0 16px 0 0;
            line-height: 48px;
            min-height: 48px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;

            // matcha-header__content__title__icon
            &__icon {
                font-size: 32px;
                line-height: 32px;
                width: 32px;
                height: 32px;
                margin: 0px 16px 0 0px;
            }

            // matcha-header__content__title__label
            &__label {
                line-height: 24px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                white-space: initial;

                -webkit-box-orient: vertical;

                text-transform: capitalize;
            }

        }

        // matcha-header__content__options
        &__options {
            display: flex;
            margin: 0;
            flex-direction: column-reverse;
            justify-content: flex-end;
            width: 100%;
            padding: 16px;
            border-radius: 8px;

            >div:nth-child(n + 2) {
                margin-bottom: 8px;
            }

            // matcha-header__content__options__form
            &__form {
                display: flex;
                width: 100%;
                height: 48px;
                margin-top: 0px;

                form {
                    display: flex;
                    width: 100%;
                    height: 48px;
                    margin-top: 0px !important;

                    // Deprecated warning
                    // All angular material classes shuld become agnostics
                    .mat-form-field {
                        width: 100%;
                        margin: 0 0 0 0 !important;

                        .mat-form-field-wrapper {
                            margin: 0
                        }

                        .mat-form-field-outline-end {
                            border-radius: 0 0 0 0 !important;
                        }
                    }
                }

                .btn-search-filter {
                    margin: 0 0 0 -2px !important;
                    border-radius: 0 8px 8px 0 !important;
                }
            }

            // matcha-header__content__options__field
            &__field {
                display: flex;
                width: 100%;
                height: 48px;
                margin-top: 0px;

                form {
                    display: flex;
                    width: 100%;
                    height: 48px;
                    margin-top: 0px !important;
                }

                // Deprecated warning
                // All angular material classes shuld become agnostics
                .mat-form-field {
                    width: 100%;
                    margin: 0 0 0 0 !important;

                    .mat-form-field-wrapper {
                        margin: 0
                    }
                }

            }

            // matcha-header__content__options__buttons
            &__buttons {

                button {
                    &:nth-child(n):not(.float-btn-new):not(.btn-search-filter) {
                        margin: 0 8px 0 0;
                    }

                    &:first-child {
                        margin: 0 8px 0 0;
                    }
                }
            }
        }

        .float-btn-new {
            position: fixed;
            z-index: 9999;
            top: calc(100vh - 72px);
            left: calc(100vw - 72px);
        }
    }
}

// Medium devices (landscapes and tablets, 600px and up < 1024px )
@media (min-width: 600px) {}

// Large devices (tablets and small monitors, 1024px and up < 1440px)
@media (min-width: 1024px) {
    .matcha-header {
        &__content {
            padding: 16px;
            border-radius: 8px;
            flex-wrap: nowrap;

            >div:nth-child(n + 2) {
                margin-top: 0px;
            }

            &--few-options {
                .matcha-header__content__options__buttons {
                    display: flex;
                }
            }

            &--full-options {
                .matcha-header__content__options__buttons {
                    display: flex;
                }

                .main-btn-new {
                    display: inline-block;
                }

                .float-btn-new {
                    display: none;
                }
            }

            &__options {
                flex-direction: row;
                max-width: fit-content;
                padding: 0px;
                border-radius: 0px;

                >div:nth-child(n + 2) {
                    margin-bottom: 0px;
                }

                &__form,
                &__field {
                    min-width: 272px;
                }

                &__buttons {
                    margin-bottom: 0px;
                    display: flex;

                    button {
                        &:nth-child(n):not(.float-btn-new):not(.btn-search-filter) {
                            margin: 0 0 0 16px;
                        }

                        &:first-child {
                            margin: 0;
                        }
                    }

                }
            }
        }
    }
}

// X-Large devices (big desktops, 1440px and up < 1920)
@media (min-width: 1440px) {}

// XX-Large devices (larger desktops, 1920px and up)
@media (min-width: 1920px) {}
