// -----------------------------------------------------------------------------------------------------
// @ Absolute position alignment helpers
// -----------------------------------------------------------------------------------------------------
@each $breakpoint,
$materialBreakpoint in $helper-breakpoints {
    @include media-breakpoint($materialBreakpoint) {
        $infix: if($materialBreakpoint ==null, "", "-#{$breakpoint}");

        .place#{$infix}-top {
            top: 0;
        }

        .place#{$infix}-right {
            right: 0;
        }

        .place#{$infix}-bottom {
            bottom: 0;
        }

        .place#{$infix}-left {
            left: 0;
        }
    }
}

// -----------------------------------------------------------------------------------------------------
// @ Position helpers
// -----------------------------------------------------------------------------------------------------
@each $breakpoint,
$materialBreakpoint in $helper-breakpoints {
    @include media-breakpoint($materialBreakpoint) {
        $infix: if($materialBreakpoint ==null, "", "-#{$breakpoint}");

        .position#{$infix}-relative {
            position: relative;
        }

        .position#{$infix}-absolute {
            position: absolute;
        }

        .position#{$infix}-static {
            position: static;
        }

        .position#{$infix}-fixed {
            position: fixed;
        }
    }
}
