/*
How to use
@import '~matcha-ds/assets/scss/components/matcha-draggable.scss';
@include matcha-draggable-theme($theme);
*/
// -----------------------------------------------------------------------------------------------------
// Draggable [Theme]
// -----------------------------------------------------------------------------------------------------
@mixin matcha-draggable-theme($theme) {
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .matcha-draggable {
        .check-with-label:checked+.label-for-check {
            box-shadow: 0px 0px 0px 8px map-get($background, card), 0px 0px 0px 10px map-get($foreground, placeholder);
        }

        .is-selected {
            box-shadow: 0px 0px 0px 8px map-get($background, card), 0px 0px 0px 10px map-get($foreground, placeholder);
        }
    }
}
