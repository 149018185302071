/*
How to use
@import '~matcha-ds/assets/scss/components/matcha-typography.scss';
@include base-typography-theme($theme);
*/
// -----------------------------------------------------------------------------------------------------
// Typography [Theme]
// -----------------------------------------------------------------------------------------------------
@mixin base-typography-theme($theme) {
    $is-dark: map-get($theme, is-dark);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    a {
        color: map-get($accent, default);
    }

    code {
        &:not(.highlight) {
            background-color: map-get($background, card);
        }
    }

    .changelog {
        .entry {
            background-color: map-get($background, card);
        }
    }

    .text-boxed {
        @if ($is-dark) {
            background-color: rgba(255, 255, 255, 0.12);
            color: rgba(255, 255, 255, 0.54);
        } @else {
            background-color: rgba(0, 0, 0, 0.12);
            color: rgba(0, 0, 0, 0.54);
        }
    }

    .text-primary {
        color: map-get($primary, default) !important;
    }

    .text-warn {
        color: map-get($warn, default) !important;
    }

    .text-label {
        color: map-get($foreground, label) !important;
    }

    .text-placeholder {
        color: map-get($foreground, placeholder) !important;
    }

    .text-basic {
        color: map-get($foreground, text) !important;
    }

    // Changelog
    .changelog {
        .entry {
            .groups {
                .breaking-changes {
                    .title {
                        background: map-get($background, red);
                    }
                }

                .new {
                    .title {
                        background: map-get($background, green);
                    }
                }

                .improved {
                    .title {
                        background: map-get($background, purple);
                    }
                }

                .fixed {
                    .title {
                        background: map-get($background, blue);
                    }
                }
            }
        }
    }

    // Code
    code {
        &:not(.highlight) {
            color: map-get($background, blue);
        }
    }

    // Mark
    mark {
        background: map-get($background, yellow);
    }

    // Message boxes
    .matcha-message-box,
    .matcha-msg-box,
    .message-box {
        background-color: rgba(map-get($primary, default), 0.2);
        border-left-color: map-get($primary, default);
        color: map-get($foreground, text);

        &.error,
        &.warning,
        &.success,
        &.info,
        &--error,
        &--warning,
        &--success,
        &--info {
            color: map-get($foreground, text);
        }

        &--error,
        &.error {
            background-color: map-get($background, red-alpha);
            border-left-color: map-get($background, red);
        }

        &--warning,
        &.warning {
            background-color: map-get($background, yellow-alpha);
            border-left-color: map-get($background, yellow);
        }

        &--success,
        &.success {
            background-color: map-get($background, green-alpha);
            border-left-color: map-get($background, green);
        }

        &--info,
        &.info {
            background-color: map-get($background, blue-alpha);
            border-left-color: map-get($background, blue);
        }
    }
}

// -----------------------------------------------------------------------------------------------------
// Typography [Style]
// -----------------------------------------------------------------------------------------------------
// Paragraph
.par-xs {
    font-size: 12px !important;
}

.par-sm,
.par-p {
    font-size: 14px !important;
}

.par-md,
.par-m {
    font-size: 16px !important;
}

.par-lg {
    font-size: 20px !important;
}

.par-xl {
    font-size: 24px !important;
}
.text-medium,
.text-semibold {
    font-weight: 500;
}

.text-regular,
.text-book {
    font-weight: 400;
}

.text-bold {
    font-weight: 700;
}

.text-black {
    font-weight: 900;
}

.matcha-message-box,
.matcha-msg-box,
.message-box {
    padding: 16px;
    border-left: 6px solid;

    &.error,
    &.warning,
    &.success,
    &.info,
    &--error,
    &--warning,
    &--success,
    &--info {
        padding: 16px;
        border-left: 6px solid;
    }
}

.title {
    line-height: 24px;
    margin: 0 0 16px 0;
    display: flex;
    align-items: center;

    .title-bullet {
        margin: 0px 8px 0 0;
        display: inline-block;
        position: relative;
        border-radius: 10px;
    }

    .title-content {
        line-height: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        white-space: initial;
        -webkit-box-orient: vertical;
        text-transform: capitalize;
    }
}

.title-sm {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
