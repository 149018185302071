/** Generated by icomoon **/
/* Matcha Icons Relative path:
  ../node_modules/matcha-icons/fonts/matcha-icons.eot
*/

@font-face {
    font-family  : 'MatchaIcons';
    src:  url('./fonts/matcha-icons.eot');
    src:  url('./fonts/matcha-icons.eot?#iefix') format('embedded-opentype'),
            url('./fonts/matcha-icons.ttf') format('truetype'),
            url('./fonts/matcha-icons.woff') format('woff'),
            url('./fonts/matcha-icons.svg') format('svg');
    font-weight  : normal;
    font-style   : normal;
    font-display : block;
}

[class^="i-matcha-"], [class*=" i-matcha-"] {
/* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'MatchaIcons' !important;
  /* speak: none; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 24px;
  font-size: 24px;
  height: 24px;
  width: 24px;
  position: relative;
  /* top: -1px; */
  display: inline-block;
  vertical-align: middle;
/* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
  
[class^="i-matcha-"].i-size-xs, [class*=" i-matcha-"].i-size-xs {
  line-height: 12px;
  font-size: 12px;
  height: 12px;
  width: 12px;
}

[class^="i-matcha-"].i-size-sm, [class*=" i-matcha-"].i-size-sm {
  line-height: 16px;
  font-size: 16px;
  height: 16px;
  width: 16px;
}

[class^="i-matcha-"].i-size-md, [class*=" i-matcha-"].i-size-md {
  line-height: 24px;
  font-size: 24px;
  height: 24px;
  width: 24px;
}

[class^="i-matcha-"].i-size-lg, [class*=" i-matcha-"].i-size-lg {
  line-height: 32px;
  font-size: 32px;
  height: 32px;
  width: 32px;
}

[class^="i-matcha-"].i-size-xl, [class*=" i-matcha-"].i-size-xl {
  line-height: 48px;
  font-size: 48px;
  height: 48px;
  width: 48px;
}

/*
// -------------------------------------------------------------------------------------------------------------------
// @ Paste library update below
// -------------------------------------------------------------------------------------------------------------------
*/

.i-matcha-app:before {
  content: "\ea93";
}
.i-matcha-site:before {
  content: "\ea94";
}
.i-matcha-home:before {
  content: "\e948";
}
.i-matcha-home-out:before {
  content: "\e964";
}
.i-matcha-content:before {
  content: "\e942";
}
.i-matcha-content-out:before {
  content: "\e95e";
}
.i-matcha-events:before {
  content: "\e945";
}
.i-matcha-events-out:before {
  content: "\e961";
}
.i-matcha-favorite:before {
  content: "\e946";
}
.i-matcha-favorite-out:before {
  content: "\e962";
}
.i-matcha-tv:before {
  content: "\e954";
}
.i-matcha-tv-out:before {
  content: "\e970";
}
.i-matcha-bible:before {
  content: "\e93a";
}
.i-matcha-bible-out:before {
  content: "\e957";
}
.i-matcha-reading-plan:before {
  content: "\e951";
}
.i-matcha-reading-plan-out:before {
  content: "\e96d";
}
.i-matcha-agenda:before {
  content: "\e939";
}
.i-matcha-agenda-out:before {
  content: "\e956";
}
.i-matcha-church:before {
  content: "\e940";
}
.i-matcha-church-out:before {
  content: "\e95c";
}
.i-matcha-download:before {
  content: "\e943";
}
.i-matcha-downloads-out:before {
  content: "\e95f";
}
.i-matcha-groups:before {
  content: "\e947";
}
.i-matcha-groups-out:before {
  content: "\e963";
}
.i-matcha-live:before {
  content: "\e94a";
}
.i-matcha-live-out:before {
  content: "\e966";
}
.i-matcha-star:before {
  content: "\e952";
}
.i-matcha-star-out:before {
  content: "\e96e";
}
.i-matcha-news:before {
  content: "\e94d";
}
.i-matcha-news-out:before {
  content: "\e969";
}
.i-matcha-edit:before {
  content: "\e944";
}
.i-matcha-edit-out:before {
  content: "\e960";
}
.i-matcha-web:before {
  content: "\e955";
}
.i-matcha-web-out:before {
  content: "\e971";
}
.i-matcha-prayer:before {
  content: "\e94e";
}
.i-matcha-prayer-out:before {
  content: "\e96a";
}
.i-matcha-radio:before {
  content: "\e950";
}
.i-matcha-radio-out:before {
  content: "\e96c";
}
.i-matcha-id-card:before {
  content: "\e93d";
}
.i-matcha-id-card-out:before {
  content: "\e965";
}
.i-matcha-cell:before {
  content: "\e93f";
}
.i-matcha-cell-out:before {
  content: "\e95b";
}
.i-matcha-business:before {
  content: "\e93c";
}
.i-matcha-business-out:before {
  content: "\e959";
}
.i-matcha-contact:before {
  content: "\e941";
}
.i-matcha-contact-out:before {
  content: "\e95d";
}
.i-matcha-store:before {
  content: "\e953";
}
.i-matcha-store-out:before {
  content: "\e96f";
}
.i-matcha-mykids:before {
  content: "\e94c";
}
.i-matcha-mykids-out:before {
  content: "\e968";
}
.i-matcha-profile:before {
  content: "\e94f";
}
.i-matcha-profile-out:before {
  content: "\e96b";
}
.i-matcha-bright:before {
  content: "\e93b";
}
.i-matcha-bright-out:before {
  content: "\e958";
}
.i-matcha-case:before {
  content: "\e93e";
}
.i-matcha-case-out:before {
  content: "\e95a";
}
.i-matcha-cake:before {
  content: "\e9b5";
}
.i-matcha-link:before {
  content: "\e949";
}
.i-matcha-list:before {
  content: "\e94b";
}
.i-matcha-grid:before {
  content: "\e9de";
}
.i-matcha-grid-out:before {
  content: "\e9df";
}
.i-matcha-contrast:before {
  content: "\e99f";
}
.i-matcha-check-in:before {
  content: "\e933";
}
.i-matcha-check-out:before {
  content: "\e9f5";
}
.i-matcha-counseling:before {
  content: "\e9f6";
}
.i-matcha-counseling-out:before {
  content: "\e9f7";
}
.i-matcha-notes:before {
  content: "\e97e";
}
.i-matcha-notes-out:before {
  content: "\e9f8";
}
.i-matcha-print:before {
  content: "\e9f9";
}
.i-matcha-print-out:before {
  content: "\e9fa";
}
.i-matcha-print2:before {
  content: "\eae7";
}
.i-matcha-print-out2:before {
  content: "\eae8";
}
.i-matcha-smile:before {
  content: "\e9fb";
}
.i-matcha-notification:before {
  content: "\e9fc";
}
.i-matcha-light-mode:before {
  content: "\e9e3";
}
.i-matcha-dark-mode:before {
  content: "\e9e4";
}
.i-matcha-dark-mode-2:before {
  content: "\e9e5";
}
.i-matcha-category:before {
  content: "\e978";
}
.i-matcha-bank:before {
  content: "\e976";
}
.i-matcha-bank1:before {
  content: "\eb15";
}
.i-matcha-bank-circle:before {
  content: "\eb16";
}
.i-matcha-visitor:before {
  content: "\e983";
}
.i-matcha-participant:before {
  content: "\e97f";
}
.i-matcha-kids:before {
  content: "\ea20";
}
.i-matcha-arrow-left:before {
  content: "\e973";
}
.i-matcha-arrow-up:before {
  content: "\e975";
}
.i-matcha-arrow-down:before {
  content: "\e972";
}
.i-matcha-arrow-right:before {
  content: "\e974";
}
.i-matcha-chevron-left:before {
  content: "\e9b1";
}
.i-matcha-chevron-right:before {
  content: "\e9e0";
}
.i-matcha-chevron-down:before {
  content: "\e9e1";
}
.i-matcha-chevron-up:before {
  content: "\e9e2";
}
.i-matcha-reading:before {
  content: "\e981";
}
.i-matcha-blocked:before {
  content: "\e977";
}
.i-matcha-reports:before {
  content: "\e982";
}
.i-matcha-dashboard:before {
  content: "\e97a";
}
.i-matcha-graph:before {
  content: "\e97c";
}
.i-matcha-education:before {
  content: "\e97b";
}
.i-matcha-phone:before {
  content: "\e980";
}
.i-matcha-contact-us:before {
  content: "\e979";
}
.i-matcha-audio:before {
  content: "\e984";
}
.i-matcha-audio-out:before {
  content: "\e991";
}
.i-matcha-foward:before {
  content: "\e986";
}
.i-matcha-foward-out:before {
  content: "\e993";
}
.i-matcha-next:before {
  content: "\e989";
}
.i-matcha-next1:before {
  content: "\e996";
}
.i-matcha-pause:before {
  content: "\e98a";
}
.i-matcha-pause-out:before {
  content: "\e997";
}
.i-matcha-play:before {
  content: "\e98b";
}
.i-matcha-play-out:before {
  content: "\e998";
}
.i-matcha-microphone:before {
  content: "\e988";
}
.i-matcha-microphone-out:before {
  content: "\e995";
}
.i-matcha-previous:before {
  content: "\e98c";
}
.i-matcha-previous-out:before {
  content: "\e999";
}
.i-matcha-rewind:before {
  content: "\e98d";
}
.i-matcha-rewind-out:before {
  content: "\e99a";
}
.i-matcha-songbook:before {
  content: "\e98e";
}
.i-matcha-songbook-out:before {
  content: "\e99b";
}
.i-matcha-video:before {
  content: "\e990";
}
.i-matcha-video-out:before {
  content: "\e99d";
}
.i-matcha-upload:before {
  content: "\e98f";
}
.i-matcha-upload-out:before {
  content: "\e99c";
}
.i-matcha-gallery:before {
  content: "\e987";
}
.i-matcha-image-out:before {
  content: "\e994";
}
.i-matcha-edit-image:before {
  content: "\ea92";
}
.i-matcha-bonus:before {
  content: "\e985";
}
.i-matcha-bonus-out:before {
  content: "\e992";
}
.i-matcha-save:before {
  content: "\e9fd";
}
.i-matcha-save-out:before {
  content: "\e9fe";
}
.i-matcha-lightbulb:before {
  content: "\e9ff";
}
.i-matcha-lightbulb-out:before {
  content: "\ea00";
}
.i-matcha-laptop:before {
  content: "\ea01";
}
.i-matcha-phone1:before {
  content: "\ea02";
}
.i-matcha-monitor:before {
  content: "\ea03";
}
.i-matcha-monitor-out:before {
  content: "\ea04";
}
.i-matcha-testimony:before {
  content: "\ea05";
}
.i-matcha-testimony-out:before {
  content: "\ea06";
}
.i-matcha-management:before {
  content: "\eae1";
}
.i-matcha-pdf:before {
  content: "\e9e6";
}
.i-matcha-podtail:before {
  content: "\e9a0";
}
.i-matcha-net:before {
  content: "\e99e";
}
.i-matcha-contact1:before {
  content: "\e9e7";
}
.i-matcha-copy-content:before {
  content: "\ea07";
}
.i-matcha-about:before {
  content: "\e9c8";
}
.i-matcha-about-out:before {
  content: "\e9cc";
}
.i-matcha-copy:before {
  content: "\e9c9";
}
.i-matcha-copy-out:before {
  content: "\ea08";
}
.i-matcha-copy-folder:before {
  content: "\ea09";
}
.i-matcha-copy-folder-out:before {
  content: "\ea0a";
}
.i-matcha-new-note:before {
  content: "\e9ca";
}
.i-matcha-new-note-out:before {
  content: "\ea0b";
}
.i-matcha-folder-closed:before {
  content: "\e9d0";
}
.i-matcha-folder-opened:before {
  content: "\e9d1";
}
.i-matcha-note:before {
  content: "\e9cb";
}
.i-matcha-note-out:before {
  content: "\e9cf";
}
.i-matcha-copy-out1:before {
  content: "\e9cd";
}
.i-matcha-new-note-out1:before {
  content: "\e9ce";
}
.i-matcha-start-presentation:before {
  content: "\eb0f";
}
.i-matcha-cancel-presentation:before {
  content: "\eb10";
}
.i-matcha-text:before {
  content: "\e9dc";
}
.i-matcha-text-align-left:before {
  content: "\e9d7";
}
.i-matcha-text-bullet-list:before {
  content: "\e9d9";
}
.i-matcha-text-align-center:before {
  content: "\e9d5";
}
.i-matcha-text-indent-back:before {
  content: "\e9da";
}
.i-matcha-text-align-justify:before {
  content: "\e9d6";
}
.i-matcha-text-indent-foward:before {
  content: "\e9db";
}
.i-matcha-text-align-right:before {
  content: "\e9d8";
}
.i-matcha-font-bold:before {
  content: "\e9d2";
}
.i-matcha-font-underline:before {
  content: "\e9d4";
}
.i-matcha-font-italic:before {
  content: "\e9d3";
}
.i-matcha-delete:before {
  content: "\e902";
}
.i-matcha-delete-out:before {
  content: "\e915";
}
.i-matcha-filter:before {
  content: "\ea0d";
}
.i-matcha-filter-out:before {
  content: "\e916";
}
.i-matcha-rocket:before {
  content: "\ea91";
}
.i-matcha-rocket-out:before {
  content: "\eae6";
}
.i-matcha-filter-bars:before {
  content: "\e903";
}
.i-matcha-fullscreen:before {
  content: "\e905";
}
.i-matcha-fullscreen-out:before {
  content: "\e918";
}
.i-matcha-fullscreen-exit:before {
  content: "\e904";
}
.i-matcha-fullscreen-exit-out:before {
  content: "\e917";
}
.i-matcha-settings:before {
  content: "\e90d";
}
.i-matcha-settings-out:before {
  content: "\e920";
}
.i-matcha-share:before {
  content: "\e90e";
}
.i-matcha-share-out:before {
  content: "\e921";
}
.i-matcha-toggle-off:before {
  content: "\e90f";
}
.i-matcha-toggle-on:before {
  content: "\e910";
}
.i-matcha-toggle-off-out:before {
  content: "\e922";
}
.i-matcha-toggle-on-out:before {
  content: "\e923";
}
.i-matcha-visibility-on:before {
  content: "\e912";
}
.i-matcha-visibility-off:before {
  content: "\e911";
}
.i-matcha-visibility-on-out:before {
  content: "\e925";
}
.i-matcha-visibility-off-out:before {
  content: "\e924";
}
.i-matcha-logout:before {
  content: "\e908";
}
.i-matcha-logout-out:before {
  content: "\e91b";
}
.i-matcha-search:before {
  content: "\e90c";
}
.i-matcha-search-out:before {
  content: "\e91f";
}
.i-matcha-notification1:before {
  content: "\e90a";
}
.i-matcha-notification-out:before {
  content: "\e91d";
}
.i-matcha-password:before {
  content: "\e90b";
}
.i-matcha-password-out:before {
  content: "\e91e";
}
.i-matcha-calendar:before {
  content: "\e901";
}
.i-matcha-calendar-out:before {
  content: "\e914";
}
.i-matcha-menu:before {
  content: "\e909";
}
.i-matcha-menu-out:before {
  content: "\e91c";
}
.i-matcha-info:before {
  content: "\e906";
}
.i-matcha-info-out:before {
  content: "\e919";
}
.i-matcha-leadership:before {
  content: "\e907";
}
.i-matcha-leadership-out:before {
  content: "\e91a";
}
.i-matcha-attachment:before {
  content: "\e900";
}
.i-matcha-attachment-out:before {
  content: "\e913";
}
.i-matcha-send:before {
  content: "\ea0e";
}
.i-matcha-action_sign_error:before {
  content: "\e935";
}
.i-matcha-action_close:before {
  content: "\e92a";
}
.i-matcha-action_sign_success:before {
  content: "\e937";
}
.i-matcha-action_done:before {
  content: "\e92b";
}
.i-matcha-action_sign_warning:before {
  content: "\e938";
}
.i-matcha-action_sign_warning-out:before {
  content: "\ea0f";
}
.i-matcha-feedback:before {
  content: "\ea10";
}
.i-matcha-feedback-out:before {
  content: "\ea11";
}
.i-matcha-action_get_up:before {
  content: "\e92d";
}
.i-matcha-action_get_down:before {
  content: "\e92c";
}
.i-matcha-action_move:before {
  content: "\e930";
}
.i-matcha-policy:before {
  content: "\e92e";
}
.i-matcha-action_key:before {
  content: "\ea64";
}
.i-matcha-action_sign_info:before {
  content: "\e936";
}
.i-matcha-action_sign_info-out:before {
  content: "\ea12";
}
.i-matcha-qr-code-no-border:before {
  content: "\ea13";
}
.i-matcha-qr-code:before {
  content: "\ea14";
}
.i-matcha-drag-dots:before {
  content: "\ea15";
}
.i-matcha-drag-bars:before {
  content: "\ea16";
}
.i-matcha-action_arrow_down:before {
  content: "\e926";
}
.i-matcha-action_arrow_left:before {
  content: "\e927";
}
.i-matcha-action_arrow_up:before {
  content: "\e929";
}
.i-matcha-action_arrow_right:before {
  content: "\e928";
}
.i-matcha-payment-cash .path1:before {
  content: "\ea95";
  color: rgb(14, 147, 71);
}
.i-matcha-payment-cash .path2:before {
  content: "\ea96";
  margin-left: -1em;
  color: rgb(59, 181, 74);
}
.i-matcha-payment-cash .path3:before {
  content: "\ea97";
  margin-left: -1em;
  color: rgb(137, 199, 99);
}
.i-matcha-payment-card .path1:before {
  content: "\ea98";
  color: rgb(95, 130, 226);
}
.i-matcha-payment-card .path2:before {
  content: "\ea99";
  margin-left: -1em;
  color: rgb(121, 153, 237);
}
.i-matcha-payment-card .path3:before {
  content: "\ea9a";
  margin-left: -1em;
  color: rgb(240, 196, 27);
}
.i-matcha-payment-card .path4:before {
  content: "\ea9b";
  margin-left: -1em;
  color: rgb(243, 213, 92);
}
.i-matcha-payment-card .path5:before {
  content: "\ea9c";
  margin-left: -1em;
  color: rgb(231, 76, 61);
}
.i-matcha-payment-card .path6:before {
  content: "\ea9d";
  margin-left: -1em;
  color: rgb(240, 196, 27);
}
.i-matcha-payment-visa .path1:before {
  content: "\ea9e";
  color: rgb(36, 42, 136);
}
.i-matcha-payment-visa .path2:before {
  content: "\ea9f";
  margin-left: -1em;
  color: rgb(245, 247, 250);
}
.i-matcha-payment-ticket .path1:before {
  content: "\eaa0";
  color: rgb(237, 237, 237);
}
.i-matcha-payment-ticket .path2:before {
  content: "\eaa1";
  margin-left: -1em;
  color: rgb(0, 124, 180);
}
.i-matcha-payment-ticket .path3:before {
  content: "\eaa2";
  margin-left: -1em;
  color: rgb(217, 38, 47);
}
.i-matcha-payment-elo .path1:before {
  content: "\eaa3";
  color: rgb(34, 55, 68);
}
.i-matcha-payment-elo .path2:before {
  content: "\eaa4";
  margin-left: -1em;
  color: rgb(255, 241, 0);
}
.i-matcha-payment-elo .path3:before {
  content: "\eaa5";
  margin-left: -1em;
  color: rgb(0, 163, 223);
}
.i-matcha-payment-elo .path4:before {
  content: "\eaa6";
  margin-left: -1em;
  color: rgb(238, 64, 35);
}
.i-matcha-payment-elo .path5:before {
  content: "\eaa7";
  margin-left: -1em;
  color: rgb(245, 247, 250);
}
.i-matcha-payment-hipercard .path1:before {
  content: "\eaa8";
  color: rgb(237, 237, 237);
}
.i-matcha-payment-hipercard .path2:before {
  content: "\eaa9";
  margin-left: -1em;
  color: rgb(179, 19, 27);
}
.i-matcha-payment-diners .path1:before {
  content: "\eaaa";
  color: rgb(0, 121, 190);
}
.i-matcha-payment-diners .path2:before {
  content: "\eaab";
  margin-left: -1em;
  color: rgb(245, 247, 250);
}
.i-matcha-payment-credicard .path1:before {
  content: "\eaac";
  color: rgb(237, 237, 237);
}
.i-matcha-payment-credicard .path2:before {
  content: "\eaad";
  margin-left: -1em;
  color: rgb(41, 45, 120);
}
.i-matcha-payment-aura .path1:before {
  content: "\eaae";
  color: rgb(0, 121, 190);
}
.i-matcha-payment-aura .path2:before {
  content: "\eaaf";
  margin-left: -1em;
  color: rgb(254, 218, 97);
}
.i-matcha-payment-aura .path3:before {
  content: "\eab0";
  margin-left: -1em;
  color: rgb(216, 35, 42);
}
.i-matcha-payment-american-express .path1:before {
  content: "\eab1";
  color: rgb(37, 87, 214);
}
.i-matcha-payment-american-express .path2:before {
  content: "\eab2";
  margin-left: -1em;
  color: rgb(245, 247, 250);
}
.i-matcha-payment-american-express .path3:before {
  content: "\eab3";
  margin-left: -1em;
  color: rgb(245, 247, 250);
}
.i-matcha-payment-pix .path1:before {
  content: "\eab4";
  color: rgb(237, 237, 237);
}
.i-matcha-payment-pix .path2:before {
  content: "\eab5";
  margin-left: -1em;
  color: rgb(30, 172, 125);
}
.i-matcha-payment-pix .path3:before {
  content: "\eab6";
  margin-left: -1em;
  color: rgb(30, 172, 125);
}
.i-matcha-payment-master .path1:before {
  content: "\eab7";
  color: rgb(34, 55, 68);
}
.i-matcha-payment-master .path2:before {
  content: "\eab8";
  margin-left: -1em;
  color: rgb(255, 95, 0);
}
.i-matcha-payment-master .path3:before {
  content: "\eab9";
  margin-left: -1em;
  color: rgb(235, 0, 27);
}
.i-matcha-payment-master .path4:before {
  content: "\eaba";
  margin-left: -1em;
  color: rgb(247, 158, 27);
}
.i-matcha-payment-master .path5:before {
  content: "\eabb";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.i-matcha-payment-maestro .path1:before {
  content: "\eabc";
  color: rgb(34, 55, 68);
}
.i-matcha-payment-maestro .path2:before {
  content: "\eabd";
  margin-left: -1em;
  color: rgb(245, 247, 250);
}
.i-matcha-payment-maestro .path3:before {
  content: "\eabe";
  margin-left: -1em;
  color: rgb(118, 115, 192);
}
.i-matcha-payment-maestro .path4:before {
  content: "\eabf";
  margin-left: -1em;
  color: rgb(235, 0, 27);
}
.i-matcha-payment-maestro .path5:before {
  content: "\eac0";
  margin-left: -1em;
  color: rgb(0, 161, 223);
}
.i-matcha-payment-alelo .path1:before {
  content: "\eac1";
  color: rgb(0, 116, 85);
}
.i-matcha-payment-alelo .path2:before {
  content: "\eac2";
  margin-left: -1em;
  color: rgb(183, 210, 86);
}
.i-matcha-payment-alelo .path3:before {
  content: "\eac3";
  margin-left: -1em;
  color: rgb(245, 247, 250);
}
.i-matcha-payment-bank-slip .path1:before {
  content: "\eac4";
  color: rgb(237, 237, 237);
}
.i-matcha-payment-bank-slip .path2:before {
  content: "\eac5";
  margin-left: -1em;
  color: rgb(99, 111, 120);
}
.i-matcha-vr-refeicao .path1:before {
  content: "\eac6";
  color: rgb(17, 144, 70);
}
.i-matcha-vr-refeicao .path2:before {
  content: "\eac7";
  margin-left: -1em;
  color: rgb(235, 139, 45);
}
.i-matcha-vr-refeicao .path3:before {
  content: "\eac8";
  margin-left: -1em;
  color: rgb(245, 247, 250);
}
.i-matcha-vr-auto .path1:before {
  content: "\eac9";
  color: rgb(17, 144, 70);
}
.i-matcha-vr-auto .path2:before {
  content: "\eaca";
  margin-left: -1em;
  color: rgb(27, 27, 27);
}
.i-matcha-vr-auto .path3:before {
  content: "\eacb";
  margin-left: -1em;
  color: rgb(245, 247, 250);
}
.i-matcha-vr-alimentacao .path1:before {
  content: "\eacc";
  color: rgb(17, 144, 70);
}
.i-matcha-vr-alimentacao .path2:before {
  content: "\eacd";
  margin-left: -1em;
  color: rgb(232, 4, 126);
}
.i-matcha-vr-alimentacao .path3:before {
  content: "\eace";
  margin-left: -1em;
  color: rgb(245, 247, 250);
}
.i-matcha-action_plus:before {
  content: "\e932";
}
.i-matcha-minus:before {
  content: "\ea17";
}
.i-matcha-action_reload:before {
  content: "\e934";
}
.i-matcha-transfer:before {
  content: "\eacf";
}
.i-matcha-cost-center:before {
  content: "\ead0";
}
.i-matcha-expand:before {
  content: "\ead1";
}
.i-matcha-collapse:before {
  content: "\ead2";
}
.i-matcha-action_list:before {
  content: "\e92f";
}
.i-matcha-action_options:before {
  content: "\e931";
}
.i-matcha-historic:before {
  content: "\ea18";
}
.i-matcha-load .path1:before {
  content: "\ea19";
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.i-matcha-load .path2:before {
  content: "\ea1a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.i-matcha-plugin:before {
  content: "\ea1b";
}
.i-matcha-header-panel:before {
  content: "\e97d";
}
.i-matcha-modules:before {
  content: "\ea1c";
}
.i-matcha-view-row:before {
  content: "\eb11";
}
.i-matcha-view-column:before {
  content: "\eb12";
}
.i-matcha-platform:before {
  content: "\ea1d";
}
.i-matcha-barcode:before {
  content: "\e9a1";
}
.i-matcha-barcode-out:before {
  content: "\e9aa";
}
.i-matcha-camera:before {
  content: "\e9a2";
}
.i-matcha-camera-out:before {
  content: "\e9ab";
}
.i-matcha-cast:before {
  content: "\e9a4";
}
.i-matcha-cast-out:before {
  content: "\e9ad";
}
.i-matcha-location:before {
  content: "\e9a7";
}
.i-matcha-location-out:before {
  content: "\e9b0";
}
.i-matcha-clock:before {
  content: "\e9a5";
}
.i-matcha-clock-out:before {
  content: "\e9ae";
}
.i-matcha-card:before {
  content: "\e9a3";
}
.i-matcha-card-out:before {
  content: "\e9ac";
}
.i-matcha-add-card:before {
  content: "\ead3";
}
.i-matcha-update-balance:before {
  content: "\ead4";
}
.i-matcha-cash:before {
  content: "\ead5";
}
.i-matcha-cash-out:before {
  content: "\ead6";
}
.i-matcha-pin:before {
  content: "\ead7";
}
.i-matcha-pin-out:before {
  content: "\ead8";
}
.i-matcha-flag:before {
  content: "\ead9";
}
.i-matcha-flag-out:before {
  content: "\eada";
}
.i-matcha-category1:before {
  content: "\eadb";
}
.i-matcha-category-out:before {
  content: "\eadc";
}
.i-matcha-media:before {
  content: "\eadd";
}
.i-matcha-media-out:before {
  content: "\eade";
}
.i-matcha-wallet:before {
  content: "\eadf";
}
.i-matcha-wallet-out:before {
  content: "\eae0";
}
.i-matcha-business-unit:before {
  content: "\eae2";
}
.i-matcha-receipt:before {
  content: "\e9a9";
}
.i-matcha-receipt-out:before {
  content: "\e9b2";
}
.i-matcha-circle-email:before {
  content: "\e9c5";
}
.i-matcha-email:before {
  content: "\e9a6";
}
.i-matcha-email-out:before {
  content: "\e9af";
}
.i-matcha-pix:before {
  content: "\e9a8";
}
.i-matcha-pix-out:before {
  content: "\e9e8";
}
.i-matcha-location-cell:before {
  content: "\e9e9";
}
.i-matcha-location-cell-out:before {
  content: "\e967";
}
.i-matcha-image-rights:before {
  content: "\e9bc";
}
.i-matcha-image-rights-allowed:before {
  content: "\e9bd";
}
.i-matcha-forbidden-passage:before {
  content: "\e9b3";
}
.i-matcha-allowed-passage:before {
  content: "\e9b4";
}
.i-matcha-food-restriction:before {
  content: "\e9be";
}
.i-matcha-no-food-restriction:before {
  content: "\e9bf";
}
.i-matcha-allergy:before {
  content: "\e9c0";
}
.i-matcha-special-need:before {
  content: "\e9c1";
}
.i-matcha-map:before {
  content: "\e9b8";
}
.i-matcha-apple:before {
  content: "\e9b6";
}
.i-matcha-number:before {
  content: "\e9b9";
}
.i-matcha-flow:before {
  content: "\e9b7";
}
.i-matcha-ibf-church:before {
  content: "\e9ba";
}
.i-matcha-process-barcode:before {
  content: "\e9bb";
}
.i-matcha-process-card:before {
  content: "\e9c2";
}
.i-matcha-box-opened:before {
  content: "\e9c3";
}
.i-matcha-parent:before {
  content: "\ea1e";
}
.i-matcha-kid-girl:before {
  content: "\ea1f";
}
.i-matcha-kid-boy:before {
  content: "\eb17";
}
.i-matcha-people:before {
  content: "\ea21";
}
.i-matcha-circle-code:before {
  content: "\e9c4";
}
.i-matcha-circle-facebook:before {
  content: "\e9c6";
}
.i-matcha-facebook:before {
  content: "\e9ea";
}
.i-matcha-circle-instagram:before {
  content: "\e9c7";
}
.i-matcha-instagram:before {
  content: "\e9eb";
}
.i-matcha-soundcloud:before {
  content: "\e9dd";
}
.i-matcha-circle-soundcloud:before {
  content: "\ea5d";
}
.i-matcha-spotify:before {
  content: "\e9ec";
}
.i-matcha-circle-spotify:before {
  content: "\ea5e";
}
.i-matcha-circle-telegram:before {
  content: "\e9ed";
}
.i-matcha-telegram:before {
  content: "\e9ee";
}
.i-matcha-circle-tiktok:before {
  content: "\ea60";
}
.i-matcha-tiktok:before {
  content: "\e9ef";
}
.i-matcha-circle-twitter:before {
  content: "\e9f0";
}
.i-matcha-twitter:before {
  content: "\e9f1";
}
.i-matcha-circle-x:before {
  content: "\eb18";
}
.i-matcha-x:before {
  content: "\eb19";
}
.i-matcha-circle-whatsapp:before {
  content: "\e9f2";
}
.i-matcha-whatsapp:before {
  content: "\e9f3";
}
.i-matcha-circle-youtube:before {
  content: "\e9f4";
}
.i-matcha-youtube:before {
  content: "\ea0c";
}
.i-matcha-circle-share:before {
  content: "\ea61";
}
.i-matcha-share1:before {
  content: "\ea22";
}
.i-matcha-circle-deezer:before {
  content: "\eb0b";
}
.i-matcha-deezer:before {
  content: "\eb0c";
}
.i-matcha-circle-beacons:before {
  content: "\eb0d";
}
.i-matcha-beacons:before {
  content: "\eb0e";
}
.i-matcha-circle-threads:before {
  content: "\eb13";
}
.i-matcha-threads:before {
  content: "\eb14";
}
.i-matcha-opened-lock:before {
  content: "\ea23";
}
.i-matcha-flickr:before {
  content: "\ea24";
}
.i-matcha-circle-flickr:before {
  content: "\eae3";
}
.i-matcha-cookie:before {
  content: "\eae4";
}
.i-matcha-cookie-out:before {
  content: "\eae5";
}
.i-matcha-cookie-out-2:before {
  content: "\ea90";
}
.i-matcha-emoji-happy-alt:before {
  content: "\eb1a";
}
.i-matcha-emoji-happy:before {
  content: "\ea25";
}
.i-matcha-emoji-happy-out:before {
  content: "\ea26";
}
.i-matcha-emoji-happy-color .path1:before {
  content: "\ea27";
  color: rgb(252, 234, 43);
}
.i-matcha-emoji-happy-color .path2:before {
  content: "\ea28";
  margin-left: -1em;
  color: rgb(245, 247, 250);
}
.i-matcha-emoji-happy-color .path3:before {
  content: "\ea29";
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.i-matcha-emoji-happy-color .path4:before {
  content: "\ea2a";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-happy-color .path5:before {
  content: "\ea2b";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-happy-color .path6:before {
  content: "\ea2c";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-happy-color .path7:before {
  content: "\ea2d";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-happy-color .path8:before {
  content: "\ea2e";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-prosperous:before {
  content: "\ea2f";
}
.i-matcha-emoji-prosperous-out:before {
  content: "\ea30";
}
.i-matcha-emoji-prosperous-color .path1:before {
  content: "\ea31";
  color: rgb(252, 234, 43);
}
.i-matcha-emoji-prosperous-color .path2:before {
  content: "\ea32";
  margin-left: -1em;
  color: rgb(241, 179, 28);
}
.i-matcha-emoji-prosperous-color .path3:before {
  content: "\ea33";
  margin-left: -1em;
  color: rgb(241, 179, 28);
}
.i-matcha-emoji-prosperous-color .path4:before {
  content: "\ea34";
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.i-matcha-emoji-prosperous-color .path5:before {
  content: "\ea35";
  margin-left: -1em;
  color: rgb(245, 247, 250);
}
.i-matcha-emoji-prosperous-color .path6:before {
  content: "\ea36";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-prosperous-color .path7:before {
  content: "\ea37";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-prosperous-color .path8:before {
  content: "\ea38";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-prosperous-color .path9:before {
  content: "\ea39";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-prosperous-color .path10:before {
  content: "\ea3a";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-prosperous-color .path11:before {
  content: "\ea3b";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-prosperous-color .path12:before {
  content: "\ea3c";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-prosperous-color .path13:before {
  content: "\ea3d";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-blessed:before {
  content: "\ea3e";
}
.i-matcha-emoji-blessed-out:before {
  content: "\ea3f";
}
.i-matcha-emoji-blessed-color .path1:before {
  content: "\ea40";
  color: rgb(252, 234, 43);
}
.i-matcha-emoji-blessed-color .path2:before {
  content: "\ea41";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-blessed-color .path3:before {
  content: "\ea42";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-blessed-color .path4:before {
  content: "\ea43";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-blessed-color .path5:before {
  content: "\ea44";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-blessed-color .path6:before {
  content: "\ea45";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-blessed-color .path7:before {
  content: "\ea46";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-blessed-color .path8:before {
  content: "\ea47";
  margin-left: -1em;
  color: rgb(97, 178, 228);
}
.i-matcha-emoji-sick:before {
  content: "\ea48";
}
.i-matcha-emoji-sick-out:before {
  content: "\ea49";
}
.i-matcha-emoji-sick-color .path1:before {
  content: "\ea4a";
  color: rgb(177, 204, 51);
}
.i-matcha-emoji-sick-color .path2:before {
  content: "\ea4b";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-sick-color .path3:before {
  content: "\ea4c";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-sick-color .path4:before {
  content: "\ea4d";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-sick-color .path5:before {
  content: "\ea4e";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-sick-color .path6:before {
  content: "\ea4f";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-sick-color .path7:before {
  content: "\ea50";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-sick-color .path8:before {
  content: "\ea51";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-sick-color .path9:before {
  content: "\ea52";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-alone:before {
  content: "\ea53";
}
.i-matcha-emoji-alone-out:before {
  content: "\ea54";
}
.i-matcha-emoji-alone-color .path1:before {
  content: "\ea55";
  color: rgb(252, 234, 43);
}
.i-matcha-emoji-alone-color .path2:before {
  content: "\ea56";
  margin-left: -1em;
  color: rgb(146, 211, 245);
}
.i-matcha-emoji-alone-color .path3:before {
  content: "\ea57";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-alone-color .path4:before {
  content: "\ea58";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-alone-color .path5:before {
  content: "\ea59";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-alone-color .path6:before {
  content: "\ea5a";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-alone-color .path7:before {
  content: "\ea5b";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-alone-color .path8:before {
  content: "\ea5c";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-alone-color .path9:before {
  content: "\ea5f";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-sad:before {
  content: "\ea62";
}
.i-matcha-emoji-sad-out:before {
  content: "\ea63";
}
.i-matcha-emoji-sad-color .path1:before {
  content: "\ea65";
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-sad-color .path2:before {
  content: "\ea66";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-sad-color .path3:before {
  content: "\ea67";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-sad-color .path4:before {
  content: "\ea68";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-sad-color .path5:before {
  content: "\ea69";
  margin-left: -1em;
  color: rgb(252, 234, 43);
}
.i-matcha-emoji-sad-color .path6:before {
  content: "\ea6a";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-sad-color .path7:before {
  content: "\ea6b";
  margin-left: -1em;
  color: rgb(245, 247, 250);
}
.i-matcha-emoji-sad-color .path8:before {
  content: "\ea6c";
  margin-left: -1em;
  color: rgb(146, 211, 245);
}
.i-matcha-emoji-sad-color .path9:before {
  content: "\ea6d";
  margin-left: -1em;
  color: rgb(146, 211, 245);
}
.i-matcha-emoji-sad-color .path10:before {
  content: "\ea6e";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-sad-color .path11:before {
  content: "\ea6f";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-sad-color .path12:before {
  content: "\ea70";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-sad-color .path13:before {
  content: "\ea71";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-faithless:before {
  content: "\ea72";
}
.i-matcha-emoji-faithless-out:before {
  content: "\ea73";
}
.i-matcha-emoji-faithless-color .path1:before {
  content: "\ea74";
  color: rgb(252, 234, 43);
}
.i-matcha-emoji-faithless-color .path2:before {
  content: "\ea75";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-faithless-color .path3:before {
  content: "\ea76";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-faithless-color .path4:before {
  content: "\ea77";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-faithless-color .path5:before {
  content: "\ea78";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-faithless-color .path6:before {
  content: "\ea79";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-faithless-color .path7:before {
  content: "\ea7a";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-faithless-color .path8:before {
  content: "\ea7b";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-careful:before {
  content: "\ea7c";
}
.i-matcha-emoji-careful-out:before {
  content: "\ea7d";
}
.i-matcha-emoji-careful-color .path1:before {
  content: "\ea7e";
  color: rgb(252, 234, 43);
}
.i-matcha-emoji-careful-color .path2:before {
  content: "\ea7f";
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.i-matcha-emoji-careful-color .path3:before {
  content: "\ea80";
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.i-matcha-emoji-careful-color .path4:before {
  content: "\ea81";
  margin-left: -1em;
  color: rgb(245, 247, 250);
}
.i-matcha-emoji-careful-color .path5:before {
  content: "\ea82";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-careful-color .path6:before {
  content: "\ea83";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-careful-color .path7:before {
  content: "\ea84";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-careful-color .path8:before {
  content: "\ea85";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-distressed:before {
  content: "\ea86";
}
.i-matcha-emoji-distressed-out:before {
  content: "\ea87";
}
.i-matcha-emoji-distressed-color .path1:before {
  content: "\ea88";
  color: rgb(252, 234, 43);
}
.i-matcha-emoji-distressed-color .path2:before {
  content: "\ea89";
  margin-left: -1em;
  color: rgb(245, 247, 250);
}
.i-matcha-emoji-distressed-color .path3:before {
  content: "\ea8a";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-distressed-color .path4:before {
  content: "\ea8b";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-distressed-color .path5:before {
  content: "\ea8c";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-distressed-color .path6:before {
  content: "\ea8d";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-distressed-color .path7:before {
  content: "\ea8e";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-emoji-distressed-color .path8:before {
  content: "\ea8f";
  margin-left: -1em;
  color: rgb(34, 55, 68);
}
.i-matcha-nu:before {
  content: "\eae9";
}
.i-matcha-nu-color .path1:before {
  content: "\eaea";
  color: rgb(255, 255, 255);
}
.i-matcha-nu-color .path2:before {
  content: "\eaeb";
  margin-left: -1em;
  color: rgb(129, 10, 210);
}
.i-matcha-bradesco:before {
  content: "\eaec";
}
.i-matcha-bradesco-color .path1:before {
  content: "\eaed";
  color: rgb(255, 255, 255);
}
.i-matcha-bradesco-color .path2:before {
  content: "\eaee";
  margin-left: -1em;
  color: rgb(204, 9, 47);
}
.i-matcha-santander:before {
  content: "\eaef";
}
.i-matcha-santander-color .path1:before {
  content: "\eaf0";
  color: rgb(255, 255, 255);
}
.i-matcha-santander-color .path2:before {
  content: "\eaf1";
  margin-left: -1em;
  color: rgb(236, 0, 0);
}
.i-matcha-bb:before {
  content: "\eaf2";
}
.i-matcha-bb-color .path1:before {
  content: "\eaf3";
  color: rgb(51, 52, 142);
}
.i-matcha-bb-color .path2:before {
  content: "\eaf4";
  margin-left: -1em;
  color: rgb(255, 239, 56);
}
.i-matcha-c6-color:before {
  content: "\eaf5";
}
.i-matcha-itau:before {
  content: "\eaf6";
}
.i-matcha-itau-color .path1:before {
  content: "\eaf7";
  color: rgb(254, 110, 2);
}
.i-matcha-itau-color .path2:before {
  content: "\eaf8";
  margin-left: -1em;
  color: rgb(46, 49, 145);
}
.i-matcha-itau-color .path3:before {
  content: "\eaf9";
  margin-left: -1em;
  color: rgb(255, 241, 0);
}
.i-matcha-itau-color .path4:before {
  content: "\eafa";
  margin-left: -1em;
  color: rgb(254, 110, 2);
}
.i-matcha-itau-color .path5:before {
  content: "\eafb";
  margin-left: -1em;
  color: rgb(254, 110, 2);
}
.i-matcha-itau-color .path6:before {
  content: "\eafc";
  margin-left: -1em;
  color: rgb(254, 110, 2);
}
.i-matcha-itau-color .path7:before {
  content: "\eafd";
  margin-left: -1em;
  color: rgb(254, 110, 2);
}
.i-matcha-itau-color .path8:before {
  content: "\eafe";
  margin-left: -1em;
  color: rgb(254, 110, 2);
}
.i-matcha-itau-color .path9:before {
  content: "\eaff";
  margin-left: -1em;
  color: rgb(254, 110, 2);
}
.i-matcha-itau-color .path10:before {
  content: "\eb00";
  margin-left: -1em;
  color: rgb(254, 110, 2);
}
.i-matcha-itau-color .path11:before {
  content: "\eb01";
  margin-left: -1em;
  color: rgb(254, 110, 2);
}
.i-matcha-itau-color .path12:before {
  content: "\eb02";
  margin-left: -1em;
  color: rgb(254, 110, 2);
}
.i-matcha-itau-color .path13:before {
  content: "\eb03";
  margin-left: -1em;
  color: rgb(254, 110, 2);
}
.i-matcha-itau-color .path14:before {
  content: "\eb04";
  margin-left: -1em;
  color: rgb(254, 110, 2);
}
.i-matcha-itau-color .path15:before {
  content: "\eb05";
  margin-left: -1em;
  color: rgb(254, 110, 2);
}
.i-matcha-caixa:before {
  content: "\eb06";
}
.i-matcha-caixa-color .path1:before {
  content: "\eb07";
  color: rgb(255, 255, 255);
}
.i-matcha-caixa-color .path2:before {
  content: "\eb08";
  margin-left: -1em;
  color: rgb(0, 111, 174);
}
.i-matcha-caixa-color .path3:before {
  content: "\eb09";
  margin-left: -1em;
  color: rgb(243, 146, 0);
}
.i-matcha-caixa-color .path4:before {
  content: "\eb0a";
  margin-left: -1em;
  color: rgb(243, 146, 0);
}
