/*
How to use
@import '~matcha-ds/assets/scss/components/matcha-color-pick.scss';
@include matcha-color-pick-theme($theme);
*/
// -----------------------------------------------------------------------------------------------------
// Color Pick [Theme]
// -----------------------------------------------------------------------------------------------------
@mixin matcha-color-pick-theme($theme) {
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .matcha-color-pick {
        .mat-radio-group {
            .mat-radio-button {
                .mat-radio-label {
                    .mat-radio-label-content {
                        width: 100%;
                    }
                }
            }
        }

        .is-selected {
            border-radius: 8px;
            box-shadow: 0px 0px 0px 2px map-get($background, card);
        }
    }
}
