@mixin matcha-scrollbar-theme($theme) {
    $is-dark: map-get($theme, is-dark);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    :not(.is-mobile) {
        ::-webkit-scrollbar {
            width: 16px;
            height: 16px;
            background-color: rgba(0, 0, 0, 0);
        }

        ::-webkit-scrollbar:hover {
            background-color: rgba(0, 0, 0, 0.12);
        }

        ::-webkit-scrollbar-thumb {
            border: 4px solid transparent;
            box-shadow: inset 0 0 0 12px #88888833;
            border-radius: 12px;
        }

        ::-webkit-scrollbar-thumb:active {
            box-shadow: inset 0 0 0 12px #88888844;
            border-radius: 12px;
        }

        ::-webkit-scrollbar-corner {
            background: rgba(0, 0, 0, 0);
        }
    }

}
