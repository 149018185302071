@mixin vendor-ngx-material-timepicker($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    ngx-material-timepicker-container {
        z-index: 10000;

        .timepicker__header,
        .clock-face__number>span.active {
            background-color: map-get($accent, default) !important;
        }

        .timepicker-dial__control {
            color: map-color($foreground, text-inverse, 0.5) !important;
        }

        .clock-face__number>span.active,
        .dial__control,
        .timepicker-dial__item_active,
        .timepicker-dial__time {
            color: map-get($foreground, text-inverse) !important;
        }

        .timepicker-button {
            color: map-get($accent, default) !important;
        }

        .timepicker__body,
        .timepicker__actions {
            background-color: map-get($background, card) !important;
        }

        .clock-face__clock-hand,
        .clock-face__clock-hand_minute {
            background-color: map-get($accent, default) !important;
        }

        .clock-face__clock-hand_minute::before {
            border-color: map-get($accent, default) !important;
            background-color: map-get($background, card) !important;
        }

        .clock-face {
            background-color: map-get($background, background) !important;
        }
    }
}
