// -----------------------------------------------------------------------------------------------------
// @ Z-index helpers
// -----------------------------------------------------------------------------------------------------
@for $zIndex from -1 through 60 {
    .z-index-#{$zIndex} {
        z-index: #{$zIndex} !important;
    }
}

// -----------------------------------------------------------------------------------------------------
// @ Order
// -----------------------------------------------------------------------------------------------------
@each $breakpoint,
$breakpointName in $helper-breakpoints {
    @include media-breakpoint($breakpointName) {
        $infix: if($breakpointName ==null, "", "-#{$breakpoint}");

        /* <integer> values */
        @for $i from -12 through 12 {
            .order#{$infix}-#{$i} {
                order: #{$i};
            }
        }

        /* Global values */
        .order#{$infix}-inherit {
            order: inherit;
        }

        .order#{$infix}-initial {
            order: initial;
        }

        .order#{$infix}-revert {
            order: revert;
        }

        .order#{$infix}-revert-layer {
            order: revert-layer;
        }

        .order#{$infix}-unset {
            order: unset;
        }
    }
}
