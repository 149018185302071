@mixin vendor-angular-material-fixes($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    // -------------------------------------------------------------------------------------------------------------------
    // @ ANGULAR MATERIAL - To attempt to fix some of the issues with the Angular Material components we have to override
    // -------------------------------------------------------------------------------------------------------------------
    --mdc-typography-button-letter-spacing: 0;
    .mat-mdc-unelevated-button,
    .mat-mdc-raised-button,
    .mat-mdc-fab,
    .mat-mdc-mini-fab {
        --mdc-filled-button-container-shape: 8px;
        &:not([disabled="true"]) {
            &.mat-primary {
                color: map-get($primary, "default-contrast");
            }
            &.mat-accent {
                color: map-get($accent, "default-contrast");
            }
            &.mat-warn {
                color: map-get($warn, "default-contrast");
            }
        }
    }
    .mat-mdc-outlined-button {
        --mdc-outlined-button-container-shape: 8px;
        --mdc-outlined-button-outline-color: currentColor;
        --mdc-outlined-button-outline-width: 0;
        box-shadow: 0px 0px 0px 2px currentColor inset;
    }
    .mat-mdc-raised-button {
        --mdc-protected-button-container-height: 36px;
        --mdc-protected-button-container-shape: 8px;
        --mdc-protected-button-container-elevation: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__trailing {
        --mdc-outlined-text-field-outline-width: 2px;
    }
    .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline .mdc-notched-outline__leading,
    .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline .mdc-notched-outline__notch,
    .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline .mdc-notched-outline__trailing {
        --mdc-outlined-text-field-outline-width: 2px;
    }
    .mdc-notched-outline__trailing {
        border-left: none;
        border-right: 2px solid;
    }
    .mdc-notched-outline__leading {
        border-left: 2px solid;
        border-right: none;
        width: 12px;
    }
    .mdc-notched-outline__notch {
        border-left: 2px solid transparent;
    }
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
        border-top: 2px solid;
        border-bottom: 2px solid;
    }
    .mdc-text-field--outlined {
        --mdc-outlined-text-field-container-shape: 8px;
    }
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined
        .mdc-notched-outline--upgraded
        .mdc-floating-label--float-above {
        --mat-mdc-form-field-label-transform: translateY(-29.75px)
            scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
    }

    /* reduce the input height fom 56px to 48px */
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
        top: 22px;
    }
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .mat-mdc-form-field-infix {
        min-height: 48px;
    }

    /* align icons inside of buttons */
    .mat-mdc-icon-button.mat-mdc-button-base {
        padding: 0;
    }
    .mat-mdc-icon-button.mat-mdc-button-base {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
    }
    .mat-stepper-vertical,
    .mat-stepper-horizontal {
        --mat-stepper-container-color: map-get($background, surface);
    }
    .mat-stepper-horizontal {
        &.bullet-stepper-header-labeled {
            .mat-horizontal-stepper-header-container {
                justify-content: end;
                margin-bottom: 0;
                margin-top: 0;
                margin-right: 0;
                padding-right: 24px;
                height: 0;
            }
        }

        &.bullet-stepper-header-labeled,
        &.bullet-stepper-header {
            position: relative;

            .mat-horizontal-stepper-header-container {
                .mat-stepper-horizontal-line {
                    display: none;
                }

                .mat-step-header-ripple {
                    display: none;
                }

                .mat-horizontal-stepper-header {
                    height: initial;
                    overflow: initial;

                    &.mat-step-header {
                        padding: 0;
                        border-radius: 0;
                        flex-direction: column;
                        position: inherit;
                        width: 40px;

                        &.cdk-keyboard-focused,
                        &.cdk-program-focused,
                        &:hover {
                            background-color: transparent;
                        }

                        .mat-step-icon {
                            margin: -32px 0 0 8px;
                            border-radius: 8px;
                            height: 8px;
                            width: 32px;
                            flex-shrink: 0;
                            position: absolute;
                        }
                    }

                    .mat-step-label {
                        position: absolute;
                        display: none;
                        right: 0;
                        margin-top: -52px;
                        margin-right: 24px;
                        font-family: "CircularStd";
                        font-weight: 700;
                        color: map-get($foreground, label);
                    }

                    .mat-step-label.mat-step-label-active {
                        display: none;
                    }

                    .mat-step-label.mat-step-label-active.mat-step-label-selected {
                        display: flex;
                        justify-content: flex-end;
                    }

                    .mat-step-icon-state-edit {
                        margin: 0;
                    }

                    .mat-step-icon-state-edit.mat-step-icon {
                        background-color: map-get($accent, default);

                        .mat-icon {
                            display: none;
                        }
                    }

                    .mat-step-icon-state-number {
                        // background-color: blue;
                    }

                    .mat-step-icon-state-number.mat-step-icon {
                        background-color: map-get($background, disabled);

                        .mat-step-icon-content {
                            display: none;
                        }
                    }

                    .mat-step-icon-state-number.mat-step-icon.mat-step-icon-selected {
                        background-color: map-get($accent, default);
                        order: 1;
                    }
                }
            }

            .mat-horizontal-content-container {
                padding: 0;

                .mat-dialog-content {
                    max-height: calc(100vh - 268px);
                }
            }
        }

        &.hide-stepper-header {
            .mat-horizontal-stepper-header-container {
                display: none !important;
            }

            .mat-horizontal-content-container {
                overflow: hidden;
                padding: 0;
            }
        }

        &.page-layout-stepper {
            .mat-horizontal-stepper-header-container {
                padding-right: 24px;

                .mat-horizontal-stepper-header {
                    .mat-step-label {
                        margin-top: -60px;
                        margin-right: 24px;
                    }
                }
            }
        }
    }
    @media screen and (min-width: 600px) {
        .mat-stepper-horizontal {
            &.bullet-stepper-header-labeled {
                .mat-horizontal-stepper-header-container {
                    padding-right: 72px;
                    .mat-horizontal-stepper-header {
                        .mat-step-label {
                            margin-right: 72px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (min-width: 1024px) {
    }
    @media screen and (min-width: 1440px) {
    }
    @media screen and (min-width: 1920px) {
    }
}
