@mixin matcha-horizontal-tree($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .svg-line {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        line {
            stroke-width: 4px;
            stroke: map-get($foreground, placeholder);
        }
    }

    .child {
        display: flex;
        position: relative;

        .the-line-connect-first,
        .the-line-connect,
        .the-line-connect-last,
        .the-line-connect-first-modal,
        .the-line-connect-second-modal,
        .the-line-connect-last-modal {}

        .the-line-connect-first {
            width: 56px;
            height: 4px;
            margin: 56px -16px 56px -40px;
            display: flex;
            position: absolute;
            z-index: -1;
            background: transparent;
            box-shadow: inset 2px -2px 0px 2px map-get($foreground, placeholder);
        }

        .the-line-connect {
            width: 56px;
            margin: -90px -16px 56px -40px;
            height: 150px;
            display: flex;
            position: absolute;
            z-index: -1;
            background: transparent;
            border-radius: 0 0 0 40px;
            box-shadow: inset 2px -2px 0px 2px map-get($foreground, placeholder);
        }

        .the-line-connect-last {
            width: 56px;
            height: 136px;
            margin: -80px -16px 56px -40px;
            display: flex;
            position: absolute;
            z-index: -1;
            border-radius: 0 0 0 40px;
            background: transparent;
            box-shadow: inset 2px -2px 0px 2px map-get($foreground, placeholder);
        }

        .the-line-connect-first-modal {
            width: 56px;
            height: 4px;
            margin: 56px -16px 56px -40px;
            display: flex;
            position: absolute;
            z-index: -1;
            background: transparent;
            box-shadow: inset 2px -2px 0px 2px map-get($background, lime-alpha);
        }

        .the-line-connect-second-modal {
            width: 56px;
            border-radius: 0 0 0 40px;
            z-index: 1;
            margin: -16px -16px 56px -40px;
            height: 72px;
            display: flex;
            position: absolute;
            background: transparent;
            box-shadow: inset 2px -2px 0px 2px map-get($background, lime-alpha);
        }

        .the-line-connect-last-modal {
            width: 56px;
            height: 150px;
            margin: -90px -16px 56px -40px;
            display: flex;
            position: absolute;
            border-radius: 0 0 0 40px;
            z-index: 1;
            background: transparent;
            box-shadow: inset 2px -2px 0px 2px map-get($background, lime-alpha);
        }

        .the-line-connect-start-modal {
            height: 92px;
            margin: -34px -16px 56px -40px;
            width: 56px;
            border-radius: 0 0 0 40px;
            z-index: 1;
            display: flex;
            position: absolute;
            background: transparent;
            box-shadow: inset 2px -2px 0px 2px map-get($background, lime-alpha);
        }

        .the-line-connect {
            height: 92px !important;
            margin: -34px -16px 56px -40px !important;
        }

        .the-line-connect-middle {
            height: 92px;
            margin: -34px -16px 56px -40px;
            width: 56px;
            border-radius: 0 0 0 40px;
            z-index: 0;
            display: flex;
            position: absolute;
            background: transparent;
            box-shadow: inset 2px -2px 0px 2px #223744;
        }
    }

    .tree-node {
        min-width: 264px;
        max-width: 264px;
        width: 264px;
        height: 80px;

        &-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;

            &-title {
                font-size: 12px;
                line-height: 12px;
                display: flex;
                margin: 0 0 4px 0;
                align-items: center;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                font-family: "CircularStd";
                font-weight: 700;

                &-bullet {
                    height: 16px;
                    width: 8px;
                    margin: 0px 8px 0 0;
                    display: inline-block;
                    position: relative;
                    border-radius: 10px;
                }
            }
        }

        &-content {
            display: flex;
            justify-content: space-between;

            &-profile {
                display: flex;
                align-items: center;
                width: 100%;

                &-avatar {
                    border-radius: 50px;
                    min-width: 24px;
                    min-height: 24px;
                    width: 24px;
                    height: 24px;
                    max-width: 24px;
                    max-height: 24px;
                }

                &-name {
                    width: calc(100% - 48px);
                    display: flex;
                    line-height: 20px;
                    font-size: 16px;
                    margin: 0 0 0 8px;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-word;
                    white-space: nowrap;
                    -webkit-box-orient: vertical;
                }
            }

            &-btn-add-node {
                position: absolute;
                margin: 24px 0px 0 236px;
                width: 24px;
                height: 24px;
                background: map-get($background, card);
                box-shadow: inset 0px 0px 0px 3px map-get($accent, default);

                &-i {
                    line-height: 12px;
                    color: map-get($accent, default);
                    border-radius: 50px;
                }
            }
        }
    }

    .tree-first-node {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 264px;
        max-width: 264px;
        width: 264px;
        height: 80px;
        margin: 16px 16px -10px 16px;
        border-radius: 8px;

        &-begin {
            font-size: 16px;
            font-family: "CircularStd";
            font-weight: 700;
            line-height: 16px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
            white-space: initial;
            -webkit-box-orient: vertical;
            text-transform: capitalize;
            text-overflow: ellipsis;

            &-btn-add-node {
                width: 24px;
                height: 24px;
                display: block;
                margin: 0 auto;
                position: relative;
                background: map-get($background, card);
                box-shadow: inset 0px 0px 0px 3px map-get($accent, default);

                &-i {
                    line-height: 12px;
                    color: map-get($accent, default);
                    border-radius: 50px;
                }
            }
        }
    }

    .matcha-horizontal-tree {
        overflow: auto;
        // cursor: grab;
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Old versions of Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently */
    }
}
