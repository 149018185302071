// @import url("https://unpkg.com/matcha-icons@latest/matcha-icons.css");
@mixin vendor-calendar-theme($theme) {
    $is-dark-theme: map-get($theme, is-dark);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    /* Colors
  --------------------------------------------------------------------------------------------------*/
    .full-calendar {
        background-color: map-get($background, background);
    }

    .fc-day-top {
        &.active {
            .fc-day-number {
                background-color: map-get($accent, default);
                color: map-get($foreground, text-inverse);
            }
        }
    }

    .fc-day-number {
        &:hover {
            background-color: map-get($primary, default);
            color: map-get($foreground, text-inverse);
        }
    }

    .fc-button-primary {
        background-color: map-get($background, background);

        .fc-icon {
            color: map-get($foreground, placeholder);
        }

        .fc-day-header {
            span {
                color: map-get($foreground, placeholder);
            }
        }

        &:hover {
            background-color: map-get($foreground, placeholder);

            .fc-icon {
                color: map-get($foreground, text);
            }
        }
    }

    .fc-unthemed th,
    .fc-unthemed td,
    .fc-unthemed thead,
    .fc-unthemed tbody,
    .fc-unthemed .fc-divider,
    .fc-unthemed .fc-row,
    .fc-unthemed .fc-content,
    .fc-unthemed .fc-popover,
    .fc-unthemed .fc-list-view,
    .fc-unthemed .fc-list-heading td {
        border-color: map-get($foreground, placeholder);
    }

    .fc-unthemed .fc-popover {
        background-color: map-get($foreground, card);
    }

    .fc-unthemed .fc-divider,
    .fc-unthemed .fc-popover .fc-header,
    .fc-unthemed .fc-list-heading td {
        background: map-get($foreground, placeholder);
    }

    .fc-unthemed td.fc-today {
        background-color: map-get($background, background);
    }

    .fc-unthemed .fc-disabled-day {
        background: map-get($foreground, placeholder);
        opacity: 0.3;
    }

    .fc-toolbar h2 {
        color: map-get($foreground, label);
    }

    .fc-day-header {
        span {
            color: map-get($foreground, placeholder);
        }
    }

    .fc-unthemed td.fc-today {
        background-color: transparent;
    }

    .fc-day-top.fc-today {
        .fc-day-number {
            background-color: map-get($foreground, label);
            color: map-get($foreground, text-inverse);
        }
    }

    .fc-button-primary:not(:disabled):active,
    .fc-button-primary:not(:disabled).fc-button-active {
        background-color: transparent;
        border-color: transparent;
    }

    .fc-button-primary {
        color: map-get($background, background);
        background-color: map-get($background, card);
        border-color: #2c3e50;
    }

    .fc-button-primary:hover {
        color: map-get($background, background);
        background-color: map-get($background, card-alpha);
        border-color: map-get($background, disabled);
    }

    .fc-button-primary:focus {
        -webkit-box-shadow: 0 0 0 2px rgba(76, 91, 106, 0.5);
        box-shadow: 0 0 0 2px rgba(76, 91, 106, 0.5);
    }

    .fc-button-primary:disabled {
        color: map-get($background, background);
        background-color: map-get($background, disabled);
        border-color: #2c3e50;
    }

    .fc-button-primary:not(:disabled):active,
    .fc-button-primary:not(:disabled).fc-button-active {
        color: map-get($background, background);
        background-color: map-get($background, disabled);
        border-color: #151e27;
    }

    .fc-button-primary:not(:disabled):active:focus,
    .fc-button-primary:not(:disabled).fc-button-active:focus {
        -webkit-box-shadow: 0 0 0 2px rgba(76, 91, 106, 0.5);
        box-shadow: 0 0 0 2px rgba(76, 91, 106, 0.5);
    }

    /* COMPONENT STYLE
  --------------------------------------------------------------------------------------------------*/
    .full-calendar {
        display: block;
        border-radius: 8px;
        padding: 16px;
    }

    .fc-day-top {
        &.active {
            .fc-day-number {
                border-radius: 8px;
            }
        }
    }

    .fc-day-number {
        display: flex;
        padding: 0;
        margin: 0 auto;
        font-size: 16px;
        line-height: 42px;
        width: 42px;
        height: 42px;
        justify-content: center;
        text-align: center;
        cursor: pointer;

        &:hover {
            border-radius: 999px;
        }
    }

    .fc-button-primary {
        border: none;
        padding: 4px;
        border-radius: 100px;
        line-height: 20px;
        height: 40px;
        width: 40px;

        .fc-icon {
            font-size: 32px;
        }

        .fc-day-header {
            span {
                display: flex;
                width: 32px;
                float: none;
                padding: 0;
                line-height: 34px;
                height: 32px;
                margin: 0 auto;
                align-content: center;
                justify-content: center;
            }
        }
    }

    .fc-toolbar h2 {
        font-size: 16px;
        text-transform: uppercase;
    }

    .fc-day-header {
        span {
            font-size: 16px;
            text-transform: capitalize;
        }
    }

    .fc-day-top.fc-today {
        .fc-day-number {
            border-radius: 999px;
        }
    }

    .fc-button-primary:focus {
        box-shadow: none;
    }

    .fc-button-primary:not(:disabled):active:focus,
    .fc-button-primary:not(:disabled).fc-button-active:focus {
        box-shadow: none;
    }

    .fc-unthemed th,
    .fc-unthemed td,
    .fc-unthemed thead,
    .fc-unthemed tbody,
    .fc-unthemed .fc-divider,
    .fc-unthemed .fc-row,
    .fc-unthemed .fc-content,
    .fc-unthemed .fc-popover,
    .fc-unthemed .fc-list-view,
    .fc-unthemed .fc-list-heading td {
        border: 0 !important;
        text-align: center;
        padding-top: 6px;
    }

    .fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
        float: none;
        margin: 8px auto;
    }

    .fc-day-grid-container {
        height: auto !important;
    }

    //full calendar core
    //=======================================

    /*!
FullCalendar Core Package v4.2.0
Docs & License: https://fullcalendar.io/
(c) 2019 Adam Shaw
*/
    .fc {
        direction: ltr;
        text-align: left;
    }

    .fc-rtl {
        text-align: right;
    }

    body .fc {
        /* extra precedence to overcome jqui */
        font-size: 16px;
    }

    /* Colors
--------------------------------------------------------------------------------------------------*/
    .fc-highlight {
        /* when user is selecting cells */
        background: #bce8f1;
        opacity: 0.3;
    }

    .fc-bgevent {
        /* default look for background events */
        background: #8fdf82;
        opacity: 0.3;
    }

    .fc-nonbusiness {
        /* default look for non-business-hours areas */
        /* will inherit .fc-bgevent's styles */
        background: #d7d7d7;
    }

    .fc-popover {
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    }

    .fc-row .fc-highlight-skeleton td,
    .fc-row .fc-bgevent-skeleton td {
        border-color: transparent;
    }

    /* Popover
--------------------------------------------------------------------------------------------------*/
    .fc-popover {
        position: absolute;
    }

    .fc-popover .fc-header {
        /* TODO: be more consistent with fc-head/fc-body */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 2px 4px;
    }

    .fc-rtl .fc-popover .fc-header {
        flex-direction: row-reverse;
    }

    .fc-popover .fc-header .fc-title {
        margin: 0 2px;
    }

    .fc-popover .fc-header .fc-close {
        cursor: pointer;
        opacity: 0.65;
        font-size: 1.1em;
    }

    /* Misc Reusable Components
--------------------------------------------------------------------------------------------------*/
    .fc-divider {
        border-style: solid;
        border-width: 1px;
    }

    hr.fc-divider {
        height: 0;
        margin: 0;
        padding: 0 0 2px;
        /* height is unreliable across browsers, so use padding */
        border-width: 1px 0;
    }

    .fc-bg,
    .fc-bgevent-skeleton,
    .fc-highlight-skeleton,
    .fc-mirror-skeleton {
        /* these element should always cling to top-left/right corners */
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    .fc-bg {
        bottom: 0;
        /* strech bg to bottom edge */
    }

    .fc-bg table {
        height: 100%;
        /* strech bg to bottom edge */
    }

    /* Tables
--------------------------------------------------------------------------------------------------*/
    .fc table {
        width: 100%;
        box-sizing: border-box;
        /* fix scrollbar issue in firefox */
        table-layout: fixed;
        border-collapse: collapse;
        border-spacing: 0;
        font-size: 1em;
        /* normalize cross-browser */
    }

    .fc th {
        text-align: center;
    }

    .fc th,
    .fc td {
        border-style: solid;
        border-width: 1px;
        padding: 0;
        vertical-align: top;
    }

    .fc td.fc-today {
        border-style: double;
        /* overcome neighboring borders */
    }

    /* Internal Nav Links
--------------------------------------------------------------------------------------------------*/
    a[data-goto] {
        cursor: pointer;
    }

    a[data-goto]:hover {
        text-decoration: underline;
    }

    /* Fake Table Rows
--------------------------------------------------------------------------------------------------*/
    .fc .fc-row {
        /* extra precedence to overcome themes forcing a 1px border */
        /* no visible border by default. but make available if need be (scrollbar width compensation) */
        border-style: solid;
        border-width: 0;
    }

    .fc-row table {
        /* don't put left/right border on anything within a fake row.
   the outer tbody will worry about this */
        border-left: 0 hidden transparent;
        border-right: 0 hidden transparent;
        /* no bottom borders on rows */
        border-bottom: 0 hidden transparent;
    }

    .fc-row:first-child table {
        border-top: 0 hidden transparent;
        /* no top border on first row */
    }

    /* Day Row (used within the header and the DayGrid)
--------------------------------------------------------------------------------------------------*/
    .fc-row {
        position: relative;
    }

    .fc-row .fc-bg {
        z-index: 1;
    }

    /* highlighting cells & background event skeleton */
    .fc-row .fc-bgevent-skeleton,
    .fc-row .fc-highlight-skeleton {
        bottom: 0;
        /* stretch skeleton to bottom of row */
    }

    .fc-row .fc-bgevent-skeleton table,
    .fc-row .fc-highlight-skeleton table {
        height: 100%;
        /* stretch skeleton to bottom of row */
    }

    .fc-row .fc-bgevent-skeleton {
        z-index: 2;
    }

    .fc-row .fc-highlight-skeleton {
        z-index: 3;
    }

    /*
row content (which contains day/week numbers and events) as well as "mirror" (which contains
temporary rendered events).
*/
    .fc-row .fc-content-skeleton {
        position: relative;
        z-index: 4;
        padding-bottom: 2px;
        /* matches the space above the events */
    }

    .fc-row .fc-mirror-skeleton {
        z-index: 5;
    }

    .fc .fc-row .fc-content-skeleton table,
    .fc .fc-row .fc-content-skeleton td,
    .fc .fc-row .fc-mirror-skeleton td {
        /* see-through to the background below */
        /* extra precedence to prevent theme-provided backgrounds */
        background: none;
        /* in case <td>s are globally styled */
        border-color: transparent;
    }

    .fc-row .fc-content-skeleton td,
    .fc-row .fc-mirror-skeleton td {
        /* don't put a border between events and/or the day number */
        border-bottom: 0;
    }

    .fc-row .fc-content-skeleton tbody td,
    .fc-row .fc-mirror-skeleton tbody td {
        /* don't put a border between event cells */
        border-top: 0;
    }

    /* Scrolling Container
--------------------------------------------------------------------------------------------------*/
    .fc-scroller {
        -webkit-overflow-scrolling: touch;
    }

    /* TODO: move to timegrid/daygrid */
    .fc-scroller>.fc-day-grid,
    .fc-scroller>.fc-time-grid {
        position: relative;
        /* re-scope all positions */
        width: 100%;
        /* hack to force re-sizing this inner element when scrollbars appear/disappear */
    }

    /* Global Event Styles
--------------------------------------------------------------------------------------------------*/
    .fc-event {
        position: relative;
        /* for resize handle and other inner positioning */
        display: block;
        /* make the <a> tag block */
        font-size: 0.85em;
        line-height: 1.4;
        border-radius: 3px;
        border: 1px solid #3788d8;
    }

    .fc-event,
    .fc-event-dot {
        background-color: #3788d8;
        /* default BACKGROUND color */
    }

    .fc-event,
    .fc-event:hover {
        color: #fff;
        /* default TEXT color */
        text-decoration: none;
        /* if <a> has an href */
    }

    .fc-event[href],
    .fc-event.fc-draggable {
        cursor: pointer;
        /* give events with links and draggable events a hand mouse pointer */
    }

    .fc-not-allowed,
    .fc-not-allowed .fc-event {
        /* to override an event's custom cursor */
        cursor: not-allowed;
    }

    .fc-event .fc-content {
        position: relative;
        z-index: 2;
    }

    /* resizer (cursor AND touch devices) */
    .fc-event .fc-resizer {
        position: absolute;
        z-index: 4;
    }

    /* resizer (touch devices) */
    .fc-event .fc-resizer {
        display: none;
    }

    .fc-event.fc-allow-mouse-resize .fc-resizer,
    .fc-event.fc-selected .fc-resizer {
        /* only show when hovering or selected (with touch) */
        display: block;
    }

    /* hit area */
    .fc-event.fc-selected .fc-resizer:before {
        /* 40x40 touch area */
        content: "";
        position: absolute;
        z-index: 9999;
        /* user of this util can scope within a lower z-index */
        top: 50%;
        left: 50%;
        width: 40px;
        height: 40px;
        margin-left: -20px;
        margin-top: -20px;
    }

    /* Event Selection (only for touch devices)
--------------------------------------------------------------------------------------------------*/
    .fc-event.fc-selected {
        z-index: 9999 !important;
        /* overcomes inline z-index */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }

    .fc-event.fc-selected:after {
        content: "";
        position: absolute;
        z-index: 1;
        /* same z-index as fc-bg, behind text */
        /* overcome the borders */
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        /* darkening effect */
        background: #000;
        opacity: 0.25;
    }

    /* Event Dragging
--------------------------------------------------------------------------------------------------*/
    .fc-event.fc-dragging.fc-selected {
        box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
    }

    .fc-event.fc-dragging:not(.fc-selected) {
        opacity: 0.75;
    }

    /* Horizontal Events
--------------------------------------------------------------------------------------------------*/
    /* bigger touch area when selected */
    .fc-h-event.fc-selected:before {
        content: "";
        position: absolute;
        z-index: 3;
        /* below resizers */
        top: -10px;
        bottom: -10px;
        left: 0;
        right: 0;
    }

    /* events that are continuing to/from another week. kill rounded corners and butt up against edge */
    .fc-ltr .fc-h-event.fc-not-start,
    .fc-rtl .fc-h-event.fc-not-end {
        margin-left: 0;
        border-left-width: 0;
        padding-left: 1px;
        /* replace the border with padding */
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .fc-ltr .fc-h-event.fc-not-end,
    .fc-rtl .fc-h-event.fc-not-start {
        margin-right: 0;
        border-right-width: 0;
        padding-right: 1px;
        /* replace the border with padding */
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    /* resizer (cursor AND touch devices) */
    /* left resizer  */
    .fc-ltr .fc-h-event .fc-start-resizer,
    .fc-rtl .fc-h-event .fc-end-resizer {
        cursor: w-resize;
        left: -1px;
        /* overcome border */
    }

    /* right resizer */
    .fc-ltr .fc-h-event .fc-end-resizer,
    .fc-rtl .fc-h-event .fc-start-resizer {
        cursor: e-resize;
        right: -1px;
        /* overcome border */
    }

    /* resizer (mouse devices) */
    .fc-h-event.fc-allow-mouse-resize .fc-resizer {
        width: 7px;
        top: -1px;
        /* overcome top border */
        bottom: -1px;
        /* overcome bottom border */
    }

    /* resizer (touch devices) */
    .fc-h-event.fc-selected .fc-resizer {
        /* 8x8 little dot */
        border-radius: 4px;
        border-width: 1px;
        width: 6px;
        height: 6px;
        border-style: solid;
        border-color: inherit;
        background: #fff;
        /* vertically center */
        top: 50%;
        margin-top: -4px;
    }

    /* left resizer  */
    .fc-ltr .fc-h-event.fc-selected .fc-start-resizer,
    .fc-rtl .fc-h-event.fc-selected .fc-end-resizer {
        margin-left: -4px;
        /* centers the 8x8 dot on the left edge */
    }

    /* right resizer */
    .fc-ltr .fc-h-event.fc-selected .fc-end-resizer,
    .fc-rtl .fc-h-event.fc-selected .fc-start-resizer {
        margin-right: -4px;
        /* centers the 8x8 dot on the right edge */
    }

    /* DayGrid events
----------------------------------------------------------------------------------------------------
We use the full "fc-day-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
    .fc-day-grid-event {
        margin: 1px 2px 0;
        /* spacing between events and edges */
        padding: 0 1px;
    }

    tr:first-child>td>.fc-day-grid-event {
        margin-top: 2px;
        /* a little bit more space before the first event */
    }

    .fc-mirror-skeleton tr:first-child>td>.fc-day-grid-event {
        margin-top: 0;
        /* except for mirror skeleton */
    }

    .fc-day-grid-event .fc-content {
        /* force events to be one-line tall */
        white-space: nowrap;
        overflow: hidden;
    }

    .fc-day-grid-event .fc-time {
        font-weight: bold;
    }

    /* resizer (cursor devices) */
    /* left resizer  */
    .fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer,
    .fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer {
        margin-left: -2px;
        /* to the day cell's edge */
    }

    /* right resizer */
    .fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer,
    .fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer {
        margin-right: -2px;
        /* to the day cell's edge */
    }

    /* Event Limiting
--------------------------------------------------------------------------------------------------*/
    /* "more" link that represents hidden events */
    a.fc-more {
        margin: 1px 3px;
        font-size: 0.85em;
        cursor: pointer;
        text-decoration: none;
    }

    a.fc-more:hover {
        text-decoration: underline;
    }

    .fc-limited {
        /* rows and cells that are hidden because of a "more" link */
        display: none;
    }

    /* popover that appears when "more" link is clicked */
    .fc-day-grid .fc-row {
        z-index: 1;
        /* make the "more" popover one higher than this */
    }

    .fc-more-popover {
        z-index: 2;
        width: 220px;
    }

    .fc-more-popover .fc-event-container {
        padding: 10px;
    }

    /* Now Indicator
--------------------------------------------------------------------------------------------------*/
    .fc-now-indicator {
        position: absolute;
        border: 0 solid red;
    }

    /* Utilities
--------------------------------------------------------------------------------------------------*/
    .fc-unselectable {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .fc-icon {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: "MatchaIcons" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .fc-icon-chevron-left:before {
        content: "\e9b1";
    }

    .fc-icon-chevron-right:before {
        content: "\e9e0";
    }

    .fc-icon-chevrons-left:before {
        content: "\e902";
    }

    .fc-icon-chevrons-right:before {
        content: "\e903";
    }

    .fc-icon-minus-square:before {
        content: "\e904";
    }

    .fc-icon-plus-square:before {
        content: "\e905";
    }

    .fc-icon-x:before {
        content: "\e906";
    }

    .fc-icon {
        display: inline-block;
        width: 1em;
        height: 1em;
        text-align: center;
    }

    /* Buttons
--------------------------------------------------------------------------------------------------
Lots taken from Flatly (MIT): https://bootswatch.com/4/flatly/bootstrap.css
*/
    /* reset */
    .fc-button {
        border-radius: 0;
        overflow: visible;
        text-transform: none;
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    .fc-button:focus {
        outline: 1px dotted;
        outline: 5px auto -webkit-focus-ring-color;
    }

    .fc-button {
        -webkit-appearance: button;
    }

    .fc-button:not(:disabled) {
        cursor: pointer;
    }

    .fc-button::-moz-focus-inner {
        padding: 0;
        border-style: none;
    }

    /* theme */
    .fc-button {
        display: flex;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 0px solid transparent;
        padding: 8px;
        font-size: 24px;
        line-height: 24px;
        border-radius: 0.25em;
    }

    .fc-button:hover {
        color: #212529;
        text-decoration: none;
    }

    .fc-button:focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 2px rgba(44, 62, 80, 0.25);
        box-shadow: 0 0 0 2px rgba(44, 62, 80, 0.25);
    }

    .fc-button:disabled {
        opacity: 0.65;
    }

    /* icons within buttons */
    .fc-button .fc-icon {
        vertical-align: middle;
        font-size: 24px;
    }

    /* Buttons Groups
--------------------------------------------------------------------------------------------------*/
    .fc-button-group {
        position: relative;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        vertical-align: middle;
    }

    .fc-button-group>.fc-button {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    }

    .fc-button-group>.fc-button:hover {
        z-index: 1;
    }

    .fc-button-group>.fc-button:focus,
    .fc-button-group>.fc-button:active,
    .fc-button-group>.fc-button.fc-button-active {
        z-index: 1;
    }

    .fc-button-group>.fc-button:not(:first-child) {
        margin-left: -1px;
    }

    .fc-button-group>.fc-button:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .fc-button-group>.fc-button:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    /* Popover
--------------------------------------------------------------------------------------------------*/
    .fc-unthemed .fc-popover {
        border-width: 1px;
        border-style: solid;
    }

    /* List View
--------------------------------------------------------------------------------------------------*/
    .fc-unthemed .fc-list-item:hover td {
        background-color: #f5f5f5;
    }

    /* Toolbar
--------------------------------------------------------------------------------------------------*/
    .fc-toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .fc-toolbar.fc-header-toolbar {
        margin-bottom: 16px;
    }

    .fc-toolbar.fc-footer-toolbar {
        margin-top: 24px;
    }

    /* inner content */
    .fc-toolbar>*> :not(:first-child) {
        margin-left: 8px;
    }

    .fc-toolbar h2 {
        font-size: 16px;
        margin: 0;
    }

    /* View Structure
--------------------------------------------------------------------------------------------------*/
    .fc-view-container {
        position: relative;
    }

    /* undo twitter bootstrap's box-sizing rules. normalizes positioning techniques */
    /* don't do this for the toolbar because we'll want bootstrap to style those buttons as some pt */
    .fc-view-container *,
    .fc-view-container *:before,
    .fc-view-container *:after {
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
    }

    .fc-view,
    .fc-view>table {
        /* so dragged elements can be above the view's main element */
        position: relative;
        z-index: 1;
    }

    @media print {
        .fc {
            max-width: 100% !important;
        }

        /* Global Event Restyling
--------------------------------------------------------------------------------------------------*/
        .fc-event {
            background: #fff !important;
            color: #000 !important;
            page-break-inside: avoid;
        }

        .fc-event .fc-resizer {
            display: none;
        }

        /* Table & Day-Row Restyling
--------------------------------------------------------------------------------------------------*/
        .fc th,
        .fc td,
        .fc hr,
        .fc thead,
        .fc tbody,
        .fc-row {
            border-color: #ccc !important;
            background: #fff !important;
        }

        /* kill the overlaid, absolutely-positioned components */
        /* common... */
        .fc-bg,
        .fc-bgevent-skeleton,
        .fc-highlight-skeleton,
        .fc-mirror-skeleton,
        .fc-bgevent-container,
        .fc-business-container,
        .fc-highlight-container,
        .fc-mirror-container {
            display: none;
        }

        /* don't force a min-height on rows (for DayGrid) */
        .fc tbody .fc-row {
            height: auto !important;
            /* undo height that JS set in distributeHeight */
            min-height: 0 !important;
            /* undo the min-height from each view's specific stylesheet */
        }

        .fc tbody .fc-row .fc-content-skeleton {
            position: static;
            /* undo .fc-rigid */
            padding-bottom: 0 !important;
            /* use a more border-friendly method for this... */
        }

        .fc tbody .fc-row .fc-content-skeleton tbody tr:last-child td {
            /* only works in newer browsers */
            padding-bottom: 1em;
            /* ...gives space within the skeleton. also ensures min height in a way */
        }

        .fc tbody .fc-row .fc-content-skeleton table {
            /* provides a min-height for the row, but only effective for IE, which exaggerates this value,
     making it look more like 3em. for other browers, it will already be this tall */
            height: 1em;
        }

        /* Undo month-view event limiting. Display all events and hide the "more" links
--------------------------------------------------------------------------------------------------*/
        .fc-more-cell,
        .fc-more {
            display: none !important;
        }

        .fc tr.fc-limited {
            display: table-row !important;
        }

        .fc td.fc-limited {
            display: table-cell !important;
        }

        .fc-popover {
            display: none;
            /* never display the "more.." popover in print mode */
        }

        /* TimeGrid Restyling
--------------------------------------------------------------------------------------------------*/
        /* undo the min-height 100% trick used to fill the container's height */
        .fc-time-grid {
            min-height: 0 !important;
        }

        /* don't display the side axis at all ("all-day" and time cells) */
        .fc-timeGrid-view .fc-axis {
            display: none;
        }

        /* don't display the horizontal lines */
        .fc-slats,
        .fc-time-grid hr {
            /* this hr is used when height is underused and needs to be filled */
            display: none !important;
            /* important overrides inline declaration */
        }

        /* let the container that holds the events be naturally positioned and create real height */
        .fc-time-grid .fc-content-skeleton {
            position: static;
        }

        /* in case there are no events, we still want some height */
        .fc-time-grid .fc-content-skeleton table {
            height: 4em;
        }

        /* kill the horizontal spacing made by the event container. event margins will be done below */
        .fc-time-grid .fc-event-container {
            margin: 0 !important;
        }

        /* TimeGrid *Event* Restyling
--------------------------------------------------------------------------------------------------*/
        /* naturally position events, vertically stacking them */
        .fc-time-grid .fc-event {
            position: static !important;
            margin: 3px 2px !important;
        }

        /* for events that continue to a future day, give the bottom border back */
        .fc-time-grid .fc-event.fc-not-end {
            border-bottom-width: 1px !important;
        }

        /* indicate the event continues via "..." text */
        .fc-time-grid .fc-event.fc-not-end:after {
            content: "...";
        }

        /* for events that are continuations from previous days, give the top border back */
        .fc-time-grid .fc-event.fc-not-start {
            border-top-width: 1px !important;
        }

        /* indicate the event is a continuation via "..." text */
        .fc-time-grid .fc-event.fc-not-start:before {
            content: "...";
        }

        /* time */
        /* undo a previous declaration and let the time text span to a second line */
        .fc-time-grid .fc-event .fc-time {
            white-space: normal !important;
        }

        /* hide the the time that is normally displayed... */
        .fc-time-grid .fc-event .fc-time span {
            display: none;
        }

        /* ...replace it with a more verbose version (includes AM/PM) stored in an html attribute */
        .fc-time-grid .fc-event .fc-time:after {
            content: attr(data-full);
        }

        /* Vertical Scroller & Containers
--------------------------------------------------------------------------------------------------*/
        /* kill the scrollbars and allow natural height */
        .fc-scroller,
        .fc-day-grid-container,
        .fc-time-grid-container {
            /* */
            overflow: visible !important;
            height: auto !important;
        }

        /* kill the horizontal border/padding used to compensate for scrollbars */
        .fc-row {
            border: 0 !important;
            margin: 0 !important;
        }

        /* Button Controls
--------------------------------------------------------------------------------------------------*/
        .fc-button-group,
        .fc button {
            display: none;
            /* don't display any button-related controls */
        }
    }

    //============================================
    //daygrid

    /*!
FullCalendar Day Grid Plugin v4.2.0
Docs & License: https://fullcalendar.io/
(c) 2019 Adam Shaw
*/
    /* DayGridView
--------------------------------------------------------------------------------------------------*/
    /* day row structure */
    .fc-dayGridWeek-view .fc-content-skeleton,
    .fc-dayGridDay-view .fc-content-skeleton {
        /* there may be week numbers in these views, so no padding-top */
        padding-bottom: 1em;
        /* ensure a space at bottom of cell for user selecting/clicking */
    }

    .fc-dayGrid-view .fc-body .fc-row {
        min-height: 4em;
        /* ensure that all rows are at least this tall */
    }

    /* a "rigid" row will take up a constant amount of height because content-skeleton is absolute */
    .fc-row.fc-rigid {
        overflow: hidden;
    }

    .fc-row.fc-rigid .fc-content-skeleton {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    /* week and day number styling */
    .fc-day-top.fc-other-month {
        opacity: 0.3;
    }

    .fc-dayGrid-view .fc-week-number,
    .fc-dayGrid-view .fc-day-number {
        padding: 2px;
    }

    .fc-dayGrid-view th.fc-week-number,
    .fc-dayGrid-view th.fc-day-number {
        padding: 0 2px;
        /* column headers can't have as much v space */
    }

    .fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
        border-radius: 8px;
    }

    .fc-rtl .fc-dayGrid-view .fc-day-top .fc-day-number {
        border-radius: 8px;
    }

    .fc-ltr .fc-dayGrid-view .fc-day-top .fc-week-number {
        border-radius: 8px;
    }

    .fc-rtl .fc-dayGrid-view .fc-day-top .fc-week-number {
        border-radius: 8px;
    }

    .fc-dayGrid-view .fc-day-top .fc-week-number {
        min-width: 1.5em;
        text-align: center;
        background-color: #f2f2f2;
        color: #808080;
    }

    /* when week/day number have own column */
    .fc-dayGrid-view td.fc-week-number {
        text-align: center;
    }

    .fc-dayGrid-view td.fc-week-number>* {
        /* work around the way we do column resizing and ensure a minimum width */
        display: inline-block;
        min-width: 1.25em;
    }

    //=========================================
    // Angular Calendar
    .cal-month-view .cal-header {
        text-align: center;
        font-weight: bolder;
    }

    .cal-month-view .cal-header .cal-cell {
        padding: 5px 0;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
    }

    .cal-month-view .cal-days {
        border: 1px solid;
        border-bottom: 0;
    }

    .cal-month-view .cal-cell-top {
        min-height: 78px;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }

    .cal-month-view .cal-cell-row {
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
    }

    .cal-month-view .cal-cell {
        float: left;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
    }

    .cal-month-view .cal-day-cell {
        min-height: 100px;
    }

    @media all and (-ms-high-contrast: none) {
        .cal-month-view .cal-day-cell {
            display: block;
        }
    }

    .cal-month-view .cal-day-cell:not(:last-child) {
        border-right: 1px solid;
    }

    .cal-month-view .cal-days .cal-cell-row {
        border-bottom: 1px solid;
    }

    .cal-month-view .cal-day-badge {
        margin-top: 18px;
        margin-left: 10px;
        display: inline-block;
        min-width: 10px;
        padding: 3px 7px;
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        border-radius: 10px;
    }

    .cal-month-view .cal-day-number {
        font-size: 1.2em;
        font-weight: 400;
        opacity: 0.5;
        margin-top: 15px;
        margin-right: 15px;
        float: right;
        margin-bottom: 10px;
    }

    .cal-month-view .cal-events {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        margin: 3px;
        line-height: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .cal-month-view .cal-event {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        margin: 2px;
    }

    .cal-month-view .cal-day-cell.cal-in-month.cal-has-events {
        cursor: pointer;
    }

    .cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
        opacity: 0.1;
        cursor: default;
    }

    .cal-month-view .cal-day-cell.cal-today .cal-day-number {
        font-size: 1.9em;
    }

    .cal-month-view .cal-open-day-events {
        padding: 15px;
    }

    .cal-month-view .cal-open-day-events .cal-event {
        position: relative;
        top: 2px;
    }

    .cal-month-view .cal-out-month .cal-day-badge,
    .cal-month-view .cal-out-month .cal-event {
        opacity: 0.3;
    }

    .cal-month-view .cal-draggable {
        cursor: move;
    }

    .cal-month-view .cal-drag-active * {
        pointer-events: none;
    }

    .cal-month-view .cal-event-title {
        cursor: pointer;
    }

    .cal-month-view .cal-event-title:hover {
        text-decoration: underline;
    }

    .cal-month-view {
        background-color: #fff;
    }

    .cal-month-view .cal-cell-row:hover {
        background-color: #fafafa;
    }

    .cal-month-view .cal-cell-row .cal-cell:hover,
    .cal-month-view .cal-cell.cal-has-events.cal-open {
        background-color: #ededed;
    }

    .cal-month-view .cal-days {
        border-color: #e1e1e1;
    }

    .cal-month-view .cal-day-cell:not(:last-child) {
        border-right-color: #e1e1e1;
    }

    .cal-month-view .cal-days .cal-cell-row {
        border-bottom-color: #e1e1e1;
    }

    .cal-month-view .cal-day-badge {
        background-color: #b94a48;
        color: #fff;
    }

    .cal-month-view .cal-event {
        background-color: #1e90ff;
        border-color: #d1e8ff;
        color: #fff;
    }

    .cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
        color: #8b0000;
    }

    .cal-month-view .cal-day-cell.cal-today {
        background-color: #e8fde7;
    }

    .cal-month-view .cal-day-cell.cal-drag-over {
        background-color: #e0e0e0 !important;
    }

    .cal-month-view .cal-open-day-events {
        color: #fff;
        background-color: #555;
        -webkit-box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
        box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
    }

    .cal-week-view {
        /* stylelint-disable-next-line selector-type-no-unknown */
    }

    .cal-week-view * {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .cal-week-view .cal-day-headers {
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        padding-left: 70px;
        border: 1px solid;
    }

    .cal-week-view .cal-day-headers .cal-header {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-align: center;
        padding: 5px;
    }

    .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
        border-right: 1px solid;
    }

    .cal-week-view .cal-day-headers .cal-header:first-child {
        border-left: 1px solid;
    }

    .cal-week-view .cal-day-headers span {
        font-weight: 400;
        opacity: 0.5;
    }

    .cal-week-view .cal-day-column {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        border-left: solid 1px;
    }

    .cal-week-view .cal-event {
        font-size: 12px;
        border: 1px solid;
    }

    .cal-week-view .cal-time-label-column {
        width: 70px;
        height: 100%;
    }

    .cal-week-view .cal-all-day-events {
        border: solid 1px;
        border-top: 0;
        border-bottom-width: 3px;
        padding-top: 3px;
        position: relative;
    }

    .cal-week-view .cal-all-day-events .cal-day-columns {
        height: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        position: absolute;
        top: 0;
        z-index: 0;
    }

    .cal-week-view .cal-all-day-events .cal-events-row {
        position: relative;
        height: 31px;
        margin-left: 70px;
    }

    .cal-week-view .cal-all-day-events .cal-event-container {
        display: inline-block;
        position: absolute;
    }

    .cal-week-view .cal-all-day-events .cal-event-container.resize-active {
        z-index: 1;
        pointer-events: none;
    }

    .cal-week-view .cal-all-day-events .cal-event {
        padding: 0 5px;
        margin-left: 2px;
        margin-right: 2px;
        height: 28px;
        line-height: 28px;
    }

    .cal-week-view .cal-all-day-events .cal-starts-within-week .cal-event {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .cal-week-view .cal-all-day-events .cal-ends-within-week .cal-event {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .cal-week-view .cal-all-day-events .cal-time-label-column {
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        font-size: 14px;
    }

    .cal-week-view .cal-all-day-events .cal-resize-handle {
        width: 6px;
        height: 100%;
        cursor: col-resize;
        position: absolute;
        top: 0;
    }

    .cal-week-view .cal-all-day-events .cal-resize-handle.cal-resize-handle-after-end {
        right: 0;
    }

    .cal-week-view .cal-event,
    .cal-week-view .cal-header {
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .cal-week-view .cal-drag-active {
        pointer-events: none;
        z-index: 1;
    }

    .cal-week-view .cal-drag-active * {
        pointer-events: none;
    }

    .cal-week-view .cal-time-events {
        position: relative;
        border: solid 1px;
        border-top: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
    }

    .cal-week-view .cal-time-events .cal-day-columns {
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }

    .cal-week-view .cal-time-events .cal-day-column {
        position: relative;
    }

    .cal-week-view .cal-time-events .cal-event-container {
        position: absolute;
        z-index: 1;
    }

    .cal-week-view .cal-time-events .cal-event {
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        margin: 1px;
        padding: 0 5px;
        line-height: 25px;
    }

    .cal-week-view .cal-time-events .cal-resize-handle {
        width: 100%;
        height: 4px;
        cursor: row-resize;
        position: absolute;
    }

    .cal-week-view .cal-time-events .cal-resize-handle.cal-resize-handle-after-end {
        bottom: 0;
    }

    .cal-week-view .cal-hour-segment {
        position: relative;
    }

    .cal-week-view .cal-hour-segment::after {
        content: "\00a0";
    }

    .cal-week-view .cal-event-container:not(.cal-draggable) {
        cursor: pointer;
    }

    .cal-week-view .cal-draggable {
        cursor: move;
    }

    .cal-week-view mwl-calendar-week-view-hour-segment,
    .cal-week-view .cal-hour-segment {
        display: block;
    }

    .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
    .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
        border-bottom: thin dashed;
    }

    .cal-week-view .cal-time {
        font-weight: bold;
        padding-top: 5px;
        width: 70px;
        text-align: center;
    }

    .cal-week-view .cal-hour-segment.cal-after-hour-start .cal-time {
        display: none;
    }

    .cal-week-view .cal-starts-within-day .cal-event {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .cal-week-view .cal-ends-within-day .cal-event {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .cal-week-view {
        background-color: #fff;
    }

    .cal-week-view .cal-day-headers {
        border-color: #e1e1e1;
    }

    .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
        border-right-color: #e1e1e1;
    }

    .cal-week-view .cal-day-headers .cal-header:first-child {
        border-left-color: #e1e1e1;
    }

    .cal-week-view .cal-day-headers .cal-header:hover,
    .cal-week-view .cal-day-headers .cal-drag-over {
        background-color: #ededed;
    }

    .cal-week-view .cal-day-column {
        border-left-color: #e1e1e1;
    }

    .cal-week-view .cal-event {
        background-color: #d1e8ff;
        border-color: #1e90ff;
        color: #1e90ff;
    }

    .cal-week-view .cal-all-day-events {
        border-color: #e1e1e1;
    }

    .cal-week-view .cal-header.cal-today {
        background-color: #e8fde7;
    }

    .cal-week-view .cal-header.cal-weekend span {
        color: #8b0000;
    }

    .cal-week-view .cal-time-events {
        border-color: #e1e1e1;
    }

    .cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
        background-color: #ededed;
    }

    .cal-week-view .cal-hour-odd {
        background-color: #fafafa;
    }

    .cal-week-view .cal-drag-over .cal-hour-segment {
        background-color: #ededed;
    }

    .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
    .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
        border-bottom-color: #e1e1e1;
    }

    .cal-day-view {
        /* stylelint-disable-next-line selector-type-no-unknown */
    }

    .cal-day-view * {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .cal-day-view .cal-hour-rows {
        width: 100%;
        border: solid 1px;
        overflow-x: auto;
        position: relative;
    }

    .cal-day-view mwl-calendar-day-view-hour-segment,
    .cal-day-view .cal-hour-segment {
        display: block;
    }

    .cal-day-view .cal-hour-segment::after {
        content: "\00a0";
    }

    .cal-day-view .cal-hour:not(:last-child) .cal-hour-segment,
    .cal-day-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
        border-bottom: thin dashed;
    }

    .cal-day-view .cal-time {
        font-weight: bold;
        width: 70px;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .cal-day-view .cal-hour-segment.cal-after-hour-start .cal-time {
        display: none;
    }

    .cal-day-view .cal-drag-active .cal-hour-segment {
        pointer-events: none;
    }

    .cal-day-view .cal-event-container {
        position: absolute;
        cursor: pointer;
    }

    .cal-day-view .cal-event-container.resize-active {
        z-index: 1;
        pointer-events: none;
    }

    .cal-day-view .cal-event {
        padding: 5px;
        font-size: 12px;
        border: 1px solid;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 100%;
    }

    .cal-day-view .cal-all-day-events>* {
        cursor: pointer;
    }

    .cal-day-view .cal-draggable {
        cursor: move;
    }

    .cal-day-view .cal-starts-within-day .cal-event {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .cal-day-view .cal-ends-within-day .cal-event {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .cal-day-view .cal-drag-active {
        z-index: 1;
    }

    .cal-day-view .cal-drag-active * {
        pointer-events: none;
    }

    .cal-day-view .cal-resize-handle {
        width: 100%;
        height: 4px;
        cursor: row-resize;
        position: absolute;
    }

    .cal-day-view .cal-resize-handle.cal-resize-handle-after-end {
        bottom: 0;
    }

    .cal-day-view {
        background-color: #fff;
    }

    .cal-day-view .cal-hour-rows {
        border-color: #e1e1e1;
    }

    .cal-day-view .cal-hour:nth-child(odd) {
        background-color: #fafafa;
    }

    .cal-day-view .cal-hour:not(:last-child) .cal-hour-segment,
    .cal-day-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
        border-bottom-color: #e1e1e1;
    }

    .cal-day-view .cal-hour-segment:hover,
    .cal-day-view .cal-drag-over .cal-hour-segment {
        background-color: #ededed;
    }

    .cal-day-view .cal-event {
        background-color: #d1e8ff;
        border-color: #1e90ff;
        color: #1e90ff;
    }

    .cal-tooltip {
        position: absolute;
        z-index: 1070;
        display: block;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;
        line-break: auto;
        line-height: 1.5;
        text-align: start;
        text-decoration: none;
        text-shadow: none;
        text-transform: none;
        white-space: normal;
        word-break: normal;
        word-spacing: normal;
        font-size: 11px;
        word-wrap: break-word;
        opacity: 0.9;
    }

    .cal-tooltip.cal-tooltip-top {
        padding: 5px 0;
        margin-top: -3px;
    }

    .cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
        bottom: 0;
        left: 50%;
        margin-left: -5px;
        border-width: 5px 5px 0;
    }

    .cal-tooltip.cal-tooltip-right {
        padding: 0 5px;
        margin-left: 3px;
    }

    .cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
        top: 50%;
        left: 0;
        margin-top: -5px;
        border-width: 5px 5px 5px 0;
    }

    .cal-tooltip.cal-tooltip-bottom {
        padding: 5px 0;
        margin-top: 3px;
    }

    .cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
        top: 0;
        left: 50%;
        margin-left: -5px;
        border-width: 0 5px 5px;
    }

    .cal-tooltip.cal-tooltip-left {
        padding: 0 5px;
        margin-left: -3px;
    }

    .cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
        top: 50%;
        right: 0;
        margin-top: -5px;
        border-width: 5px 0 5px 5px;
    }

    .cal-tooltip-inner {
        max-width: 200px;
        padding: 3px 8px;
        text-align: center;
        border-radius: 0.25rem;
    }

    .cal-tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }

    .cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
        border-top-color: #000;
    }

    .cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
        border-right-color: #000;
    }

    .cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
        border-bottom-color: #000;
    }

    .cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
        border-left-color: #000;
    }

    .cal-tooltip-inner {
        color: #fff;
        background-color: #000;
    }

    // Angular Calendar
    .cal-month-view {
        background-color: transparent;

        .cal-header {
            text-align: center;
            font-family: "CircularStd";
            font-weight: 700;

            .cal-cell {
                padding: 5px 0;
                overflow: hidden;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
                display: block;
                white-space: nowrap;
            }
        }

        .cal-days {
            border: 1px solid;
            border-bottom: 0;
        }

        .cal-cell-top {
            min-height: 78px;
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
        }

        .cal-cell-row {
            display: -webkit-box;
            display: -ms-flexbox;
            -js-display: flex;
            display: flex;
        }

        .cal-cell {
            float: left;
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            display: -webkit-box;
            display: -ms-flexbox;
            -js-display: flex;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: stretch;
            -ms-flex-align: stretch;
            align-items: stretch;
        }

        .cal-day-cell {
            min-height: 100px;
        }

        .cal-day-cell:not(:last-child) {
            border-right: 1px solid;
        }

        .cal-days .cal-cell-row {
            border-bottom: 1px solid;
        }

        .cal-day-badge {
            margin-top: 18px;
            margin-left: 10px;
            display: inline-block;
            min-width: 10px;
            padding: 3px 7px;
            font-size: 12px;
            font-family: "CircularStd";
            font-weight: 700;
            line-height: 1;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            border-radius: 10px;
        }

        .cal-day-number {
            color: map-get($foreground, foreground);
            opacity: 1;
            font-size: 1.2em;
            margin-top: 15px;
            margin-right: 15px;
            float: right;
            margin-bottom: 10px;
        }

        .cal-events {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;
            margin: 3px;
            line-height: 10px;
            display: -webkit-box;
            display: -ms-flexbox;
            -js-display: flex;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }

        .cal-event {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            margin: 2px;
        }

        .cal-day-cell.cal-in-month.cal-has-events {
            cursor: pointer;
        }

        .cal-day-cell.cal-out-month .cal-day-number {
            opacity: 0.1;
            cursor: default;
        }

        .cal-day-cell.cal-today .cal-day-number {
            font-size: 24px;
        }

        .cal-open-day-events {
            padding: 16px;
        }

        .cal-open-day-events .cal-event {
            position: relative;
            top: 2px;
        }

        .cal-out-month .cal-day-badge,
        .cal-out-month .cal-event {
            opacity: 0.3;
        }

        .cal-draggable {
            cursor: move;
        }

        .cal-drag-active * {
            pointer-events: none;
        }

        .cal-event-title {
            cursor: pointer;
        }

        .cal-event-title:hover {
            text-decoration: underline;
        }

        .cal-cell-row:hover {
            background-color: map-get($background, disabled);
        }

        .cal-cell-row .cal-cell:hover,
        .cal-cell.cal-has-events.cal-open {
            background-color: map-get($background, disabled);
        }

        .cal-days {
            border-color: map-get($background, disabled);
        }

        .cal-day-cell:not(:last-child) {
            border-right-color: map-get($background, disabled);
        }

        .cal-days .cal-cell-row {
            border-bottom-color: map-get($background, disabled);
        }

        .cal-day-badge {
            background-color: map-get($foreground, graphic-red);
            color: map-get($foreground, text-inverse);
        }

        .cal-event {
            background-color: map-get($foreground, graphic-blue);
            border-color: map-get($background, disabled);
            color: #fff;
        }

        .cal-day-cell.cal-weekend .cal-day-number {
            color: map-get($foreground, graphic-red);
        }

        .cal-day-cell.cal-today {
            background-color: map-get($background, disabled);
        }

        .cal-day-cell.cal-drag-over {
            background-color: #e0e0e0 !important;
        }

        .cal-open-day-events {
            color: map-get($foreground, text);
            background-color: map-get($background, disabled);
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }

    @media all and (-ms-high-contrast: none) {
        .cal-month-view .cal-day-cell {
            display: block;
        }
    }

    .cal-week-view {
        /* stylelint-disable-next-line selector-type-no-unknown */
    }

    .cal-week-view * {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .cal-week-view .cal-day-headers {
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        padding-left: 70px;
        border: 1px solid;
    }

    .cal-week-view .cal-day-headers .cal-header {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-align: center;
        padding: 5px;
    }

    .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
        border-right: 1px solid;
    }

    .cal-week-view .cal-day-headers .cal-header:first-child {
        border-left: 1px solid;
    }

    .cal-week-view .cal-day-headers span {
        opacity: 0.5;
    }

    .cal-week-view .cal-day-column {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        border-left: solid 1px;
    }

    .cal-week-view .cal-event {
        font-size: 12px;
        border: 1px solid;
    }

    .cal-week-view .cal-time-label-column {
        width: 70px;
        height: 100%;
    }

    .cal-week-view .cal-all-day-events {
        border: solid 1px;
        border-top: 0;
        border-bottom-width: 3px;
        padding-top: 3px;
        position: relative;
    }

    .cal-week-view .cal-all-day-events .cal-day-columns {
        height: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        position: absolute;
        top: 0;
        z-index: 0;
    }

    .cal-week-view .cal-all-day-events .cal-events-row {
        position: relative;
        height: 31px;
        margin-left: 70px;
    }

    .cal-week-view .cal-all-day-events .cal-event-container {
        display: inline-block;
        position: absolute;
    }

    .cal-week-view .cal-all-day-events .cal-event-container.resize-active {
        z-index: 1;
        pointer-events: none;
    }

    .cal-week-view .cal-all-day-events .cal-event {
        padding: 0 5px;
        margin-left: 2px;
        margin-right: 2px;
        height: 28px;
        line-height: 28px;
    }

    .cal-week-view .cal-all-day-events .cal-starts-within-week .cal-event {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .cal-week-view .cal-all-day-events .cal-ends-within-week .cal-event {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .cal-week-view .cal-all-day-events .cal-time-label-column {
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        font-size: 14px;
    }

    .cal-week-view .cal-all-day-events .cal-resize-handle {
        width: 6px;
        height: 100%;
        cursor: col-resize;
        position: absolute;
        top: 0;
    }

    .cal-week-view .cal-all-day-events .cal-resize-handle.cal-resize-handle-after-end {
        right: 0;
    }

    .cal-week-view .cal-event,
    .cal-week-view .cal-header {
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .cal-week-view .cal-drag-active {
        pointer-events: none;
        z-index: 1;
    }

    .cal-week-view .cal-drag-active * {
        pointer-events: none;
    }

    .cal-week-view .cal-time-events {
        position: relative;
        border: solid 1px;
        border-top: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
    }

    .cal-week-view .cal-time-events .cal-day-columns {
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }

    .cal-week-view .cal-time-events .cal-day-column {
        position: relative;
    }

    .cal-week-view .cal-time-events .cal-event-container {
        position: absolute;
        z-index: 1;
    }

    .cal-week-view .cal-time-events .cal-event {
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        margin: 1px;
        padding: 0 5px;
        line-height: 25px;
    }

    .cal-week-view .cal-time-events .cal-resize-handle {
        width: 100%;
        height: 4px;
        cursor: row-resize;
        position: absolute;
    }

    .cal-week-view .cal-time-events .cal-resize-handle.cal-resize-handle-after-end {
        bottom: 0;
    }

    .cal-week-view .cal-hour-segment {
        position: relative;
    }

    .cal-week-view .cal-hour-segment::after {
        content: "\00a0";
    }

    .cal-week-view .cal-event-container:not(.cal-draggable) {
        cursor: pointer;
    }

    .cal-week-view .cal-draggable {
        cursor: move;
    }

    .cal-week-view mwl-calendar-week-view-hour-segment,
    .cal-week-view .cal-hour-segment {
        display: block;
    }

    .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
    .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
        border-bottom: thin dashed;
    }

    .cal-week-view .cal-time {
        font-family: "CircularStd";
        font-weight: 700;
        padding-top: 5px;
        width: 70px;
        text-align: center;
    }

    .cal-week-view .cal-hour-segment.cal-after-hour-start .cal-time {
        display: none;
    }

    .cal-week-view .cal-starts-within-day .cal-event {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .cal-week-view .cal-ends-within-day .cal-event {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .cal-week-view {
        background-color: #fff;
    }

    .cal-week-view .cal-day-headers {
        border-color: #e1e1e1;
    }

    .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
        border-right-color: #e1e1e1;
    }

    .cal-week-view .cal-day-headers .cal-header:first-child {
        border-left-color: #e1e1e1;
    }

    .cal-week-view .cal-day-headers .cal-header:hover,
    .cal-week-view .cal-day-headers .cal-drag-over {
        background-color: #ededed;
    }

    .cal-week-view .cal-day-column {
        border-left-color: #e1e1e1;
    }

    .cal-week-view .cal-event {
        background-color: map-get($foreground, graphic-blue);
        border-color: map-get($foreground, graphic-blue);
        color: map-get($foreground, graphic-blue);
    }

    .cal-week-view .cal-all-day-events {
        border-color: #e1e1e1;
    }

    .cal-week-view .cal-header.cal-today {
        background-color: #e8fde7;
    }

    .cal-week-view .cal-header.cal-weekend span {
        color: #8b0000;
    }

    .cal-week-view .cal-time-events {
        border-color: #e1e1e1;
    }

    .cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
        background-color: #ededed;
    }

    .cal-week-view .cal-hour-odd {
        background-color: #fafafa;
    }

    .cal-week-view .cal-drag-over .cal-hour-segment {
        background-color: #ededed;
    }

    .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
    .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
        border-bottom-color: #e1e1e1;
    }

    .cal-day-view {
        /* stylelint-disable-next-line selector-type-no-unknown */
    }

    .cal-day-view * {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .cal-day-view .cal-hour-rows {
        width: 100%;
        border: solid 1px;
        overflow-x: auto;
        position: relative;
    }

    .cal-day-view mwl-calendar-day-view-hour-segment,
    .cal-day-view .cal-hour-segment {
        display: block;
    }

    .cal-day-view .cal-hour-segment::after {
        content: "\00a0";
    }

    .cal-day-view .cal-hour:not(:last-child) .cal-hour-segment,
    .cal-day-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
        border-bottom: thin dashed;
    }

    .cal-day-view .cal-time {
        font-family: "CircularStd";
        font-weight: 700;
        width: 70px;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .cal-day-view .cal-hour-segment.cal-after-hour-start .cal-time {
        display: none;
    }

    .cal-day-view .cal-drag-active .cal-hour-segment {
        pointer-events: none;
    }

    .cal-day-view .cal-event-container {
        position: absolute;
        cursor: pointer;
    }

    .cal-day-view .cal-event-container.resize-active {
        z-index: 1;
        pointer-events: none;
    }

    .cal-day-view .cal-event {
        padding: 5px;
        font-size: 12px;
        border: 1px solid;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 100%;
    }

    .cal-day-view .cal-all-day-events>* {
        cursor: pointer;
    }

    .cal-day-view .cal-draggable {
        cursor: move;
    }

    .cal-day-view .cal-starts-within-day .cal-event {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .cal-day-view .cal-ends-within-day .cal-event {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .cal-day-view .cal-drag-active {
        z-index: 1;
    }

    .cal-day-view .cal-drag-active * {
        pointer-events: none;
    }

    .cal-day-view .cal-resize-handle {
        width: 100%;
        height: 4px;
        cursor: row-resize;
        position: absolute;
    }

    .cal-day-view .cal-resize-handle.cal-resize-handle-after-end {
        bottom: 0;
    }

    .cal-day-view {
        background-color: #fff;
    }

    .cal-day-view .cal-hour-rows {
        border-color: #e1e1e1;
    }

    .cal-day-view .cal-hour:nth-child(odd) {
        background-color: #fafafa;
    }

    .cal-day-view .cal-hour:not(:last-child) .cal-hour-segment,
    .cal-day-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
        border-bottom-color: #e1e1e1;
    }

    .cal-day-view .cal-hour-segment:hover,
    .cal-day-view .cal-drag-over .cal-hour-segment {
        background-color: #ededed;
    }

    .cal-day-view .cal-event {
        background-color: map-get($foreground, graphic-blue);
        border-color: map-get($foreground, graphic-blue);
        color: map-get($foreground, graphic-blue);
    }

    .cal-tooltip {
        position: absolute;
        z-index: 1070;
        display: block;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;
        line-break: auto;
        line-height: 1.5;
        text-align: start;
        text-decoration: none;
        text-shadow: none;
        text-transform: none;
        white-space: normal;
        word-break: normal;
        word-spacing: normal;
        font-size: 11px;
        word-wrap: break-word;
        opacity: 0.9;
    }

    .cal-tooltip.cal-tooltip-top {
        padding: 5px 0;
        margin-top: -3px;
    }

    .cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
        bottom: 0;
        left: 50%;
        margin-left: -5px;
        border-width: 5px 5px 0;
    }

    .cal-tooltip.cal-tooltip-right {
        padding: 0 5px;
        margin-left: 3px;
    }

    .cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
        top: 50%;
        left: 0;
        margin-top: -5px;
        border-width: 5px 5px 5px 0;
    }

    .cal-tooltip.cal-tooltip-bottom {
        padding: 5px 0;
        margin-top: 3px;
    }

    .cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
        top: 0;
        left: 50%;
        margin-left: -5px;
        border-width: 0 5px 5px;
    }

    .cal-tooltip.cal-tooltip-left {
        padding: 0 5px;
        margin-left: -3px;
    }

    .cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
        top: 50%;
        right: 0;
        margin-top: -5px;
        border-width: 5px 0 5px 5px;
    }

    .cal-tooltip-inner {
        max-width: 200px;
        padding: 3px 8px;
        text-align: center;
        border-radius: 0.25rem;
    }

    .cal-tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }

    .cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
        border-top-color: #000;
    }

    .cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
        border-right-color: #000;
    }

    .cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
        border-bottom-color: #000;
    }

    .cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
        border-left-color: #000;
    }

    .cal-tooltip-inner {
        color: #fff;
        background-color: #000;
    }
}
