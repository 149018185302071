// -----------------------------------------------------------------------------------------------------
// @ Position helpers
// -----------------------------------------------------------------------------------------------------
$position-class-names: relative, absolute, static, sticky, fixed, inherit, initial, revert, revert-layer, unset;
@mixin helper-position($helper-breakpoints){
    @each $breakpoint, $materialBreakpoint in $helper-breakpoints {
        @include media-breakpoint($materialBreakpoint) {
            $infix: if($materialBreakpoint ==null, "", "-#{$breakpoint}");
            @each $position in $position-class-names {
                .position#{$infix}-#{$position} {
                    position: #{$position} !important;
                }
            }
        }
    }
}
@include helper-position($helper-breakpoints);


// -----------------------------------------------------------------------------------------------------
// @ Absolute position alignment helpers
// -----------------------------------------------------------------------------------------------------
$align-class-names: top, right, bottom, left;
@mixin helper-alignment-position($helper-breakpoints){
    @each $breakpoint, $materialBreakpoint in $helper-breakpoints {
        @include media-breakpoint($materialBreakpoint) {
            $infix: if($materialBreakpoint ==null, "", "-#{$breakpoint}");
            @each $align in $align-class-names {
                .align#{$infix}-#{$align} {
                    #{$align}: 0 !important;
                }
            }
        }
    }
}
@include helper-alignment-position($helper-breakpoints);

// -----------------------------------------------------------------------------------------------------
// @ Size helpers
// -----------------------------------------------------------------------------------------------------
.w-auto {
    width: auto !important;
    min-width: auto !important;
    max-width: auto !important
}

.h-auto {
    height: auto !important;
    min-height: auto !important;
    max-height: auto !important
}

.w-264 {
    width: 264px !important;
    min-width: 264px !important;
    max-width: 264px !important
}

.w-300 {
    width: 300px !important;
    min-width: 300px !important;
    max-width: 300px !important
}

.w-400 {
    width: 400px !important;
    min-width: 400px !important;
    max-width: 400px !important
}

.w-500 {
    width: 500px !important;
    min-width: 500px !important;
    max-width: 500px !important
}

.w-600 {
    width: 600px !important;
    min-width: 600px !important;
    max-width: 600px !important
}

.h-264 {
    height: 264px !important;
    min-height: 264px !important;
    max-height: 264px !important
}

.h-300 {
    height: 300px !important;
    min-height: 300px !important;
    max-height: 300px !important
}

.h-400 {
    height: 400px !important;
    min-height: 400px !important;
    max-height: 400px !important
}

.h-500 {
    height: 500px !important;
    min-height: 500px !important;
    max-height: 500px !important
}

.h-600 {
    height: 600px !important;
    min-height: 600px !important;
    max-height: 600px !important
}

@media only screen and (min-width:600px) {
    .w-sm-auto {
        width: auto !important;
        min-width: auto !important;
        max-width: auto !important
    }

    .h-sm-auto {
        height: auto !important;
        min-height: auto !important;
        max-height: auto !important
    }

    .w-sm-264 {
        width: 264px !important;
        min-width: 264px !important;
        max-width: 264px !important
    }

    .w-sm-300 {
        width: 300px !important;
        min-width: 300px !important;
        max-width: 300px !important
    }

    .w-sm-400 {
        width: 400px !important;
        min-width: 400px !important;
        max-width: 400px !important
    }

    .w-sm-500 {
        width: 500px !important;
        min-width: 500px !important;
        max-width: 500px !important
    }

    .w-sm-600 {
        width: 600px !important;
        min-width: 600px !important;
        max-width: 600px !important
    }

    .h-sm-264 {
        height: 264px !important;
        min-height: 264px !important;
        max-height: 264px !important
    }

    .h-sm-300 {
        height: 300px !important;
        min-height: 300px !important;
        max-height: 300px !important
    }

    .h-sm-400 {
        height: 400px !important;
        min-height: 400px !important;
        max-height: 400px !important
    }

    .h-sm-500 {
        height: 500px !important;
        min-height: 500px !important;
        max-height: 500px !important
    }

    .h-sm-600 {
        height: 600px !important;
        min-height: 600px !important;
        max-height: 600px !important
    }
}

@media only screen and (min-width:1024px) {
    .w-md-auto {
        width: auto !important;
        min-width: auto !important;
        max-width: auto !important
    }

    .h-md-auto {
        height: auto !important;
        min-height: auto !important;
        max-height: auto !important
    }

    .w-md-264 {
        width: 264px !important;
        min-width: 264px !important;
        max-width: 264px !important
    }

    .w-md-300 {
        width: 300px !important;
        min-width: 300px !important;
        max-width: 300px !important
    }

    .w-md-400 {
        width: 400px !important;
        min-width: 400px !important;
        max-width: 400px !important
    }

    .w-md-500 {
        width: 500px !important;
        min-width: 500px !important;
        max-width: 500px !important
    }

    .w-md-600 {
        width: 600px !important;
        min-width: 600px !important;
        max-width: 600px !important
    }

    .h-md-264 {
        height: 264px !important;
        min-height: 264px !important;
        max-height: 264px !important
    }

    .h-md-300 {
        height: 300px !important;
        min-height: 300px !important;
        max-height: 300px !important
    }

    .h-md-400 {
        height: 400px !important;
        min-height: 400px !important;
        max-height: 400px !important
    }

    .h-md-500 {
        height: 500px !important;
        min-height: 500px !important;
        max-height: 500px !important
    }

    .h-md-600 {
        height: 600px !important;
        min-height: 600px !important;
        max-height: 600px !important
    }
}

@media only screen and (min-width:1440px) {
    .w-lg-auto {
        width: auto !important;
        min-width: auto !important;
        max-width: auto !important
    }

    .h-lg-auto {
        height: auto !important;
        min-height: auto !important;
        max-height: auto !important
    }

    .w-lg-264 {
        width: 264px !important;
        min-width: 264px !important;
        max-width: 264px !important
    }

    .w-lg-300 {
        width: 300px !important;
        min-width: 300px !important;
        max-width: 300px !important
    }

    .w-lg-400 {
        width: 400px !important;
        min-width: 400px !important;
        max-width: 400px !important
    }

    .w-lg-500 {
        width: 500px !important;
        min-width: 500px !important;
        max-width: 500px !important
    }

    .w-lg-600 {
        width: 600px !important;
        min-width: 600px !important;
        max-width: 600px !important
    }

    .h-lg-264 {
        height: 264px !important;
        min-height: 264px !important;
        max-height: 264px !important
    }

    .h-lg-300 {
        height: 300px !important;
        min-height: 300px !important;
        max-height: 300px !important
    }

    .h-lg-400 {
        height: 400px !important;
        min-height: 400px !important;
        max-height: 400px !important
    }

    .h-lg-500 {
        height: 500px !important;
        min-height: 500px !important;
        max-height: 500px !important
    }

    .h-lg-600 {
        height: 600px !important;
        min-height: 600px !important;
        max-height: 600px !important
    }
}

@media only screen and (min-width:1920px) {
    .w-xl-auto {
        width: auto !important;
        min-width: auto !important;
        max-width: auto !important
    }

    .h-xl-auto {
        height: auto !important;
        min-height: auto !important;
        max-height: auto !important
    }

    .w-xl-264 {
        width: 264px !important;
        min-width: 264px !important;
        max-width: 264px !important
    }

    .w-xl-300 {
        width: 300px !important;
        min-width: 300px !important;
        max-width: 300px !important
    }

    .w-xl-400 {
        width: 400px !important;
        min-width: 400px !important;
        max-width: 400px !important
    }

    .w-xl-500 {
        width: 500px !important;
        min-width: 500px !important;
        max-width: 500px !important
    }

    .w-xl-600 {
        width: 600px !important;
        min-width: 600px !important;
        max-width: 600px !important
    }

    .h-xl-264 {
        height: 264px !important;
        min-height: 264px !important;
        max-height: 264px !important
    }

    .h-xl-300 {
        height: 300px !important;
        min-height: 300px !important;
        max-height: 300px !important
    }

    .h-xl-400 {
        height: 400px !important;
        min-height: 400px !important;
        max-height: 400px !important
    }

    .h-xl-500 {
        height: 500px !important;
        min-height: 500px !important;
        max-height: 500px !important
    }

    .h-xl-600 {
        height: 600px !important;
        min-height: 600px !important;
        max-height: 600px !important
    }
}


// -----------------------------------------------------------------------------------------------------
// @ Space helpers
// -----------------------------------------------------------------------------------------------------
.m-auto {
    margin: auto !important
}

.mt-auto {
    margin-top: auto !important
}

.mr-auto {
    margin-right: auto !important
}

.mb-auto {
    margin-bottom: auto !important
}

.ml-auto {
    margin-left: auto !important
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
}

@media screen and (min-width:600px) {
    .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-sm-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }
}

@media screen and (min-width:1024px) {
    .m-md-auto {
        margin: auto !important
    }

    .mt-md-auto {
        margin-top: auto !important
    }

    .mr-md-auto {
        margin-right: auto !important
    }

    .mb-md-auto {
        margin-bottom: auto !important
    }

    .ml-md-auto {
        margin-left: auto !important
    }

    .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-md-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }
}

@media screen and (min-width:1440px) {}

@media screen and (min-width:1920px) {
    .m-xl-auto {
        margin: auto !important
    }

    .mt-xl-auto {
        margin-top: auto !important
    }

    .mr-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-auto {
        margin-bottom: auto !important
    }

    .ml-xl-auto {
        margin-left: auto !important
    }

    .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-xl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }
}



// -----------------------------------------------------------------------------------------------------
// @ Text Helpers
// -----------------------------------------------------------------------------------------------------
// Text format helpers text-md-center, text-md-left, text-md-right, text-md-justify, text-md-nowrap, text-md-truncate, text-md-lowercase, text-md-uppercase, text-md-capitalize

@mixin helper-text-format($helper-breakpoints){
    @each $breakpoint, $materialBreakpoint in $helper-breakpoints {
        @include media-breakpoint($materialBreakpoint) {
            $infix: if($materialBreakpoint ==null, "", "-#{$breakpoint}");
            
            .text#{$infix}-decoration-strike,
            .text#{$infix}-strike,
            .text#{$infix}-line-through {
                text-decoration: line-through !important
            }
            
            .text#{$infix}-decoration-none,
            .text#{$infix}-none {
                text-decoration: none !important
            }
            
            .text#{$infix}-super {
                vertical-align: super !important
            }
            
            .text#{$infix}-sub {
                vertical-align: sub !important
            }
            
            .text#{$infix}-capitalize {
                text-transform: capitalize !important
            }
            
            .text#{$infix}-lowercase {
                text-transform: lowercase !important
            }
            
            .text#{$infix}-uppercase {
                text-transform: uppercase !important
            }
            
            .text#{$infix}-nowrap {
                white-space: nowrap
            }
            
            .text#{$infix}-left {
                text-align: left !important
            }
            
            .text#{$infix}-center {
                text-align: center !important
            }
            
            .text#{$infix}-right {
                text-align: right !important
            }
            
            .text#{$infix}-boxed {
                border-radius: 2px;
                padding: 4px 8px;
                margin: 0 8px;
                font-size: 11px;
                font-weight: 600;
                white-space: nowrap
            }
            
            .text#{$infix}-truncate,
            .text#{$infix}-ellipsis {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap
            }
        }
    }
}
@include helper-text-format($helper-breakpoints);

// -----------------------------------------------------------------------------------------------------
// @ Border helpers
// -----------------------------------------------------------------------------------------------------
// none:0 thin:2 thick:4 thicker:8

@mixin helper-border-size($helper-breakpoints){
    @each $breakpoint, $materialBreakpoint in $helper-breakpoints {
        @include media-breakpoint($materialBreakpoint) {
            $infix: if($materialBreakpoint ==null, "", "-#{$breakpoint}");
            
            @for $i from 0 through 4 {
                $border-value: $i * 2;
                $border-suffix: "-#{$border-value}";
                // Gerar classes para todos os cantos
                .border#{$infix}#{$border-suffix},
                .b#{$infix}#{$border-suffix} {
                    border: #{$border-value}px solid!important;
                }

                @if($border-value != 0){
                    @each $direction, $abbrev in (bottom:bb, top:bt, left:bl, right:br) {
                        .border-#{$direction}#{$infix}#{$border-suffix},
                        .#{$abbrev}#{$infix}#{$border-suffix}{
                            border-#{$direction}:
                            if($direction == "bottom",#{$border-value}px solid,
                            if($direction == "top", #{$border-value}px solid,
                            if($direction == "left", #{$border-value}px solid,
                                                #{$border-value}px solid
                                            )
                                        )
                                    ) !important;
                        }
                    }
                }
            }
        }
    }
}
@include helper-border-size($helper-breakpoints);
.border-none {
    border: none !important
}

// -----------------------------------------------------------------------------------------------------
// @ Border Radius helpers
// -----------------------------------------------------------------------------------------------------
@mixin helper-border-radius($helper-breakpoints){
    @each $breakpoint, $materialBreakpoint in $helper-breakpoints {
        @include media-breakpoint($materialBreakpoint) {
            $infix: if($materialBreakpoint ==null, "", "-#{$breakpoint}");
            
            @for $i from 0 through 8 {
                $radius-value: $i * 2;
                $radius-suffix: "-#{$radius-value}";
                //Exemplo de classe gerada: .border-radius-md-4 ou .border-radius-4
                .border-radius#{$infix}#{$radius-suffix} {
                    border-radius: #{$radius-value}px !important;
                }

                @if($radius-value != 0){
                    @each $direction, $abbrev in (bottom:b, top:t, left:l, right:r) {
                        //Exemplo de classe gerada: .border-radius-bottom-md-4 e .border-radius-b-md-4 ou .border-radius-bottom-4 e .border-radius-b-4
                        .border-radius-#{$direction}#{$infix}#{$radius-suffix},
                        .border-radius-#{$abbrev}#{$infix}#{$radius-suffix}{
                            border-radius:
                            if($direction == "bottom", 0 0 #{$radius-value}px #{$radius-value}px,
                            if($direction == "top", #{$radius-value}px #{$radius-value}px 0 0,
                            if($direction == "left", #{$radius-value}px 0 0 #{$radius-value}px,
                                                0 #{$radius-value}px #{$radius-value}px 0
                                            )
                                        )
                                    )!important;
                        }
                    }
                }
            }
            .border-radius#{$infix}-circle,
            .border-radius#{$infix}-full,
            .border-radius#{$infix}-round {
                border-radius: 999px !important;
            }
        }
    }
}
@include helper-border-radius($helper-breakpoints);



// -----------------------------------------------------------------------------------------------------
// @ Overflow helpers
// -----------------------------------------------------------------------------------------------------
.overflow-auto {
    overflow: auto
}

.overflow-scroll {
    overflow: scroll
}

.overflow-hidden {
    overflow: hidden
}

.overflow-overlay {
    overflow: overlay
}

.overflow-x-auto {
    overflow-x: auto;
    overflow-y: hidden
}

.overflow-x-scroll {
    overflow-x: scroll;
    overflow-y: hidden
}

.overflow-x-hidden {
    overflow-x: hidden;
    overflow-y: hidden
}

.overflow-x-overlay {
    overflow-x: overlay;
    overflow-y: hidden
}

.overflow-y-auto {
    overflow-y: auto;
    overflow-x: hidden
}

.overflow-y-scroll {
    overflow-y: scroll;
    overflow-x: hidden
}

.overflow-y-hidden {
    overflow-y: hidden;
    overflow-x: hidden
}

.overflow-y-overlay {
    overflow-y: overlay;
    overflow-x: hidden
}


// -----------------------------------------------------------------------------------------------------
// @ Container helpers
// -----------------------------------------------------------------------------------------------------
.matcha-container {
	width: 100%;
	max-width: 1128px;
    margin-left:auto;
    margin-right:auto;
}

// -----------------------------------------------------------------------------------------------------
// @ Display helpers
// -----------------------------------------------------------------------------------------------------
.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-grid {
    display: grid !important;
}

.d-table {
    display: table !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-table-row {
    display: table-row !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

.d-flex {
    display: flex !important;
}

.d-flex-center-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.d-flex-row {
    display: flex !important;
    flex-direction: row !important;
}

.d-flex-column {
    display: flex !important;
    flex-direction: column !important;
}

.d-flex-row-reverse {
    display: flex !important;
    flex-direction: row-reverse !important;
}

.d-flex-column-reverse {
    display: flex !important;
    flex-direction: column-reverse !important;
}

.d-flex-nowrap {
    flex-wrap: nowrap !important;
}

.d-flex-wrap {
    flex-wrap: wrap !important;
}

.d-flex-align-center {
    display: flex !important;
    align-items: center !important;
}

.d-flex-align-start {
    display: flex !important;
    align-items: flex-start !important;
}

.d-flex-align-end {
    display: flex !important;
    align-items: flex-end !important;
}

.d-flex-align-self-end {
    align-self: flex-end !important;
}

.d-flex-align-self-start {
    align-self: flex-start !important;
}

.d-flex-center {
    display: flex !important;
    justify-content: center !important;
}

.d-flex-flex-end,
.d-flex-end {
    display: flex !important;
    justify-content: flex-end !important;
}

.d-flex-flex-start,
.d-flex-start {
    display: flex !important;
    justify-content: flex-start !important;
}

.d-flex-inherit {
    display: flex !important;
    justify-content: inherit !important;
}

.d-flex-initial {
    display: flex !important;
    justify-content: initial !important;
}

.d-flex-left {
    display: flex !important;
    justify-content: left !important;
}

.d-flex-normal {
    display: flex !important;
    justify-content: normal !important;
}

.d-flex-revert {
    display: flex !important;
    justify-content: revert !important;
}

.d-flex-right {
    display: flex !important;
    justify-content: right !important;
}

.d-flex-space-around {
    display: flex !important;
    justify-content: space-around !important;
}

.d-flex-space-between {
    display: flex !important;
    justify-content: space-between !important;
}

.d-flex-space-evenly {
    display: flex !important;
    justify-content: space-evenly !important;
}

.d-flex-stretch {
    display: flex !important;
    justify-content: stretch !important;
}

.d-flex-unset {
    display: flex !important;
    justify-content: unset !important;
}

@media only screen and (max-width: 599px) {
    .d-none-xs {
        display: none !important;
    }

    .d-inline-xs {
        display: inline !important;
    }

    .d-inline-block-xs {
        display: inline-block !important;
    }

    .d-block-xs {
        display: block !important;
    }

    .d-grid-xs {
        display: grid !important;
    }

    .d-table-xs {
        display: table !important;
    }

    .d-table-cell-xs {
        display: table-cell !important;
    }

    .d-table-row-xs {
        display: table-row !important;
    }

    .d-inline-flex-xs {
        display: inline-flex !important;
    }

    .d-flex-xs {
        display: flex !important;
    }

    .d-flex-xs-center-center {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .d-flex-xs-row {
        display: flex !important;
        flex-direction: row !important;
    }

    .d-flex-xs-column {
        display: flex !important;
        flex-direction: column !important;
    }

    .d-flex-xs-nowrap {
        flex-wrap: nowrap !important;
    }

    .d-flex-xs-align-center {
        display: flex !important;
        align-items: center !important;
    }

    .d-flex-xs-align-start {
        display: flex !important;
        align-items: flex-start !important;
    }

    .d-flex-xs-align-end {
        display: flex !important;
        align-items: flex-end !important;
    }

    .d-flex-xs-align-self-end {
        align-self: flex-end !important;
    }

    .d-flex-xs-align-self-start {
        align-self: flex-start !important;
    }

    .d-flex-xs-center {
        display: flex !important;
        justify-content: center !important;
    }

    .d-flex-xs-flex-end,
    .d-flex-xs-end {
        display: flex !important;
        justify-content: flex-end !important;
    }

    .d-flex-xs-flex-start,
    .d-flex-xs-start {
        display: flex !important;
        justify-content: flex-start !important;
    }

    .d-flex-xs-inherit {
        display: flex !important;
        justify-content: inherit !important;
    }

    .d-flex-xs-initial {
        display: flex !important;
        justify-content: initial !important;
    }

    .d-flex-xs-left {
        display: flex !important;
        justify-content: left !important;
    }

    .d-flex-xs-normal {
        display: flex !important;
        justify-content: normal !important;
    }

    .d-flex-xs-revert {
        display: flex !important;
        justify-content: revert !important;
    }

    .d-flex-xs-right {
        display: flex !important;
        justify-content: right !important;
    }

    .d-flex-xs-space-around {
        display: flex !important;
        justify-content: space-around !important;
    }

    .d-flex-xs-space-between {
        display: flex !important;
        justify-content: space-between !important;
    }

    .d-flex-xs-space-evenly {
        display: flex !important;
        justify-content: space-evenly !important;
    }

    .d-flex-xs-stretch {
        display: flex !important;
        justify-content: stretch !important;
    }

    .d-flex-xs-unset {
        display: flex !important;
        justify-content: unset !important;
    }
}

@media only screen and (min-width: 600px) {
    .d-none-sm {
        display: none !important;
    }

    .d-inline-sm {
        display: inline !important;
    }

    .d-inline-block-sm {
        display: inline-block !important;
    }

    .d-block-sm {
        display: block !important;
    }

    .d-grid-sm {
        display: grid !important;
    }

    .d-table-sm {
        display: table !important;
    }

    .d-table-cell-sm {
        display: table-cell !important;
    }

    .d-table-row-sm {
        display: table-row !important;
    }

    .d-inline-flex-sm {
        display: inline-flex !important;
    }

    .d-flex-sm {
        display: flex !important;
    }

    .d-flex-sm-center-center {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .d-flex-sm-row {
        display: flex !important;
        flex-direction: row !important;
    }

    .d-flex-sm-column {
        display: flex !important;
        flex-direction: column !important;
    }

    .d-flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }

    .d-flex-sm-align-center {
        display: flex !important;
        align-items: center !important;
    }

    .d-flex-sm-align-start {
        display: flex !important;
        align-items: flex-start !important;
    }

    .d-flex-sm-align-end {
        display: flex !important;
        align-items: flex-end !important;
    }

    .d-flex-sm-align-self-end {
        align-self: flex-end !important;
    }

    .d-flex-sm-align-self-start {
        align-self: flex-start !important;
    }

    .d-flex-sm-center {
        display: flex !important;
        justify-content: center !important;
    }

    .d-flex-sm-flex-end,
    .d-flex-sm-end {
        display: flex !important;
        justify-content: flex-end !important;
    }

    .d-flex-sm-flex-start,
    .d-flex-sm-start {
        display: flex !important;
        justify-content: flex-start !important;
    }

    .d-flex-sm-inherit {
        display: flex !important;
        justify-content: inherit !important;
    }

    .d-flex-sm-initial {
        display: flex !important;
        justify-content: initial !important;
    }

    .d-flex-sm-left {
        display: flex !important;
        justify-content: left !important;
    }

    .d-flex-sm-normal {
        display: flex !important;
        justify-content: normal !important;
    }

    .d-flex-sm-revert {
        display: flex !important;
        justify-content: revert !important;
    }

    .d-flex-sm-right {
        display: flex !important;
        justify-content: right !important;
    }

    .d-flex-sm-space-around {
        display: flex !important;
        justify-content: space-around !important;
    }

    .d-flex-sm-space-between {
        display: flex !important;
        justify-content: space-between !important;
    }

    .d-flex-sm-space-evenly {
        display: flex !important;
        justify-content: space-evenly !important;
    }

    .d-flex-sm-stretch {
        display: flex !important;
        justify-content: stretch !important;
    }

    .d-flex-sm-unset {
        display: flex !important;
        justify-content: unset !important;
    }
}

@media only screen and (min-width: 1024px) {
    .d-none-md {
        display: none !important;
    }

    .d-inline-md {
        display: inline !important;
    }

    .d-inline-block-md {
        display: inline-block !important;
    }

    .d-block-md {
        display: block !important;
    }

    .d-grid-md {
        display: grid !important;
    }

    .d-table-md {
        display: table !important;
    }

    .d-table-cell-md {
        display: table-cell !important;
    }

    .d-table-row-md {
        display: table-row !important;
    }

    .d-inline-flex-md {
        display: inline-flex !important;
    }

    .d-flex-md {
        display: flex !important;
    }

    .d-flex-md-center-center {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .d-flex-md-row {
        display: flex !important;
        flex-direction: row !important;
    }

    .d-flex-md-column {
        display: flex !important;
        flex-direction: column !important;
    }

    .d-flex-md-nowrap {
        flex-wrap: nowrap !important;
    }

    .d-flex-md-align-center {
        display: flex !important;
        align-items: center !important;
    }

    .d-flex-md-align-start {
        display: flex !important;
        align-items: flex-start !important;
    }

    .d-flex-md-align-end {
        display: flex !important;
        align-items: flex-end !important;
    }

    .d-flex-md-align-self-end {
        align-self: flex-end !important;
    }

    .d-flex-md-align-self-start {
        align-self: flex-start !important;
    }

    .d-flex-md-center {
        display: flex !important;
        justify-content: center !important;
    }

    .d-flex-md-flex-end,
    .d-flex-md-end {
        display: flex !important;
        justify-content: flex-end !important;
    }

    .d-flex-md-flex-start,
    .d-flex-md-start {
        display: flex !important;
        justify-content: flex-start !important;
    }

    .d-flex-md-inherit {
        display: flex !important;
        justify-content: inherit !important;
    }

    .d-flex-md-initial {
        display: flex !important;
        justify-content: initial !important;
    }

    .d-flex-md-left {
        display: flex !important;
        justify-content: left !important;
    }

    .d-flex-md-normal {
        display: flex !important;
        justify-content: normal !important;
    }

    .d-flex-md-revert {
        display: flex !important;
        justify-content: revert !important;
    }

    .d-flex-md-right {
        display: flex !important;
        justify-content: right !important;
    }

    .d-flex-md-space-around {
        display: flex !important;
        justify-content: space-around !important;
    }

    .d-flex-md-space-between {
        display: flex !important;
        justify-content: space-between !important;
    }

    .d-flex-md-space-evenly {
        display: flex !important;
        justify-content: space-evenly !important;
    }

    .d-flex-md-stretch {
        display: flex !important;
        justify-content: stretch !important;
    }

    .d-flex-md-unset {
        display: flex !important;
        justify-content: unset !important;
    }
}

@media only screen and (min-width: 1440px) {
    .d-none-lg {
        display: none !important;
    }

    .d-inline-lg {
        display: inline !important;
    }

    .d-inline-block-lg {
        display: inline-block !important;
    }

    .d-block-lg {
        display: block !important;
    }

    .d-grid-lg {
        display: grid !important;
    }

    .d-table-lg {
        display: table !important;
    }

    .d-table-cell-lg {
        display: table-cell !important;
    }

    .d-table-row-lg {
        display: table-row !important;
    }

    .d-inline-flex-lg {
        display: inline-flex !important;
    }

    .d-flex-lg {
        display: flex !important;
    }

    .d-flex-lg-center-center {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .d-flex-lg-row {
        display: flex !important;
        flex-direction: row !important;
    }

    .d-flex-lg-column {
        display: flex !important;
        flex-direction: column !important;
    }

    .d-flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }

    .d-flex-lg-align-center {
        display: flex !important;
        align-items: center !important;
    }

    .d-flex-lg-align-start {
        display: flex !important;
        align-items: flex-start !important;
    }

    .d-flex-lg-align-end {
        display: flex !important;
        align-items: flex-end !important;
    }

    .d-flex-lg-align-self-end {
        align-self: flex-end !important;
    }

    .d-flex-lg-align-self-start {
        align-self: flex-start !important;
    }

    .d-flex-lg-center {
        display: flex !important;
        justify-content: center !important;
    }

    .d-flex-lg-flex-end,
    .d-flex-lg-end {
        display: flex !important;
        justify-content: flex-end !important;
    }

    .d-flex-lg-flex-start,
    .d-flex-lg-start {
        display: flex !important;
        justify-content: flex-start !important;
    }

    .d-flex-lg-inherit {
        display: flex !important;
        justify-content: inherit !important;
    }

    .d-flex-lg-initial {
        display: flex !important;
        justify-content: initial !important;
    }

    .d-flex-lg-left {
        display: flex !important;
        justify-content: left !important;
    }

    .d-flex-lg-normal {
        display: flex !important;
        justify-content: normal !important;
    }

    .d-flex-lg-revert {
        display: flex !important;
        justify-content: revert !important;
    }

    .d-flex-lg-right {
        display: flex !important;
        justify-content: right !important;
    }

    .d-flex-lg-space-around {
        display: flex !important;
        justify-content: space-around !important;
    }

    .d-flex-lg-space-between {
        display: flex !important;
        justify-content: space-between !important;
    }

    .d-flex-lg-space-evenly {
        display: flex !important;
        justify-content: space-evenly !important;
    }

    .d-flex-lg-stretch {
        display: flex !important;
        justify-content: stretch !important;
    }

    .d-flex-lg-unset {
        display: flex !important;
        justify-content: unset !important;
    }
}

@media only screen and (min-width: 1920px) {
    .d-none-xl {
        display: none !important;
    }

    .d-inline-xl {
        display: inline !important;
    }

    .d-inline-block-xl {
        display: inline-block !important;
    }

    .d-block-xl {
        display: block !important;
    }

    .d-grid-xl {
        display: grid !important;
    }

    .d-table-xl {
        display: table !important;
    }

    .d-table-cell-xl {
        display: table-cell !important;
    }

    .d-table-row-xl {
        display: table-row !important;
    }

    .d-inline-flex-xl {
        display: inline-flex !important;
    }

    .d-flex-xl {
        display: flex !important;
    }

    .d-flex-xl-center-center {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .d-flex-xl-row {
        display: flex !important;
        flex-direction: row !important;
    }

    .d-flex-xl-column {
        display: flex !important;
        flex-direction: column !important;
    }

    .d-flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }

    .d-flex-xl-align-center {
        display: flex !important;
        align-items: center !important;
    }

    .d-flex-xl-align-start {
        display: flex !important;
        align-items: flex-start !important;
    }

    .d-flex-xl-align-end {
        display: flex !important;
        align-items: flex-end !important;
    }

    .d-flex-xl-align-self-end {
        align-self: flex-end !important;
    }

    .d-flex-xl-align-self-start {
        align-self: flex-start !important;
    }

    .d-flex-xl-center {
        display: flex !important;
        justify-content: center !important;
    }

    .d-flex-xl-flex-end,
    .d-flex-xl-end {
        display: flex !important;
        justify-content: flex-end !important;
    }

    .d-flex-xl-flex-start,
    .d-flex-xl-start {
        display: flex !important;
        justify-content: flex-start !important;
    }

    .d-flex-xl-inherit {
        display: flex !important;
        justify-content: inherit !important;
    }

    .d-flex-xl-initial {
        display: flex !important;
        justify-content: initial !important;
    }

    .d-flex-xl-left {
        display: flex !important;
        justify-content: left !important;
    }

    .d-flex-xl-normal {
        display: flex !important;
        justify-content: normal !important;
    }

    .d-flex-xl-revert {
        display: flex !important;
        justify-content: revert !important;
    }

    .d-flex-xl-right {
        display: flex !important;
        justify-content: right !important;
    }

    .d-flex-xl-space-around {
        display: flex !important;
        justify-content: space-around !important;
    }

    .d-flex-xl-space-between {
        display: flex !important;
        justify-content: space-between !important;
    }

    .d-flex-xl-space-evenly {
        display: flex !important;
        justify-content: space-evenly !important;
    }

    .d-flex-xl-stretch {
        display: flex !important;
        justify-content: stretch !important;
    }

    .d-flex-xl-unset {
        display: flex !important;
        justify-content: unset !important;
    }
}

.flex-grow-1 {
    flex-grow: 1;
    ;
}

.flex-shrink-1 {
    flex-shrink: 1;
    ;
}

.flex-basis-1 {
    flex-basis: 100%;
    ;
}

.flex-full {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
    ;
}

// -----------------------------------------------------------------------------------------------------
// @ Combo helpers
// -----------------------------------------------------------------------------------------------------
.combo-image-text,
.combo-checkbox-text,
.combo-icon-text,
.combo-double-line {
    display: flex;
    align-items: center;
}

.combo-image-text .side-text,
.combo-checkbox-text .side-text,
.combo-icon-text .side-text,
.combo-double-line .side-text {
    display: inline-block;
    width: calc(100% - 48px);
    line-height: 20px;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    white-space: initial;

    -webkit-box-orient: vertical;

}

.combo-image-text .avatar,
.combo-checkbox-text .avatar,
.combo-icon-text .avatar,
.combo-double-line .avatar {
    border-radius: 50px;
    min-width: 24px;
    min-height: 24px;
}

.one-line-text {
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
}

.two-lines-text {
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
}

.three-lines-text {
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
}

.four-lines-text {
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
}

.five-lines-text {
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
}

.one-line-text,
.two-lines-text,
.three-lines-text,
.four-lines-text,
.five-lines-text {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    white-space: initial;

    -webkit-box-orient: vertical;

}

.word-break {
    word-break: break-word
}

.word-break-all {
    word-break: break-all
}

// -----------------------------------------------------------------------------------------------------
// @ Font Weight
// -----------------------------------------------------------------------------------------------------
@mixin helper-font-weight($helper-breakpoints){
    @each $breakpoint, $materialBreakpoint in $helper-breakpoints {
        @include media-breakpoint($materialBreakpoint) {
            $infix: if($materialBreakpoint ==null, "", "-#{$breakpoint}");
    
            @for $index from 1 through 9 {
                $weight: $index * 100;
                .font-weight#{$infix}-#{$weight},
                .fw#{$infix}-#{$weight} {
                    font-weight: $weight !important;
                }
            }
        }
    }
}
@include helper-font-weight($helper-breakpoints);

// -----------------------------------------------------------------------------------------------------
// @ Font Size
// -----------------------------------------------------------------------------------------------------
@mixin helper-font-size($helper-breakpoints){
    @each $breakpoint, $materialBreakpoint in $helper-breakpoints {
        @include media-breakpoint($materialBreakpoint) {
            $infix: if($materialBreakpoint ==null, "", "-#{$breakpoint}");
    
            $index: 0;
            @while $index <= 18 {
                $size: $index * 4;
                .font-size#{$infix}-#{$size},
                .fs#{$infix}-#{$size} {
                    font-size: #{$size}px !important;
                }
                $index: $index + 1;
            }
        }
    }
}
@include helper-font-size($helper-breakpoints);

// -----------------------------------------------------------------------------------------------------
// @ Line Height
// -----------------------------------------------------------------------------------------------------
@mixin helper-line-height($helper-breakpoints){
    @each $breakpoint, $materialBreakpoint in $helper-breakpoints {
        @include media-breakpoint($materialBreakpoint) {
            $infix: if($materialBreakpoint ==null, "", "-#{$breakpoint}");
    
            $index: 0;
            @while $index <= 36 {
                $height: $index * 2;
                .line-height#{$infix}-#{$height},
                .lh#{$infix}-#{$height} {
                    line-height: #{$height}px !important;
                }
                $index: $index + 1;
            }
        }
    }
}
@include helper-line-height($helper-breakpoints);

// -----------------------------------------------------------------------------------------------------
// @ Letter spacing helpers
// -----------------------------------------------------------------------------------------------------

.letter-spacing-condensed {
    letter-spacing: -.08em !important;
}

.letter-spacing-normal {
    letter-spacing: normal !important;
}

.letter-spacing-relaxed {
    letter-spacing: .08em !important;
}


// -----------------------------------------------------------------------------------------------------
// @ Cursor helpers
// -----------------------------------------------------------------------------------------------------
.cursor-pointer {
    cursor: pointer
}

.cursor-default {
    cursor: default
}

.cursor-grab {
    cursor: grab
}

.cursor-grabbing {
    cursor: grabbing
}

.cursor-move {
    cursor: move
}

.cursor-help {
    cursor: help
}

.cursor-wait {
    cursor: wait
}

.pointer-events-all {
    pointer-events: all
}

.pointer-events-none {
    pointer-events: none
}

// -----------------------------------------------------------------------------------------------------
// @ Clear Shadows
// -----------------------------------------------------------------------------------------------------
.flatten,
.no-shadow,
.shadow-none {
    box-shadow: none !important;
}

// -----------------------------------------------------------------------------------------------------
// @ Enable/Disable
// -----------------------------------------------------------------------------------------------------
.disabled {
    transition: opacity 100ms linear;
    opacity: .5;
    pointer-events: none
}

.enabled {
    transition: opacity 100ms linear;
    opacity: 1
}


// -----------------------------------------------------------------------------------------------------
// @ Opacity
// -----------------------------------------------------------------------------------------------------
/* <integer> values */
.opacity-0 {
    opacity: 0;
}

.opacity-01 {
    opacity: 0.1;
}

.opacity-02 {
    opacity: 0.2;
}

.opacity-03 {
    opacity: 0.3;
}

.opacity-04 {
    opacity: 0.4;
}

.opacity-05 {
    opacity: 0.5;
}

.opacity-06 {
    opacity: 0.6;
}

.opacity-07 {
    opacity: 0.7;
}

.opacity-08 {
    opacity: 0.8;
}

.opacity-09 {
    opacity: 0.9;
}

.opacity-10,
.opacity-1 {
    opacity: 1.0;
}

// -----------------------------------------------------------------------------------------------------
// @ Hover - Show/Hide
// -----------------------------------------------------------------------------------------------------
.on-hover-show:hover {
    opacity: 1;
}

.on-hover-hide:hover {
    opacity: 0;
}

// -----------------------------------------------------------------------------------------------------
// @ Transition
// write mixin using the time tokens
// -----------------------------------------------------------------------------------------------------
.ts-100-l {
    transition: all 100ms linear;
}

.ts-300-l {
    transition: all 300ms linear;
}

.ts-100-ei {
    transition: all 100ms ease-in;
}

.ts-300-ei {
    transition: all 300ms ease-in;
}

.ts-100-eo {
    transition: all 100ms ease-out;
}

.ts-300-eo {
    transition: all 300ms ease-out;
}

.ts-100-eio {
    transition: all 100ms ease-in-out;
}

.ts-300-eio {
    transition: all 300ms ease-in-out;
}

// -----------------------------------------------------------------------------------------------------
// @ Rotate Animation
// -----------------------------------------------------------------------------------------------------
.rotate-infinity-1s {
    animation: spin 1s infinite;
}

.rotate-infinity-2s {
    animation: spin 2s infinite;
}

.rotate-infinity-3s {
    animation: spin 3s infinite;
}

@keyframes spin {
    0% {
        rotate: 0deg;
    }

    100% {
        rotate: 360deg;
    }
}

// -----------------------------------------------------------------------------------------------------
// @ Float
// -----------------------------------------------------------------------------------------------------
.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.left {
    float: left;
}

.right {
    float: right;
}

.list,
.list-style-none {
    list-style: none;
}

.list-style-disc,
.list-style-dot {
    list-style: disc;
}
