@mixin matcha-scrollbox-shadow($theme) {
    $is-dark: map-get($theme, is-dark);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    // Drop shadow inside of area when scrolled vertically
    .scrollbox,
    .scrollbox-vertical,
    .scrollbox-v,
    .scrollbox-y {
        overflow: overlay;
        background:
            /* Shadow covers */
            linear-gradient(map-get($background, card) 30%, rgba(255, 255, 255, 0)),
            linear-gradient(rgba(255, 255, 255, 0), map-get($background, card) 70%) 0 100%,
            /* Shadows */
            linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 50%),
            linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 50%) 0 100%;

        background-repeat: no-repeat;
        background-color: map-get($background, card);
        background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

        /* Opera doesn't support this in the shorthand */
        background-attachment: local, local, scroll, scroll;
    }

    // Drop shadow inside of area when scrolled horizontally
    .scrollbox-horizontal,
    .scrollbox-h,
    .scrollbox-x {
        box-shadow: 0px -16px 0px 0px inset map-get($background, card), 20px 0 16px -24px inset #000000,
            -20px 0 16px -24px inset #000000, 0px 2px 2px 0px #00000009, 0px 2px 2px 0px #00000009,
            0px 2px 2px 0px #00000009;
        overflow: auto;
        background: linear-gradient(90deg,
                rgba(0, 0, 0, 0.1),
                rgba(0, 0, 0, 0) calc(0% + 10px),
                rgba(0, 0, 0, 0) calc(100% - 10px),
                rgba(0, 0, 0, 0.1));

        background: -moz-linear-gradient(90deg,
                rgba(0, 0, 0, 0.1),
                rgba(0, 0, 0, 0) calc(0% + 10px),
                rgba(0, 0, 0, 0) calc(100% - 10px),
                rgba(0, 0, 0, 0.1));

        background: -webkit-linear-gradient(0deg,
                rgba(0, 0, 0, 0.1),
                rgba(0, 0, 0, 0) calc(0% + 10px),
                rgba(0, 0, 0, 0) calc(100% - 10px),
                rgba(0, 0, 0, 0.1));
    }

    .scrollbox-horizontal-content,
    .scrollbox-h-content,
    .scrollbox-x-content {
        background: map-get($background, card);
        background: linear-gradient(90deg,
                map-get($background, card) 10px,
                map-get($background, card),
                rgba(255, 255, 255, 0) calc(0% + 10px),
                rgba(255, 255, 255, 0) calc(100% - 10px),
                map-get($background, card) 10px,
                map-get($background, card));
        background: -moz-linear-gradient(90deg,
                map-get($background, card) 10px,
                map-get($background, card),
                rgba(255, 255, 255, 0) calc(0% + 10px),
                rgba(255, 255, 255, 0) calc(100% - 10px),
                map-get($background, card) 10px,
                map-get($background, card));
        background: -webkit-linear-gradient(0deg,
                map-get($background, card) 10px,
                map-get($background, card),
                rgba(255, 255, 255, 0) calc(0% + 10px),
                rgba(255, 255, 255, 0) calc(100% - 10px),
                map-get($background, card) 10px,
                map-get($background, card));
    }

    .scrollbox-horizontal-content-background,
    .scrollbox-h-content-background,
    .scrollbox-x-content-background {
        background: map-get($background, background);
        background: linear-gradient(90deg,
                map-get($background, background) 10px,
                map-get($background, background),
                rgba(255, 255, 255, 0) calc(0% + 10px),
                rgba(255, 255, 255, 0) calc(100% - 10px),
                map-get($background, background) 10px,
                map-get($background, background));
        background: -moz-linear-gradient(90deg,
                map-get($background, background) 10px,
                map-get($background, background),
                rgba(255, 255, 255, 0) calc(0% + 10px),
                rgba(255, 255, 255, 0) calc(100% - 10px),
                map-get($background, background) 10px,
                map-get($background, background));
        background: -webkit-linear-gradient(0deg,
                map-get($background, background) 10px,
                map-get($background, background),
                rgba(255, 255, 255, 0) calc(0% + 10px),
                rgba(255, 255, 255, 0) calc(100% - 10px),
                map-get($background, background) 10px,
                map-get($background, background));
    }

    .scrollbox-background {
        overflow: overlay;
        background:
            /* Shadow covers */
            linear-gradient(map-get($background, background) 30%, rgba(255, 255, 255, 0)),
            linear-gradient(rgba(255, 255, 255, 0), map-get($background, background) 70%) 0 100%,
            /* Shadows */
            linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 50%),
            linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 50%) 0 100%;
        background-repeat: no-repeat;
        background-color: map-get($background, background);
        background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
        /* Opera doesn't support this in the shorthand */
        background-attachment: local, local, scroll, scroll;
    }
}
