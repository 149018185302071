/*
How to use
@import '~matcha-ds/assets/scss/components/matcha-header.scss';
@include matcha-progress-bar-theme($theme);
*/
// -----------------------------------------------------------------------------------------------------
// Progress-bar [Theme]
// -----------------------------------------------------------------------------------------------------
@mixin matcha-progress-bar-theme($theme) {
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .matcha-progress-bar {

        // matcha-progress-bar__field
        &__field {
            background: map-get($background, disabled);
            transition: all 300ms linear;
        }

        // matcha-progress-bar__progress
        &__progress {
            transition: all 300ms ease-out;

            &::before {
                content: '';
                border-radius: 8px;
                background-size: 50px 50px;
                background-image: linear-gradient(-45deg,
                        rgba(0, 0, 0, 0) 25%,
                        transparent 25%,
                        transparent 50%,
                        rgba(0, 0, 0, 0) 50%,
                        rgba(0, 0, 0, 0) 75%,
                        transparent 75%,
                        transparent);
                animation: progress-bar-animation 2s linear infinite;
                width: 100%;
                display: flex;
                transition: all 300ms linear;

            }
        }

        @keyframes progress-bar-animation {
            0% {
                background-position: 50px 50px;
            }

            100% {
                background-position: 0px 0px;
            }
        }

        // matcha-progress-bar__progress
        &__label {
            color: map-get($foreground, text-inverse);
        }
    }
}

// -----------------------------------------------------------------------------------------------------
// Progress-bar [Style]
// -----------------------------------------------------------------------------------------------------
.matcha-progress-bar {
    width: 100%;
    display: flex;

    // matcha-progress-bar__field
    &__field {
        border-radius: 8px;
        display: flex;
        width: 100%;
    }

    // matcha-progress-bar__progress
    &__progress {
        line-height: 12px;
        font-size: 12px;
        height: 16px;
        border-radius: 8px;
        display: flex;
    }

    // matcha-progress-bar__progress
    &__label {
        line-height: 16px;
        padding-left: 8px;
        display: flex;
        position: relative;
    }
}

// Medium devices (landscapes and tablets, 600px and up < 1024px )
@media (min-width: 600px) {}

// Large devices (tablets and small monitors, 1024px and up < 1440px)
@media (min-width: 1024px) {}

// X-Large devices (big desktops, 1440px and up < 1920)
@media (min-width: 1440px) {}

// XX-Large devices (larger desktops, 1920px and up)
@media (min-width: 1920px) {}
