// -------------------------------------------------------------------------------------------------------------------
// @ Typography mixins
// -------------------------------------------------------------------------------------------------------------------
// Outputs the shorthand `font` CSS property, based on a set of typography values. Falls back to
// the individual properties if a value that isn't allowed in the shorthand is passed in.
@mixin matcha-typography-font-shorthand($font-size, $font-weight, $line-height, $font-family) {
    // If any of the values are set to `inherit`, we can't use the shorthand
    // so we fall back to passing in the individual properties.
    @if (
        $font-size
            ==inherit or
            $font-weight
            ==inherit or
            $line-height
            ==inherit or
            $font-family
            ==inherit or
            $font-size
            ==null or
            $font-weight
            ==null or
            $line-height
            ==null or
            $font-family
            ==null
    ) {
        font-size: $font-size;
        font-weight: $font-weight;
        line-height: $line-height;
        font-family: $font-family;
    } @else {
        // Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
        // that we need to use interpolation for `font-size/line-height` in order to prevent
        // Sass from dividing the two values.
        font: $font-weight #{$font-size}/#{$line-height} $font-family;
    }
}

// Converts a typography level into CSS styles.
@mixin matcha-typography-level-to-styles($config, $level) {
    $font-size: matcha-font-size($config, $level);
    $font-weight: matcha-font-weight($config, $level);
    $line-height: matcha-line-height($config, $level);
    $font-family: matcha-font-family($config, $level);

    @include matcha-typography-font-shorthand($font-size, $font-weight, $line-height, $font-family);
    letter-spacing: matcha-letter-spacing($config, $level);
}

// Represents a collection of typography levels.
// Defaults come from https://material.io/guidelines/style/typography.html
// Note: The spec doesn't mention letter spacing. The values here come from
// eyeballing it until it looked exactly like the spec examples.

// h1 40px headline - bold
// h2 32px title - medium
// h3 24px subheading - bold
// h4 20px subheading - bold

// h5-5 18px '' - bold
// h6 18px '' - bold
// h7 18px '' - bold
// h8 12px '' - bold

// m 16px body - regular
// p 14px body - regular
// small 12px caption - regular
@mixin matcha-reset-typography($config) {
    // Headings
    .h1,
    h1 {
        font-size: px-to-rem(40px);
        font-family: matcha-font-family($config, title);
        font-weight: matcha-font-weight($config, title);
    }

    .h2,
    h2 {
        font-size: px-to-rem(32px);
        font-family: matcha-font-family($config, body-2);
        font-weight: matcha-font-weight($config, body-2);
    }

    .h3,
    h3 {
        font-size: px-to-rem(24px);
        font-family: matcha-font-family($config, title);
        font-weight: matcha-font-weight($config, title);
    }

    .h4,
    h4 {
        font-size: px-to-rem(20px);
        font-family: matcha-font-family($config, title);
        font-weight: matcha-font-weight($config, title);
    }

    .h5,
    h5 {
        font-size: px-to-rem(16px);
        font-family: matcha-font-family($config, title);
        font-weight: matcha-font-weight($config, title);
    }

    .h6,
    h6 {
        font-size: px-to-rem(12px);
        font-family: matcha-font-family($config, subheading-2);
        font-weight: matcha-font-weight($config, subheading-2);
    }

    .text-bold,
    strong,
    b {
        font-family: matcha-font-family($config, title);
        font-weight: matcha-font-weight($config, title);
    }
}
