// -------------------------------------------------------------------------------------------------------------------
// @ Breakpoints generator
//
// Examples:
// @include media-breakpoint("xs") {
// Show styles in small devices ( Phones and Gadget, 0px and up < 600px )
// }
// @include media-breakpoint("gt-xs") {
// Show styles in medium devices (landscapes and tablets, 600px and up < 1024px )
// }
// @include media-breakpoint("gt-sm") {
// Show styles in large devices (tablets and small monitors, 1024px and up < 1440px)
// }
// @include media-breakpoint("gt-md") {
// Show styles in X-Large devices (big desktops, 1440px and up < 1920)
// }
// @include media-breakpoint("gt-lg") {
// Show styles in XX-Large devices (larger desktops, 1920px and up)
// }
// -------------------------------------------------------------------------------------------------------------------
@mixin media-breakpoint($breakpointName) {
    $mediaQuery: map-get($breakpoints, $breakpointName);

    @if ($mediaQuery ==null) {
        @content;
    }

    @else {
        @media #{$mediaQuery} {
            @content;
        }
    }
}
