@mixin _matcha-table-theme-container-shadow-property($theme) {
    $background: map-get($theme, background);

    box-shadow:
        0px -16px 0px 0px inset map-get($background, card),
        20px 0 16px -24px inset #000000,
        -20px 0 16px -24px inset #000000,
        0px 2px 2px 0px #00000009,
        0px 2px 2px 0px #00000009,
        0px 2px 2px 0px #00000009;
}

@mixin _matcha-table-theme-shadow-property($theme) {
    $background: map-get($theme, background);

    background: map-get($background, card);
    background: linear-gradient(
        90deg,
        map-get($background, card) 0px,
        map-get($background, card) 0px,
        rgba(255, 255, 255, 0) calc(0% + 40px),
        rgba(255, 255, 255, 0) calc(100% - 40px),
        map-get($background, card) calc(100% - 0px)
    );
    background: -moz-linear-gradient(
        90deg,
        map-get($background, card) 0px,
        map-get($background, card) 0px,
        rgba(255, 255, 255, 0) calc(0% + 40px),
        rgba(255, 255, 255, 0) calc(100% - 40px),
        map-get($background, card) calc(100% - 0px)
    );
    background: -webkit-linear-gradient(
        0deg,
        map-get($background, card) 0px,
        map-get($background, card) 0px,
        rgba(255, 255, 255, 0) calc(0% + 40px),
        rgba(255, 255, 255, 0) calc(100% - 40px),
        map-get($background, card) calc(100% - 0px)
    );
}

@mixin matcha-table-theme($theme) {
    $is-dark-theme: map-get($theme, is-dark);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    // Vai direto na tag body
    body.is-mobile {
        .table-container {
            .table-container-shadow {
                overflow: auto;
                @include _matcha-table-theme-container-shadow-property($theme);

                .mat-table,
                .mat-sort {
                    tbody {
                        @include _matcha-table-theme-shadow-property($theme);
                    }
                }
            }
        }
    }

    .table-container {
        width: 100%;

        .mat-table,
        .mat-sort {
            .published {
                color: map-get($foreground, green);
                // margin-top: 5px;
            }

            .notPublished {
                color: map-get($foreground, amber);
                // margin-top: 5px;
            }
        }

        .table-container-shadow {
            overflow: auto;
            background: map-get($background, card);
            border-radius: 8px;
            box-shadow:
                0px 2px 2px 0px #00000009,
                0px 2px 2px 0px #00000009,
                0px 2px 2px 0px #00000009;
            @include _matcha-table-theme-container-shadow-property($theme);

            .mat-table,
            .mat-sort {
                tbody {
                    @include _matcha-table-theme-shadow-property($theme);
                }
            }

            &.ps--active-x {
                @include _matcha-table-theme-container-shadow-property($theme);

                .mat-table,
                .mat-sort {
                    @include _matcha-table-theme-shadow-property($theme);
                }
            }
        }

        .mat-table,
        .mat-sort,
        .mat-expansion-panel {
            thead {
                background: map-get($background, card);

                th {
                    height: 64px;
                }
            }

            tr {
                &.mat-row {
                    // &:nth-child(even){background: map-get($background, card);}
                    &:nth-child(odd) {
                        background: map-get($background, table-row-hover);
                    }
                }

                td,
                th {
                    touch-action: unset !important;
                    font-size: 14px;

                    &:first-child {
                        padding-left: 24px;
                    }

                    &:last-child {
                    }

                    .clickable {
                        cursor: pointer;
                    }
                }
            }

            .td-duble-line {
                height: 48px;
            }

            .td-with-image,
            .td-with-checkbox,
            .td-with-icon,
            .td-duble-line {
                display: flex;
                align-items: center;

                .td-text-aside {
                    display: inline-block;
                    width: calc(100% - 48px);
                }

                .avatar {
                    width: 48px;
                    min-width: 48px;
                    height: 48px;
                    line-height: 48px;
                    border-width: 0;
                    margin-right: 24px;
                }

                .thumbnail {
                    width: 72px;
                    min-width: 72px;
                    height: 48px;
                    border-radius: 8px;
                    margin: 0 8px 0 0;

                    &-portrait {
                        width: 56px;
                        min-width: 56px;
                        height: 72px;
                        border-radius: 8px;
                        margin: 0 8px 0 0;
                    }
                }
            }

            .mat-header-cell {
                color: map-get($foreground, text);
                font-family: "CircularStd";
                font-weight: 700;
                font-size: 16px;
                padding-left: 8px;
                padding-right: 8px;
                border-bottom-width: 0px;
            }

            .mat-cell {
                color: map-get($foreground, label);
                font-size: 14px;
                padding-left: 8px;
                padding-right: 8px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: start;
                border-bottom-width: 0px;

                &.cdk-column-menu {
                    text-align: left;
                }
            }

            .last {
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;

                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                mat-menu {
                    display: none;
                }
            }
        }

        table {
            width: 100%;
        }

        .badge {
            border-radius: 8px;
            padding: 8px;
            color: map-get($foreground, text-inverse);

            &.live-badge {
                background-color: map-get($background, cyan);
                font-size: 14px;
            }

            &.cell-badge {
                background-color: map-get($background, purple);
                font-size: 14px;
            }

            &.cult-badge {
                background-color: map-get($background, blue);
                font-size: 14px;
            }

            &.new-visitor-badge {
                background-color: map-get($primary, default);
                color: map-get($primary, default-contrast);
                font-size: 14px;
                line-height: 14px;
                display: inline-block;
                width: auto;
            }
        }
    }

    // X-Large devices (big desktops, 1440px and up < 1920)
    @media (min-width: 1440px) {
        .table-container {
            .mat-table,
            .mat-sort {
                .mat-cell {
                    font-size: 16px;
                }
            }
        }
    }
}
