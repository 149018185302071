@mixin matcha-button-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .matcha-button {
        &-xs {
            font-size: 14px;
            padding: $spacing-inline-s;
            border-radius: $border-radius-medium;
            line-height: $size-line-height-minimum;
        }

        &-sm {
            font-size: 16px;
            padding: $spacing-inline-s;
            border-radius: $border-radius-medium;
            line-height: $size-line-height-small;
        }

        &-md {
            font-size: 16px;
            padding: $spacing-inline-s;
            border-radius: $border-radius-medium;
            line-height: $size-line-height-medium;
        }

        &-lg {
            font-size: 20px;
            padding: $spacing-inline-s;
            border-radius: $border-radius-medium;
            line-height: $size-line-height-large;
        }

        &-xl {
            font-size: 20px;
            padding: $spacing-inline-m;
            border-radius: $border-radius-medium;
            line-height: $size-line-height-xlarge;
        }

        &-main {
            background: map-get($background, background);
            line-height: 32px;
            padding: 0 16px;
        }

        &-xs,
        &-sm,
        &-md,
        &-lg,
        &-xl {
            min-width: 48px;

            &.stroked {
                border: 0px solid currentColor;
                box-shadow: 0px 0px 0px 2px inset;

                &.color-label {
                    box-shadow: 0px 0px 0px 2px inset map-color($foreground, label);
                    color: map-color($foreground, label);
                }

                &.color-placeholder {
                    box-shadow: 0px 0px 0px 2px inset map-color($foreground, placeholder);
                    color: map-color($foreground, placeholder);
                }

                &.color-primary {
                    box-shadow: 0px 0px 0px 2px inset map-color($primary, default);
                    color: map-color($primary, default);
                }
            }
        }

        &-pill {
            border-radius: $border-radius-wider;
        }

        &-link {
            background-color: transparent;
            color: map-color($foreground, label);
            text-transform: initial;
            letter-spacing: 0;
            text-decoration: underline;
            padding: 0;
        }
    }
}
