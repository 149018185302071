@import "app/app.theme.scss";

html,
body {
  padding: 0;
  margin: 0;
  font-family: CircularStd, "Helvetica Neue", sans-serif;
  mat-form-field.ng-untouched,
  mat-form-field.ng-valid {
    height: 48px;
  }
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    border-radius: 8px !important;
  }
}
