// TOKENS
@import "./tokens/animations";
@import "./tokens/breakpoints";
@import "./tokens/color-tokens";
@import "./tokens/elevation-tokens";
@import "./tokens/spacing-tokens";
@import "./tokens/typography-tokens";

// FUNCTIONS
@import "./abstracts/functions"; // map-contrast() palette() map-color() light-theme() dark-theme() matcha-typography-level() matcha-typography-config() _matcha-get-type-value() matcha-font-size() matcha-line-height() matcha-font-weight() matcha-letter-spacing() matcha-font-family() _get-umbra-map() _get-penumbra-map() _get-ambient-map() elevation-transition-property-value()

// MIXINS
@import "./abstracts/breakpoints"; //media-breakpoint()
@import "./abstracts/colors"; // colors-classes-static() colors-classes-dynamic()
@import "./abstracts/elevation"; // elevation() _matcha-theme-elevation() matcha-overridable-elevation() _matcha-theme-overridable-elevation() elevation-transition() elevation-theme()
@import "./abstracts/grid"; // display-grid() display-flex()
@import "./abstracts/order";
@import "./abstracts/position";
@import "./abstracts/sizes";
@import "./abstracts/spacings";
@import "./abstracts/typography"; // matcha-typography-font-shorthand() matcha-typography-level-to-styles() matcha-reset-typography()

// BASE
@import "./base/helpers";
@import "./base/reset"; // base-body-reset-theme()
@import "./base/typography"; // base-typography-theme()

// COMPONENTS
@import "./components/matcha-audio-player.scss"; //matcha-audio-player-theme($theme)
@import "./components/matcha-buttons.scss"; // matcha-button-theme($theme)
@import "./components/matcha-cards.scss"; // matcha-cards-theme($theme)
@import "./components/matcha-color-pick.scss"; // matcha-color-pick-theme($theme)
@import "./components/matcha-draggable.scss"; // matcha-draggable-theme($theme)
@import "./components/matcha-header.scss"; // matcha-header-theme($theme)
@import "./components/matcha-horizontal-tree.scss"; // matcha-horizontal-tree-theme($theme)
@import "./components/matcha-progress-bar.scss"; // matcha-progress-bar-theme($theme)
@import "./components/matcha-scrollbar.scss"; // matcha-scrollbar-theme($theme)
@import "./components/matcha-scrollbox-shadow.scss"; // matcha-scrollbox-shadow-theme($theme)
@import "./components/matcha-table.scss"; // matcha-table-theme($theme)

// VENDORS
@import "./vendors/angular-editor.scss";
@import "./vendors/angular-material-fixes.scss";
@import "./vendors/calendar.scss";
@import "./vendors/charts.scss";
@import "./vendors/ng5-slider.scss";
@import "./vendors/ngx-material-timepicker.scss";

// DEFAULT FONTS
@font-face {
    font-family: "CircularStd";
    src: url("fonts/CircularStd-Regular.eot");
    src:
        local("☺"),
        url("fonts/CircularStd-Regular.woff") format("woff"),
        url("fonts/CircularStd-Regular.ttf") format("truetype"),
        url("fonts/CircularStd-Regular.svg") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "CircularStd";
    src: url("fonts/CircularStd-Medium.eot");
    src:
        local("☺"),
        url("fonts/CircularStd-Medium.woff") format("woff"),
        url("fonts/CircularStd-Medium.ttf") format("truetype"),
        url("fonts/CircularStd-Medium.svg") format("svg");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "CircularStd";
    src: url("fonts/CircularStd-Bold.eot");
    src:
        local("☺"),
        url("fonts/CircularStd-Bold.woff") format("woff"),
        url("fonts/CircularStd-Bold.ttf") format("truetype"),
        url("fonts/CircularStd-Bold.svg") format("svg");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "CircularStd";
    src: url("fonts/CircularStd-Black.eot");
    src:
        local("☺"),
        url("fonts/CircularStd-Black.woff") format("woff"),
        url("fonts/CircularStd-Black.ttf") format("truetype"),
        url("fonts/CircularStd-Black.svg") format("svg");
    font-weight: 900;
    font-style: normal;
}
// -------------------------------------------------------------------------------------------------------------------
// @ CORE - Mixins and Includes
// -------------------------------------------------------------------------------------------------------------------
@mixin matcha-typography($config: null) {
    @if $config ==null {
        $config: matcha-typography-config();
    }

    @include matcha-reset-typography($config);
}

@mixin matcha-components($theme) {
    @include colors-classes-dynamic($theme);
    @include colors-classes-static($theme);

    @include elevation-theme($theme);

    @include vendor-angular-editor($theme);
    @include vendor-angular-material-fixes($theme);
    @include vendor-calendar-theme($theme);
    @include vendor-chart($theme);
    @include vendor-ng-5-theme($theme);
    @include vendor-ngx-material-timepicker($theme);

    @include base-typography-theme($theme);
    @include base-body-reset-theme($theme);

    @include matcha-cards-theme($theme);
    @include matcha-horizontal-tree($theme);
    @include matcha-scrollbox-shadow($theme);
    @include matcha-scrollbar-theme($theme);

    @include matcha-table-theme($theme);

    @include matcha-button-theme($theme);
    @include matcha-header-theme($theme);
}
