$unit-base-pixels: px;
$unit-base-percent: .0%;

$rate-radius-none: 0;
$rate-radius-minimum: 2;
$rate-radius-small: 4;
$rate-radius-medium: 8;
$rate-radius-large: 16;
$rate-radius-wider: 9999;

$border-radius-none: 0px; // 0px
$border-radius-minimum: 2px; // 2px
$border-radius-small: 4px; // 4px
$border-radius-medium: 8px; // 8px
$border-radius-large: 16px; // 16px
$border-radius-wider: 9999px; // 9999px
$border-width-none: 0px; // 0px
$border-width-thin: 2px; // 2px
$border-width-thick: 4px; // 4px
$border-width-thicker: 8px; // 8px
$border-style-none: none;
$border-style-solid: solid;
$border-style-dashed: dashed;
$border-style-dotted: dotted;

$size-padding-minimum: 8px;
$size-padding-xxsmall: 16px;
$size-padding-xsmall: 24px;
$size-padding-small: 32px;
$size-padding-medium: 40px;
$size-padding-big: 48px;
$size-padding-bigger: 56px;
$size-padding-tall: 64px;
$size-padding-taller: 72px;
$size-padding-large: 80px;
$size-padding-xlarge: 88px;
$size-padding-xxlarge: 96px;
$size-padding-larger: 104px;
$size-padding-largest: 112px;
$size-padding-wider: 120px;
$spacing-stack-min: 8px 0; // 8px
$spacing-stack-xxs: 16px 0; // 16px
$spacing-stack-xs: 24px 0; // 24px
$spacing-stack-s: 32px 0; // 32px
$spacing-stack-m: 40px 0; // 40px
$spacing-stack-big: 48px 0; // 48px
$spacing-stack-bgg: 56px 0; // 56px
$spacing-stack-tll: 64px 0; // 64px
$spacing-stack-tlr: 72px 0; // 72px
$spacing-stack-lg: 80px 0; // 80px
$spacing-stack-xl: 88px 0; // 88px
$spacing-stack-xxl: 96px 0; // 96px
$spacing-stack-lgr: 104px 0; // 104px
$spacing-stack-lgs: 112px 0; // 112px
$spacing-inline-min: 0 8px; // 8px
$spacing-inline-xxs: 0 16px; // 16px
$spacing-inline-xs: 0 24px; // 24px
$spacing-inline-s: 0 32px; // 32px
$spacing-inline-m: 0 40px; // 40px
$spacing-inline-big: 0 48px; // 48px
$spacing-inline-bgg: 0 56px; // 56px
$spacing-inline-tll: 0 64px; // 64px
$spacing-inline-tlr: 0 72px; // 72px
$spacing-inline-lg: 0 80px; // 80px
$spacing-inline-xl: 0 88px; // 88px
$spacing-inline-xxl: 0 96px; // 96px
$spacing-inline-lgr: 0 104px; // 104px
$spacing-inline-lgs: 0 112px; // 112px
$spacing-inset-min: 8px 8px; // 8px
$spacing-inset-xxs: 16px 16px; // 16px
$spacing-inset-xs: 24px 24px; // 24px
$spacing-inset-s: 32px 32px; // 32px
$spacing-inset-m: 40px 40px; // 40px
$spacing-inset-big: 48px 48px; // 48px
$spacing-inset-bgg: 56px 56px; // 56px
$spacing-inset-tll: 64px 64px; // 64px
$spacing-inset-tlr: 72px 72px; // 72px
$spacing-inset-lg: 80px 80px; // 80px
$spacing-inset-xl: 88px 88px; // 88px
$spacing-inset-xxl: 96px 96px; // 96px
$spacing-inset-lgr: 104px 104px; // 104px
$spacing-inset-lgs: 112px 112px; // 112px
$spacing-squish-min: 8px 16px; // 8px
$spacing-squish-xxs: 16px 24px; // 16px
$spacing-squish-xs: 24px 32px; // 24px
$spacing-squish-s: 32px 40px; // 32px
$spacing-squish-m: 40px 48px; // 40px
$spacing-squish-big: 48px 56px; // 48px
$spacing-squish-bgg: 56px 64px; // 56px
$spacing-squish-tll: 64px 72px; // 64px
$spacing-squish-tlr: 72px 80px; // 72px
$spacing-squish-lg: 80px 88px; // 80px
$spacing-squish-xl: 88px 96px; // 88px
$spacing-squish-xxl: 96px 104px; // 96px
$spacing-squish-lgr: 104px 112px; // 104px
$spacing-squish-lgs: 112px 120px; // 112px
