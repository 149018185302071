/*
How to use
@import '~matcha-ds/assets/scss/components/matcha-audio-player.scss';
@include matcha-audio-player-theme($theme);
*/
// -----------------------------------------------------------------------------------------------------
// Audio Player [Theme]
// -----------------------------------------------------------------------------------------------------
@mixin matcha-audio-player-theme($theme) {
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .matcha-audio-player {
        .audio-player {
            audio {
                // display:none
            }

            .audio & {
                a {
                    display: none;
                }

                audio {
                    display: inline-block;
                }
            }
        }

        .is-selected {
            box-shadow: 0px 0px 0px 2px inset map-get($accent, default);
        }
    }
}
