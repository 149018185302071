@mixin vendor-angular-editor($theme) {
    $is-dark-theme: map-get($theme, is-dark);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .angular-editor {
        .angular-editor-toolbar {
            font: 100 0.8rem/15px Roboto, Arial, sans-serif !important;
            background-color: map-get($background, disabled) !important;
            padding: 8px !important;
            // height: 56px;
            border: 0px !important;

            .angular-editor-toolbar-set {
                .angular-editor-button {
                    background-color: transparent !important;
                    vertical-align: middle !important;
                    border: 0 !important;
                    padding: 8px !important;
                    float: left !important;
                    border-radius: 50px;

                    &:hover {
                        background: map-get($background, card-alpha) !important;
                    }

                    &.active {
                        background: map-get($background, card) !important;
                    }

                    i {
                        color: map-get($foreground, text);
                        height: 24px !important;
                        width: 24px !important;
                        font-size: 16px !important;
                        line-height: 24px !important;
                    }
                }
            }
        }

        .angular-editor-textarea {
            min-height: 100px !important;
            height: 30vh !important;
            padding: 16px !important;
            border: 0px !important;
            background-color: transparent !important;
            overflow-x: hidden !important;
            overflow-y: auto !important;
            position: relative !important;
        }
    }
}
